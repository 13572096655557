import React, { useState } from "react";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import QrReader from "modern-react-qr-reader";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => {
	return {
		container: {
			display: "flex",
			flexDirection: "column",
			alignContent: "center",
			alignItems: "center",
			justifyContent: "center",
			justifyItems: "center",
			padding: 0,
			margin: 0,
			flex: 1,
			flexGrow: 1
		},
		qrCodeReader: {
			opacity: 0
		},
		err: {
			height: "100%",
			maxHeight: 300,
			border: "1px solid #555",
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			alignContent: "center",
			alignItems: "center",
			color: theme.palette.error.main
		},
		errIcon: {
			height: 30,
			width: 30,
			paddingRight: theme.spacing(1)
		},
		errText: {
			fontSize: "1.2rem"
		},
		loaded: {
			opacity: 1,
			transition: "opacity 0.3s delay 5s"
		}
	};
});

export interface ParsedScanResult {
	isJoinExisting: boolean;
	joinId?: string;
	tableSlug: string;
}

const parseScanResult = (scanResult): ParsedScanResult | null => {
	if (scanResult) {
		try {
			const link = new URL(scanResult);
			const parts = link.pathname.split("/");
			return {
				isJoinExisting: parts.length === 6,
				joinId: parts.length === 6 ? parts[5] : undefined,
				tableSlug: parts[3]
			};
		} catch (err) {
			console.error(err);
		}
	}
	return null;
};

interface Props {
	onScanned: (result: ParsedScanResult) => void;
}

const QrCodeScanner: React.FC<Props> = ({ onScanned }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const [scanError, setScanError] = useState();
	// const [scanResult, setScanResult] = useState<string>("");
	const [loadResult, setLoadResult] = useState("");
	const [isLoaded, setIsLoaded] = useState(false);

	const [facingMode, setFacingMode] = useState("user");

	const onScannerLoaded = evt => {
		console.log("onScannerLoaded evt", evt);
		setLoadResult(JSON.stringify(evt, null, 2));
		setFacingMode("environment");
		setIsLoaded(true);
	};

	const onScanError = error => {
		setScanError(error.message);
		// TODO:  report an error
		console.log("onScanError", error);
		setScanError(error);
		if (error.message === "Could not start video source") {
			console.error(error);
			// setFacingMode("environment");
		}
	};

	const onScan = async result => {
		const res = parseScanResult(result);
		if (res) {
			console.log("onScan res", res);
			onScanned(res);
		}
	};

	return (
		// <Box className={classes.container}>
		<>
			{scanError ? (
				<Box className={clsx(classes.err)}>
					<ErrorOutlineIcon className={classes.errIcon} />
					<Typography className={classes.errText}>{t("unableToStartQrScanner")}</Typography>
				</Box>
			) : (
				<QrReader
					delay={300}
					showViewFinder
					className={clsx(classes.qrCodeReader, isLoaded && classes.loaded)}
					facingMode={facingMode}
					onError={onScanError}
					onLoad={onScannerLoaded}
					onScan={onScan}
				/>
			)}
		</>
	);
};

export default React.memo(QrCodeScanner);
