import React, { useEffect, useRef, useState } from "react";

// import Search from "components/Search";
// import MenuItemModal from "components/MenuItemModal";
import OrderFulfillmentModal from "components/OrderFulfillmentModal";

import useHistory from "hooks/useHistory";

interface Props {}

const Dialogs: React.FC<Props> = () => {
	const { hash, history } = useHistory();

	const [listenedHash, setListenedHash] = useState(hash);

	const historyRef = useRef(history);

	useEffect(() => {
		const unlisten = historyRef.current.listen(({ location }) => {
			setListenedHash(location.pathname);
		});
		return () => {
			unlisten();
		};
	}, []);

	return (
		<>
			{/* {listenedHash.endsWith("/detail") && <MenuItemModal />} */}
			{/* {listenedHash.includes("/type") && <OrderFulfillmentModal />} */}
			{/* {listenedHash.endsWith("/search") && <Search />} */}
			{/* {listenedHash.includes("/cart") && <CartModal />} */}
			{/* {hash.includes("?checkout") && <CheckoutModal />} */}
		</>
	);
};

export default Dialogs;
