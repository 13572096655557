import { DateTime } from "luxon";

import MenuItemAvailability from "api/types/MenuItemAvailability";

const isUnavailable = (time: DateTime, availability?: MenuItemAvailability): boolean => {
	let ret = false;
	availability?.scheduleExceptions?.forEach(e => {
		if (
			e &&
			e.date &&
			e.date.startOf("day").toSeconds() === time.startOf("day").toSeconds() &&
			(!e.periods || e.periods.length === 0)
		) {
			ret = true;
		} else if (e && e.startsAt && DateTime.fromISO(e?.startsAt) <= time) {
			if (e.endsAt) {
				if (DateTime.fromISO(e?.endsAt) > time) {
					ret = true;
				}
			} else {
				// no end dt
				ret = true;
			}
		}
	});
	return ret;
};

export default isUnavailable;
