import { createBrowserHistory } from "history";
import useStore from "store";
// const history = createHashHistory();
const history = createBrowserHistory();

const useHistory = () => {
	const locale = useStore(store => store.locale);

	const hash = () => history.location.pathname;

	const getHash = () => history.location.pathname;

	const push = path => {
		try {
			if (path.startsWith(`/${locale}/`) || path === `/${locale}`) {
				history.push(`${path}`);
			} else if (!path.startsWith("/")) {
				history.push(`${history.location.pathname}/${path}`);
			} else {
				history.push(`/${locale}${path}`);
			}
		} catch (err) {
			console.error(err);
		}
		// if (window.location.hash.replace("#", "") !== path) {
		// 	console.log("replacing path", path);
		// 	// history = createHashHistory();
		// 	history.replace(path);
		// 	// window.setTimeout(() => {
		// 	// window.location.hash = `#${path}`;
		// 	// }, 100);
		// }
		// history.replace(path);
		// setHash(history.location.pathname);
	};
	return {
		getHash,
		hash,
		history,
		push
	};
};

export default useHistory;
