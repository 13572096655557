import { DateTime } from "luxon";

import { DaysOfWeek } from "gql/types/globals";
import { BusinessQuery_business_orderingHours } from "gql/types/queries/BusinessQuery";

import getScheduleExceptions from "./getScheduleExceptions";
import getTimeFromTimeString from "./getTimeFromTimeString";

const isOpen = (time: DateTime, schedule: BusinessQuery_business_orderingHours, timeZone: string): boolean => {
	let ret = false;
	const dayOfWeek = DaysOfWeek[time.weekdayShort.toLowerCase()];
	const hrs = schedule.schedulePeriods?.filter(p => p?.days.includes(dayOfWeek));

	// Check for exceptions
	if (schedule.scheduleExceptions) {
		const ex = getScheduleExceptions(time, schedule, timeZone);
		if (ex && ex.length === 1) {
			if (ex[0]?.periods?.length === 0) {
				return false;
			} else if (ex[0] && ex[0].periods!.length > 0) {
				ex[0].periods!.forEach(p => {
					if (!ret && p) {
						if (p.isAllDay) {
							ret = true;
							return;
						}
						if (
							time >= getTimeFromTimeString(p.startTime, time, timeZone)! &&
							time < getTimeFromTimeString(p.endTime, time, timeZone)!
						) {
							ret = true;
							return;
						}
					}
				});
			}
			return ret;
		}
	}

	hrs?.forEach(h => {
		if (!ret) {
			h?.periods.forEach(p => {
				if (!ret) {
					if (p.isAllDay) {
						ret = true;
						return;
					}
					if (
						time >= getTimeFromTimeString(p.startTime, time, timeZone)! &&
						time < getTimeFromTimeString(p.endTime, time, timeZone)!
					) {
						ret = true;
						return;
					}
				}
			});
		}
	});

	return ret;
};

export default isOpen;
