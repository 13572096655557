import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import useStore from "store";

interface Props {
	to: string;
}

const Redirect: React.FC<Props> = ({ to }) => {
	const navigate = useNavigate();
	const locale = useStore(state => state.locale);

	useEffect(() => {
		console.log(`redirecting to /${locale}/${to}${window.location.search}`);
		navigate(`/${locale}/${to}${window.location.search}`);
	}, [navigate, locale, to]);

	return null;
};

export default Redirect;
