import React from "react";

import { styled } from "@mui/system";

import { useTranslation } from "react-i18next";
import { Background, Parallax } from "react-parallax";

import useStore from "store";

import AppLoading from "components/Loading";

const StyledParallax = styled(Parallax)`
	background-repeat: no-repeat;
	width: 100%;
	height: 220px;
`;

const StyledImg = styled("img")`
	height: 235px;
	backdrop-filter: blur(8px);
	/* @media screen and (min-width: 480px) {
		width: auto;
	} */
`;

// const StyledLogoImg = styled("img")`
// 	justify-self: center;
// 	align-self: center;
// 	height: 100%;
// 	width: 100%;
// 	margin: 0;
// 	padding: 0;
// 	margin-bottom: ${p => p.theme.spacing(3)};
// `;

// const StyledBoxContainer = styled(Box)`
// 	position: relative;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	flex-grow: 1;
// 	margin: 0;
// 	padding: ${p => p.theme.spacing(2)};
// 	padding-bottom: 210px;
// 	flex: 1;
// `;

// const StyledMotionDiv = styled(motion.div)`
// 	top: -65px;
// 	position: absolute;
// 	justify-self: center;
// 	align-self: center;
// 	margin: 0;
// 	padding: 0;
// 	max-height: 96px;
// 	max-width: 96px;
// 	margin-bottom: ${p => p.theme.spacing(3)};
// `;

interface Props {}

const BusinessInfoHeader: React.FC<Props> = () => {
	const { t } = useTranslation();

	const business = useStore(store => store.business);

	if (!business) {
		return <AppLoading />;
	}

	const infoImage = business.mediaLinks?.find(m => m && m.key === "infoImage");

	const background = business.menus[0]!.defaultMedia?.mediaUrl
		? {
				backgroundSize: "contain",
				backgroundAttachment: "fixed",
				backgroundRepeat: "no-repeat"
		  }
		: {};

	// const infoLogo = business.mediaLinks?.find(m => m && m.key === "infoLogo");

	return (
		<>
			<StyledParallax
				bgImage={infoImage?.mediaUrl || business.menus[0]!.defaultMedia?.mediaUrl}
				strength={300}
				blur={{ min: -1, max: 3 }}
				bgImageStyle={background}
			>
				<Background>
					{infoImage && (
						<StyledImg src={infoImage.mediaUrl || business.menus[0]!.defaultMedia?.mediaUrl} alt={t("info")} />
					)}
				</Background>
			</StyledParallax>

			{/* <StyledBoxContainer>
				{infoLogo && (
					<StyledMotionDiv
						initial={{ opacity: 0, height: 0, width: 0 }}
						animate={{ opacity: 1, height: 96, width: 96 }}
						transition={{ duration: 0.25, ease: "easeInOut" }}
					>
						<StyledLogoImg src={infoLogo.mediaUrl} alt={t("logo")} />
					</StyledMotionDiv>
				)}
			</StyledBoxContainer> */}
		</>
	);
};

export default BusinessInfoHeader;
