import { ApolloClient } from "@apollo/client";
import { Stripe, StripeCardElement } from "@stripe/stripe-js";
import analytics from "analytics/analytics";
import { onNetworkChanged } from "apolloClient/apolloClient";
import { i18n } from "i18n";
import produce from "immer";
import jwtDecode from "jwt-decode";
import { DateTime } from "luxon";
import { setAccessToken } from "utils/accessToken";
import { getDeviceToken, setDeviceToken } from "utils/deviceToken";
import getLocalId from "utils/getLocalId";
import { tagSortByTagItemDisplayOrder } from "utils/tagSort/tagSort";
import { getNextTimeSlot, isOpen } from "utils/timeUtils";
import getTimeString from "utils/timeUtils/getTimeString";
import create from "zustand";
import { persist } from "zustand/middleware";

import AddOrderItemsMutation from "api/mutations/AddOrderItemsMutation";

import AuthenticateOtpMutation from "api/mutations/AuthenticateOtpMutation";
import BeginOtpAuthenticationMutation from "api/mutations/BeginOtpAuthenticationMutation";

import ClearOrderItemsMutation from "api/mutations/ClearOrderItemsMutation";
import CustomerUpdateProfileMutation from "api/mutations/CustomerUpdateProfileMutation";
import EnsureCustomerDeviceMutation from "api/mutations/EnsureCustomerDeviceMutation";
import LogEventMutation from "api/mutations/LogEventMutation";
import OrderApplyPromotionMutation from "api/mutations/OrderApplyPromotionMutation";
import OrderTabJoinMutation from "api/mutations/OrderTabJoinMutation";
import OrderTabLeaveMutation from "api/mutations/OrderTabLeaveMutation";
import OrderTabMakeHostMutation from "api/mutations/OrderTabMakeHostMutation";
import OrderTabOpenMutation from "api/mutations/OrderTabOpenMutation";
import OrderTabRemoveMemberMutation from "api/mutations/OrderTabRemoveMemberMutation";
import OrderTabUpdateMemberInfoMutation from "api/mutations/OrderTabUpdateMemberInfoMutation";
import PaymentMethodAddMutation from "api/mutations/PaymentMethodAddMutation";
import PaymentMethodAddStartMutation from "api/mutations/PaymentMethodAddStartMutation";
import PaymentMethodDeleteMutation from "api/mutations/PaymentMethodDeleteMutation";
import PaymentMethodUpdateMutation from "api/mutations/PaymentMethodUpdateMutation";
import PlaceOrderCompleteMutation from "api/mutations/PlaceOrderCompleteMutation";
import PlaceOrderStartMutation from "api/mutations/PlaceOrderStartMutation";
import PlaceWalletOrderStartMutation from "api/mutations/PlaceWalletOrderStartMutation";
import RegisterMutation from "api/mutations/RegisterMutation";
import RemoveOrderItemMutation from "api/mutations/RemoveOrderItemMutation";
import ToggleMenuItemFavoriteMutation from "api/mutations/ToggleMenuItemFavoriteMutation";
import UpdateOrderItemMutation from "api/mutations/UpdateOrderItemMutation";
import ActiveBusinessPromotionsQuery from "api/queries/ActiveBusinessPromotionsQuery";
import BusinessCheckIsOrderingEnabledQuery from "api/queries/BusinessCheckIsOrderingEnabledQuery";
import BusinessQuery from "api/queries/BusinessQuery";

import CustomerPaymentMethodsQuery from "api/queries/CustomerPaymentMethodsQuery";
import MenuItemFavoritesQuery from "api/queries/MenuItemFavoritesQuery";
import OrderFindOpenTabQuery from "api/queries/OrderFindOpenTabQuery";
import OrderQuery from "api/queries/OrderQuery";
import OrderTabFindQuery from "api/queries/OrderTabFindQuery";
import OrderedItemsQuery from "api/queries/OrderedItemsQuery";
import RecentOrdersQuery from "api/queries/RecentOrdersQuery";
import BusinessChangedSubscription from "api/subscriptions/BusinessChangedSubscription";
import NotificationUpdatedSubscription from "api/subscriptions/NotificationUpdatedSubscription";
import OrderChangedSubscription from "api/subscriptions/OrderChangedSubscription";
import Business from "api/types/Business";
// import BusinessPromotion from "api/types/BusinessPromotion";
import MenuItem from "api/types/MenuItem";
import OrderFulfillmentType from "api/types/OrderFulfillmentType";
import Tag from "api/types/Tag";
import config from "config";
import {
	AuthTypes,
	EventLevels,
	NotificationLevels,
	NotificationTypes,
	OrderFulfillmentMethods,
	OrderItemStatuses,
	OrderStatuses,
	OrderTabStatuses,
	PaymentMethods,
	TableMemberStatuses,
	TaxRules,
	TaxTypes
} from "gql/types/globals";

import { BusinessQuery_business_tables } from "gql/types/queries/BusinessQuery";
import { OrderQuery_order_taxes } from "gql/types/queries/OrderQuery";

import CartItem from "./CartItem";
import CustomerInfo from "./CustomerInfo";
import NotificationModel from "./NotificationModel";
import OrderTotals from "./OrderTotals";
import StoreModel from "./StoreModel";
import getCustomerAppInfo from "./getCustomerAppInfo";
import { executeMutation, executeQuery, executeSubscription, initClient } from "./gqlClient";

let _client: ApolloClient<unknown>;

let _businessSubscription: any | null = null;
let _notificationSubscription: any | null = null;

let _appVersionPollingRef: NodeJS.Timeout | null = null;
let _networkReconnectTimeout: NodeJS.Timeout | null = null;

const _orderSubscriptions: Record<string, any> = {};
let _refreshing: string | undefined = undefined;

export const initStore = async (apolloClient: ApolloClient<unknown>): Promise<void> => {
	_client = apolloClient;
	initClient(apolloClient);
	const setLoading = useStore(store => store.setLoading);
	const refreshOrder = useStore(store => store.refreshOrder);
	const onNetworkConnectionChanged = useStore(store => store.onNetworkConnectionChanged);

	const refreshOrderSubscriptions = useStore(store => store.refreshOrderSubscriptions);
	const subscribeToNotifications = useStore(store => store.subscribeToNotifications);

	onNetworkChanged(onNetworkConnectionChanged);

	await refreshOrderSubscriptions();
	await subscribeToNotifications();

	setLoading(true);
	await refreshOrder();
	setLoading(false);
};

// const immer = config => (set, get, api) => config(fn => set(produce(fn)), get, api);

const initialOrderTotals: OrderTotals = {
	itemsCount: 0,
	foodAndBeverageTotalCents: 0,
	alcoholicBeverageTotalCents: 0,
	cartSubTotalCents: 0,
	taxes: [],
	taxCents: 0,
	tipPercentage: 15,
	tipCents: 0,
	automaticGratuityPercentage: undefined,
	automaticGratuityCents: undefined,
	deliveryFeeCents: 0,
	orderTotalCents: 0
};

const computeOrderTotals = (
	business: Business,
	tipPercentage: number,
	cart: (CartItem | null)[],
	deliveryFeeCents: number,
	automaticGratuityPercentage?: number,
	discountPercent?: number | null
): OrderTotals => {
	if (business && (!business.taxes || business.taxes.length < 1)) {
		throw new Error("Invalid taxes");
	}
	const totals: {
		itemsCount: number;
		foodAndBeverageTotalCents: number;
		alcoholicBeverageTotalCents: number;
		cartSubTotalCents: number;
		taxCents: number;
		discountCents: number;
		taxes: any[];
	} = {
		itemsCount: 0,
		foodAndBeverageTotalCents: 0,
		alcoholicBeverageTotalCents: 0,
		cartSubTotalCents: 0,
		taxCents: 0,
		discountCents: 0,
		taxes: business
			.taxes!.map(
				tx =>
					(tx && {
						...tx,
						taxCents: 0
					}) as OrderQuery_order_taxes
			)!
			.filter(tx => tx !== null)
	};

	let discountAlcoholicBeverageTotalCents = 0;

	const isPromotionsEnabled = business.settings?.isPromotionsEnabled ?? false;

	switch (business.taxRule) {
		case TaxRules.caBc:
			cart.forEach(curr => {
				if (curr) {
					totals.itemsCount += curr.quantity!;
					if (isPromotionsEnabled && discountPercent) {
						totals.discountCents += curr.extendedPriceCents! * (discountPercent / 100);
						discountAlcoholicBeverageTotalCents += curr.menuItem.isAlcoholicBeverage
							? curr.extendedPriceCents! * (1 - discountPercent / 100)
							: 0;
					}
					totals.foodAndBeverageTotalCents += curr.menuItem.isAlcoholicBeverage ? 0 : curr.extendedPriceCents!;
					totals.alcoholicBeverageTotalCents += curr.menuItem.isAlcoholicBeverage ? curr.extendedPriceCents! : 0;
				}
			});

			totals.taxes.forEach(tx => {
				if (tx.type === TaxTypes.gst) {
					// GST is based on both foodAndBeverage & alcoholicBeverage
					tx.taxCents = Math.round(
						(totals.foodAndBeverageTotalCents + totals.alcoholicBeverageTotalCents - totals.discountCents) *
							(tx.taxRate / 100)
					);
				}
				if (tx.type === TaxTypes.pst) {
					if (isPromotionsEnabled && discountPercent) {
						tx.taxCents = Math.round(discountAlcoholicBeverageTotalCents * (tx.taxRate / 100));
					} else {
						tx.taxCents = Math.round(totals.alcoholicBeverageTotalCents * (tx.taxRate / 100));
					}
				}
			});
			break;

		case TaxRules.caAb:
		case TaxRules.caNb:
		case TaxRules.caNl:
		case TaxRules.caNs:
		case TaxRules.caNt:
		case TaxRules.caNu:
		case TaxRules.caPe:
		case TaxRules.caQc:
		case TaxRules.caYt:
		case TaxRules.usa:
		default:
			cart.forEach(curr => {
				if (curr) {
					totals.itemsCount += curr.quantity!;
					totals.foodAndBeverageTotalCents += curr.extendedPriceCents!;
					if (isPromotionsEnabled && discountPercent) {
						totals.discountCents += curr.extendedPriceCents! * (discountPercent / 100);
						totals.taxCents +=
							curr.menuItem!.isTaxable === false
								? 0
								: (totals.taxes![0]!.taxRate / 100) * curr.extendedPriceCents! * (1 - discountPercent / 100);
						totals.taxes![0]!.taxCents +=
							curr.menuItem!.isTaxable === false
								? 0
								: (totals.taxes![0]!.taxRate / 100) * curr.extendedPriceCents! * (1 - discountPercent / 100);
					} else {
						totals.taxCents +=
							curr.menuItem!.isTaxable === false ? 0 : (totals.taxes![0]!.taxRate / 100) * curr.extendedPriceCents!;
						totals.taxes![0]!.taxCents +=
							curr.menuItem!.isTaxable === false ? 0 : (totals.taxes![0]!.taxRate / 100) * curr.extendedPriceCents!;
					}
				}
			});
			break;
	}

	totals.discountCents = Math.round(totals.discountCents);

	let roundedTax = 0;
	totals.taxes.forEach(tx => {
		roundedTax += tx.taxCents;
	});

	totals.taxes.forEach(tx => {
		tx.taxCents = Math.round(tx.taxCents);
	});

	const tipCents = Math.round(
		(totals.foodAndBeverageTotalCents + totals.alcoholicBeverageTotalCents - totals.discountCents) *
			(tipPercentage / 100)
	);

	const automaticGratuityCents = automaticGratuityPercentage
		? Math.round(
				(totals.foodAndBeverageTotalCents + totals.alcoholicBeverageTotalCents - totals.discountCents) *
					(automaticGratuityPercentage / 100)
		  )
		: undefined;

	roundedTax = Math.round(roundedTax);

	return {
		...totals,
		taxes: totals.taxes.filter(tx => tx !== null),
		taxCents: roundedTax,
		tipPercentage,
		tipCents,
		automaticGratuityPercentage,
		automaticGratuityCents,
		deliveryFeeCents,
		cartSubTotalCents:
			totals.foodAndBeverageTotalCents + totals.alcoholicBeverageTotalCents + roundedTax - totals.discountCents,
		orderTotalCents:
			totals.foodAndBeverageTotalCents +
			totals.alcoholicBeverageTotalCents +
			roundedTax +
			tipCents +
			deliveryFeeCents -
			totals.discountCents
	};
};

// build the search index
const buildIndex = business =>
	business.menus[0]?.menuItems?.map(
		m =>
			m &&
			({
				...m,
				searchIndex: m.name.toLocaleLowerCase()
			} as MenuItem)
	);

type StoreType = StoreModel & NotificationModel;

const useStore = create<StoreType>(
	persist(
		(set, get) => ({
			locale: "en",
			isClientConnected: false,
			isAppBarVisible: true,
			isCartButtonVisible: true,
			isVerticalScrolling: false,
			isDrawerOpen: false,
			isLoggedIn: false,
			isPlacingOrder: false,
			setIsPlacingOrder: value => {
				set({
					isPlacingOrder: value
				});
			},
			isWalletEnabled: undefined,
			loading: false,
			hash: "",
			includeUtensils: undefined,

			hasAccount: false,
			customerId: null,
			customerInfo: {
				name: undefined,
				firstName: "",
				lastName: "",
				mobileNumber: "",
				email: undefined,
				deliveryAddress: null
			},
			business: null,
			taxRate: 0,
			orderNote: undefined,
			deviceToken: null,
			decodedDeviceToken: null,
			orderId: undefined,
			localId: getLocalId(), // local orderId
			order: null,
			orderStatus: OrderStatuses.new,
			businessPromotions: undefined,
			orderPromotions: [],

			table: null,
			tabHost: null,
			tableMemberId: undefined,
			tableMembers: [],
			orderTabStatus: null,

			isAsapOrder: true,
			orderTime: undefined,
			updateOrderTime: () => {
				const { isAsapOrder, deliveryOrPickupAt } = get();
				const orderTime = isAsapOrder
					? getNextTimeSlot(DateTime.local(), 5)
					: deliveryOrPickupAt?.isValid
					? deliveryOrPickupAt
					: DateTime.fromISO(deliveryOrPickupAt!.toString()) ?? DateTime.local();
				set({ orderTime });
				return orderTime;
			},
			deliveryOrPickupAt: null,
			orderDeliveryAddress: null,
			orderDeliveryInstructions: null,

			subscribedOrderIds: [],

			orderFulfillmentType: null,
			orderFulfillmentTimeString: "",
			cartItemCount: 0,

			discountPercent: undefined,

			orderTotals: initialOrderTotals,
			currentMenuItemIndex: 0,
			currentMenuItem: null,
			menuState: [],
			searchTags: [],
			allTags: [],
			cart: [],
			filtered: [],
			searchFiltered: [],
			favorites: [],
			currentPage: 0,
			isOrderingEnabled: true,

			orderedItems: [],

			recentOrders: [],
			paymentMethods: [],

			accessToken: null,
			refreshToken: null,

			isOpenNow: () => {
				const { business } = get();
				if (business) {
					return isOpen(DateTime.local(), get().business!.orderingHours, business.timeZone!);
				}
				return false;
			},

			setIsWalletEnabled: value => {
				set({
					isWalletEnabled: value
				});
			},

			setDeliveryOrPickupAt: time => {
				set({
					isAsapOrder: false,
					deliveryOrPickupAt: time
				});
				const { updateOrderTime } = get();
				updateOrderTime();
			},

			setOrderFulfillmentTimeString: timeString => {
				set({
					orderFulfillmentTimeString: timeString
				});
			},

			setOrderDeliveryAddress: address => {
				set({
					orderDeliveryAddress: address
				});
			},

			setOrderDeliveryInstructions: deliveryInstructions => {
				set({
					orderDeliveryInstructions: deliveryInstructions
				});
			},

			setIsAsapOrder: value => {
				set({
					isAsapOrder: value
				});
				if (value) {
					set({
						deliveryOrPickupAt: null
					});
				}
				const { updateOrderTime } = get();
				updateOrderTime();
			},

			findOpenTab: async (tableSlug: string) => {
				const { business } = get();
				const res = await executeQuery(OrderFindOpenTabQuery, {
					variables: {
						orderFindOpenTabInput: {
							businessId: business!._id,
							tableSlug
						}
					}
				});

				if (res.data?.orderFindOpenTab) {
					// console.log("res.data", res.data);
					return res.data?.orderFindOpenTab;
				}
				return [];
			},

			orderTabFind: async (joinId: string, tableSlug: string) => {
				const { business } = get();
				const res = await executeQuery(OrderTabFindQuery, {
					variables: {
						orderTabFindInput: {
							businessId: business!._id,
							joinId,
							tableSlug
						}
					}
				});

				if (res.data?.orderTabFind) {
					// console.log("res.data", res.data);
					return res.data?.orderTabFind;
				}
				return null;
			},

			orderTabOpen: async ({ table, name }: { table: BusinessQuery_business_tables; name?: string | undefined }) => {
				const { business, localId, orderId, subscribeToOrderChanged, tableMemberId } = get();
				const dineIn = business!.orderFulfillmentTypes.find(
					t => t?.orderFulfillmentMethod === OrderFulfillmentMethods.dineIn
				);

				if (dineIn) {
					const newTableMemberId = tableMemberId || getLocalId();
					const res = await executeMutation(OrderTabOpenMutation, {
						variables: {
							orderTabOpenInput: {
								businessId: business!._id,
								tableSlug: table.slug,
								orderId,
								localId,
								name,
								tableMemberId: newTableMemberId,
								orderTabStatus: OrderTabStatuses.open
							}
						}
					});
					if (res.data?.orderTabOpen) {
						set({
							orderFulfillmentType: dineIn,
							orderFulfillmentTimeString: i18n.t("tableWithName", { name: table.name }),
							isAsapOrder: true,
							tableScannedAt: DateTime.local(),
							table,
							orderId: res.data?.orderTabOpen._id,
							tableMemberId: newTableMemberId,
							orderTabStatus: res.data.orderTabOpen.orderTabStatus
						});
						// subscribe to the order
						await subscribeToOrderChanged(res.data?.orderTabOpen._id);

						return res.data?.orderTabOpen;
					}
				}
			},

			orderTabJoin: async ({
				orderId,
				joinId,
				table,
				name
			}: {
				orderId: string;
				joinId: string;
				table: BusinessQuery_business_tables;
				name?: string | undefined;
			}) => {
				const { business, subscribeToOrderChanged, tableMemberId, refreshOrder, orderId: currentOrderId } = get();
				const dineIn = business!.orderFulfillmentTypes.find(
					t => t?.orderFulfillmentMethod === OrderFulfillmentMethods.dineIn
				);

				if (dineIn) {
					const newTableMemberId = tableMemberId || getLocalId();

					const res = await executeMutation(OrderTabJoinMutation, {
						variables: {
							orderTabJoinInput: {
								orderId,
								tableMemberId: newTableMemberId,
								tableSlug: table.slug,
								name
							}
						}
					});
					if (res.data?.orderTabJoin) {
						set({
							orderFulfillmentType: dineIn,
							orderFulfillmentTimeString: i18n.t("tableWithName", { name: table.name }),
							isAsapOrder: true,
							orderId: res.data.orderTabJoin._id,
							localId: joinId,
							tableScannedAt: DateTime.local(),
							table,
							tableMemberId: newTableMemberId,
							tabHost: res.data.orderTabJoin.tabHost!,
							tableMembers: res.data.orderTabJoin.tableMembers || [],
							orderStatus: res.data.orderTabJoin.orderStatus!,
							orderTabStatus: res.data.orderTabJoin.orderTabStatus
						});

						const { addOrderItems, cart } = get();
						const myCartItems = cart.filter(
							i => i && i.orderedById === tableMemberId // && i.orderItemStatus === OrderItemStatuses.new  // TODO: Process based on status later
						);
						await addOrderItems(myCartItems);

						// subscribe to the order
						await subscribeToOrderChanged(res.data?.orderTabJoin._id);
						await refreshOrder(orderId);

						return res.data?.orderTabJoin;
					}
				}
			},

			orderTabLeave: async (newOrderFulfillmentType?: OrderFulfillmentType) => {
				// Leave the current order
				const {
					addOrderItems,
					cart,
					customerInfo,
					orderId,
					orderTabOpen,
					tableMemberId,
					table,
					unsubscribeOrderChanged
				} = get();

				// unsubscribe to the current order
				await unsubscribeOrderChanged(orderId);

				//
				if (orderId) {
					try {
						const res = await executeMutation(OrderTabLeaveMutation, {
							variables: {
								orderTabLeaveInput: {
									orderId: orderId!,
									tableMemberId: tableMemberId!,
									orderFulfillmentMethod: newOrderFulfillmentType?.orderFulfillmentMethod
								}
							}
						});
					} catch (err) {
						console.error("Error on orderTabLeave: ", err);
					}
				}
				set({
					orderId: undefined,
					order: null,
					orderStatus: OrderStatuses.new,
					localId: getLocalId(),
					cart: [],
					orderTotals: initialOrderTotals
				});

				if (newOrderFulfillmentType?.orderFulfillmentMethod === OrderFulfillmentMethods.dineIn) {
					// Start a new order & move my order items
					await orderTabOpen({ table: table!, name: customerInfo.name });

					const myCartItems = cart.filter(
						i => i && i.orderedById === tableMemberId //&& i.orderItemStatus === OrderItemStatuses.new
					);
					await addOrderItems(myCartItems);
				}
			},

			orderTabUpdateMemberInfo: async ({ name }) => {
				const { orderId, tableMemberId } = get();
				const newTableMemberId = tableMemberId || getLocalId();
				const res = await executeMutation(OrderTabUpdateMemberInfoMutation, {
					variables: {
						orderTabUpdateMemberInfoInput: {
							orderId: orderId!,
							tableMemberId: tableMemberId!,
							name
						}
					}
				});
				if (res.data?.orderTabUpdateMemberInfo) {
					set({
						tableMemberId: newTableMemberId,
						tabHost: res.data.orderTabUpdateMemberInfo.tabHost!,
						tableMembers: res.data.orderTabUpdateMemberInfo.tableMembers || []
					});
				}
			},

			orderTabClose: async (tableSlug: string) => {},

			orderTabRemoveMember: async (tableMemberId: string) => {
				const { orderId, tableMembers } = get();

				// Make the change locally & then submit
				set({
					tableMembers: tableMembers.filter(m => m && m._id !== tableMemberId)
				});

				const res = await executeMutation(OrderTabRemoveMemberMutation, {
					variables: {
						orderTabRemoveMemberInput: {
							orderId,
							tableMemberId
						}
					}
				});
			},

			orderTabMakeHost: async (tableMemberId: string) => {
				const { orderId, tableMembers } = get();

				if (orderId) {
					// Make the change locally & then submit
					const newHost = tableMembers.find(m => m && m._id === tableMemberId);
					if (newHost) {
						set({
							tabHost: newHost,
							tableMembers: tableMembers.map(
								m =>
									m && {
										...m,
										isHost: m._id === tableMemberId
									}
							)
						});
					}

					const res = await executeMutation(OrderTabMakeHostMutation, {
						variables: {
							orderTabMakeHostInput: {
								orderId,
								tableMemberId
							}
						}
					});
				}
			},

			orderApplyPromotion: async (promotionId?: string, promotionCode?: string) => {
				const { business, localId, orderId } = get();
				if (!localId) {
					console.error("null localId!!");
				}
				if (orderId || localId) {
					try {
						const res = await executeMutation(OrderApplyPromotionMutation, {
							variables: {
								orderApplyPromotionInput: {
									businessId: business!._id,
									orderId: orderId,
									localId: localId,
									promotionId,
									promotionCode
								}
							}
						});
						if (res.data?.orderApplyPromotion) {
							const { orderTotals, business, cart } = get();

							const discountedOrderTotals = computeOrderTotals(
								business!,
								orderTotals.tipPercentage!,
								cart,
								orderTotals.deliveryFeeCents,
								orderTotals.automaticGratuityPercentage,
								res.data.orderApplyPromotion.discountPercent
							);
							set({
								orderId: res.data?.orderApplyPromotion._id,
								discountPercent: res.data?.orderApplyPromotion.discountPercent || undefined,
								orderTotals: {
									...discountedOrderTotals,
									discountPercent: res.data?.orderApplyPromotion.discountPercent
								}
							});
						}
					} catch (e: any) {
						console.error("Error applying promotion", e.message);
						if (e.message === "Invalid promotion") {
							// Remove the promotion
							const { orderPromotions } = get();
							const filteredPromotions = orderPromotions.filter(p => p && p._id !== promotionId);
							// console.log("filteredPromotions", filteredPromotions);

							const { orderTotals, business, cart } = get();

							const discountedOrderTotals = computeOrderTotals(
								business!,
								orderTotals.tipPercentage!,
								cart,
								orderTotals.deliveryFeeCents,
								orderTotals.automaticGratuityPercentage,
								0
							);
							set({
								discountPercent: undefined,
								orderTotals: {
									...discountedOrderTotals,
									discountPercent: undefined
								},
								orderPromotions: filteredPromotions
							});
						}
					}
				} else {
					console.error("NULL orderId");
				}
			},

			setCurrentMenuItemIndex: index => {
				set(state => {
					const menuState = state.menuState;
					return {
						currentMenuItemIndex: index,
						menuState
					};
				});
			},

			setCurrentMenuItem: menuItem => {
				set({
					currentMenuItem: menuItem
				});
				if (menuItem) {
					analytics.track("Viewing item", {
						menuItemId: menuItem?._id,
						menuItemName: menuItem?.name
					});
				}
			},

			setCurrentPage: (page: number) =>
				set({
					currentPage: page
				}),

			setHash: (hash: string) => {
				set({
					hash: hash.substr(0, 1) !== "#" ? `#${hash}` : hash
				});
			},

			setLoading: (value: boolean) => {
				set({
					loading: value
				});
			},

			setCustomerInfo: (customerInfo: CustomerInfo) =>
				set({
					customerInfo
				}),

			setLocale: (locale: string) =>
				set({
					locale
				}),

			setIsAppBarVisible: (value: boolean) =>
				set({
					isAppBarVisible: value
				}),

			setIsCartButtonVisible: (value: boolean) =>
				set({
					isCartButtonVisible: value
				}),

			setIsVerticalScrolling: (value: boolean) =>
				set({
					isVerticalScrolling: value
				}),

			setOrderNote: (value: string) => {
				set({
					orderNote: value
				});
			},

			setIncludeUtensils: (value: boolean) =>
				set({
					includeUtensils: value
				}),

			toggleDrawer: () =>
				set(state => ({
					isDrawerOpen: !state.isDrawerOpen
				})),

			ensureToken: async (token: string | null) => {
				const { isLoggedIn } = get();
				if (!isLoggedIn) {
					// clear the access token
					setAccessToken(null);
				}
				const res = await executeMutation(EnsureCustomerDeviceMutation, {
					variables: {
						ensureCustomerDeviceInput: {
							deviceToken: token ? token : getDeviceToken()
						}
					}
				});
				if (res.data?.ensureCustomerDevice) {
					const decoded = jwtDecode(res.data.ensureCustomerDevice.deviceToken) as any;
					if (decoded.customerId) {
						analytics.identify(decoded.customerId);
						const ppl = {
							$created: new Date(decoded!.cat).toISOString()
						};
						const { customerInfo } = get();
						if (customerInfo && customerInfo.firstName) {
							ppl["$first_name"] = customerInfo.firstName;
							ppl["$last_name"] = customerInfo.lastName;
						}
						analytics.people.set(ppl);
					} else {
						analytics.identify(decoded.token);
						analytics.people.set({
							$created: new Date(decoded!.cat).toISOString()
						});
					}
					setDeviceToken(res.data.ensureCustomerDevice.deviceToken);
					set({
						decodedDeviceToken: decoded.token,
						deviceToken: res.data.ensureCustomerDevice.deviceToken
					});
				}
			},

			onBusinessUpdated: async result => {
				if (result.data.businessChanged) {
					// console.log("onBusinessUpdated", result.data.businessChanged);
					const { getBusiness, setLoading } = get();
					setLoading(true);
					await getBusiness(result.data.businessChanged.slug);
					setLoading(false);
				}
			},

			getBusiness: async (slug?: string, domainHostName?: string) => {
				const { business, onBusinessUpdated } = get();

				let lookupSlug = slug;
				if (!slug && !domainHostName) {
					if (!business) {
						throw new Error("Business not found");
					}
					lookupSlug = business.slug;
				}
				const res = await executeQuery(BusinessQuery, {
					variables: { businessInput: { slug: lookupSlug, domainHostName } }
				});
				if (res.data && res.data.business) {
					const tags: Array<Tag> = [];
					res.data.business.menus[0]?.menuItems?.map(m => {
						if (m && m.tags && m.tags.length > 0) {
							m?.tags.map(t => {
								if (t && !tags.find(added => added.tag === t.tag)) {
									tags.push(t);
								}
							});
						}
					});
					const updatedBusiness = res.data.business;
					const { orderFulfillmentType, orderFulfillmentTimeString, table } = get();
					const updatedOrderFulfillmentType: OrderFulfillmentType = orderFulfillmentType
						? (updatedBusiness.orderFulfillmentTypes.find(
								f => f?.orderFulfillmentMethod === orderFulfillmentType.orderFulfillmentMethod
						  ) as OrderFulfillmentType)
						: (updatedBusiness.orderFulfillmentTypes[0]! as OrderFulfillmentType);

					const deliveryFeeCents =
						updatedOrderFulfillmentType &&
						updatedOrderFulfillmentType.orderFulfillmentMethod === OrderFulfillmentMethods.delivery
							? updatedOrderFulfillmentType.priceCents!
							: 0;

					const defaultTipPercentage =
						updatedOrderFulfillmentType.defaultTipPercentage ?? updatedBusiness.defaultTipPercentage ?? 15;
					const defaultOrderFulfillmentTimeString =
						orderFulfillmentTimeString === ""
							? updatedOrderFulfillmentType &&
							  updatedOrderFulfillmentType.orderFulfillmentMethod !== OrderFulfillmentMethods.dineIn
								? getTimeString(updatedBusiness, updatedOrderFulfillmentType!, true, null)
								: i18n.t("tableWithName", { name: table?.name })
							: orderFulfillmentTimeString;

					const searchFiltered = buildIndex(updatedBusiness);

					set(state => ({
						allTags: tags.sort((a, b) => (a.tag > b.tag ? 1 : -1)),
						business: updatedBusiness,
						isOrderingEnabled: updatedBusiness.isOrderingEnabled !== null ? updatedBusiness.isOrderingEnabled : true,
						orderFulfillmentType: updatedOrderFulfillmentType,
						orderFulfillmentTimeString: defaultOrderFulfillmentTimeString!,
						searchFiltered: searchFiltered || [],
						orderTotals: {
							...state.orderTotals,
							deliveryFeeCents,
							tipPercentage: defaultTipPercentage,
							orderTotalCents:
								state.orderTotals.foodAndBeverageTotalCents -
								(state.orderTotals.discountCents || 0) +
								state.orderTotals.taxCents! +
								(state.orderTotals.tipCents ? state.orderTotals.tipCents : 0) +
								deliveryFeeCents
						}
					}));

					if (!updatedBusiness.settings?.isPromotionsEnabled) {
						const { cart, orderTotals } = get();
						const newOrderTotals = computeOrderTotals(
							updatedBusiness!,
							orderTotals.tipPercentage!,
							cart,
							orderTotals.deliveryFeeCents,
							orderTotals.automaticGratuityPercentage,
							0
						);

						set({
							discountPercent: 0,
							orderTotals: {
								discountCents: null,
								...newOrderTotals
							}
						});
					}

					if (!_businessSubscription) {
						_businessSubscription = executeSubscription(BusinessChangedSubscription, {
							variables: {
								businessId: updatedBusiness._id
							}
						}).subscribe(nextResult => {
							onBusinessUpdated(nextResult);
						});
					}
				}
				const { ensureToken, deviceToken, isLoggedIn, getBusinessPromotions } = get();
				await ensureToken(deviceToken);

				if (isLoggedIn) {
					const { getMenuItemFavorites, getOrderedItems } = get();
					await getMenuItemFavorites();
					await getOrderedItems();
				}

				await getBusinessPromotions();
			},

			getBusinessPromotions: async () => {
				if (!_client) {
					throw new Error("ApolloClient not initialized");
				}
				const { business } = get();
				if (business) {
					const res = await executeQuery(ActiveBusinessPromotionsQuery, {
						variables: { businessPromotionsInput: { businessId: business._id } }
					});
					if (res.data && res.data.activeBusinessPromotions) {
						const automaticPromotions = res.data.activeBusinessPromotions.filter(p => p?.isAutomatic);
						let discountPercent: number | undefined = undefined;
						const { orderApplyPromotion } = get();
						automaticPromotions.forEach(async p => {
							if (p) {
								if (p.discountPercent) {
									discountPercent = p.discountPercent;
								}
								await orderApplyPromotion(p._id!);
							}
						});
						set({
							businessPromotions: res.data.activeBusinessPromotions,
							orderPromotions: automaticPromotions,
							discountPercent
						});
						const { cart, orderTotals } = get();
						const newOrderTotals = computeOrderTotals(
							business!,
							orderTotals.tipPercentage!,
							cart,
							orderTotals.deliveryFeeCents,
							orderTotals.automaticGratuityPercentage,
							discountPercent
						);
						set({
							orderTotals: newOrderTotals
						});
					} else {
						set({ businessPromotions: [] });
					}
				}
			},

			checkBusinessIsOrderingEnabled: async () => {
				const { business, isOrderingEnabled } = get();
				if (business) {
					const res = await executeQuery(BusinessCheckIsOrderingEnabledQuery, {
						variables: { businessInput: { businessId: business._id } }
					});
					if (res.data.business && res.data.business.isOrderingEnabled !== isOrderingEnabled) {
						set({
							isOrderingEnabled: res.data.business.isOrderingEnabled!
						});
					}
				}
			},

			getMenuItemFavorites: async () => {
				const { business } = get();
				if (business) {
					const res = await executeQuery(MenuItemFavoritesQuery, {
						variables: {
							getMenuItemFavoritesInput: {
								businessId: business._id
							}
						}
					});
					if (res.data.getMenuItemFavorites) {
						set({
							favorites: res.data.getMenuItemFavorites
						});
					}
				}
			},

			getOrderedItems: async () => {
				const { business, deviceToken } = get();
				if (deviceToken) {
					const res = await executeQuery(OrderedItemsQuery, {
						variables: { businessId: business!._id }
					});
					if (res.data.orderedItems) {
						set({
							orderedItems: res.data.orderedItems
						});
					}
				}
			},

			getRecentOrders: async (asOfDate?: DateTime) => {
				const { business, customerInfo, deviceToken, recentOrders } = get();

				if (deviceToken && recentOrders.length === 0) {
					const limit = 20;
					let skip = 0;
					let more = true;
					while (more) {
						console.log("limit, skip", limit, skip);
						const res = await executeQuery(RecentOrdersQuery, {
							variables: {
								recentOrdersInput: {
									businessId: business!._id,
									phoneNumber: customerInfo.mobileNumber,
									asOfDate,
									limit,
									skip
								}
							}
						});
						if (res.data.recentOrders) {
							if (asOfDate) {
								set(state => ({
									recentOrders: state.recentOrders.map(o => {
										const r1 = res.data.recentOrders.find(r => r?._id === o?._id);
										return r1 ? r1 : o;
									})
								}));
							} else {
								set(state => {
									const ids = new Set(state.recentOrders.map(d => d?._id));
									return {
										recentOrders: [...state.recentOrders, ...res.data.recentOrders.filter(d => !ids.has(d?._id))]
									};
								});
							}
							more = res.data.recentOrders.length === limit;
							if (more) {
								skip += limit;
							}
						}
					}
				}
			},

			getPaymentMethods: async () => {
				const { isLoggedIn } = get();
				if (isLoggedIn) {
					const res = await executeQuery(CustomerPaymentMethodsQuery, {
						variables: { customerPaymentMethodsInput: {} }
					});
					if (res.data.customerPaymentMethods) {
						set({
							paymentMethods: res.data.customerPaymentMethods
						});
					}
				}
			},

			searchText: undefined,

			setSearchText: (value: string | undefined) => {
				set({
					searchText: value
				});
			},

			applySearchFilter: (searchString?: string) => {
				const { business, searchFiltered } = get();
				let filtered: (MenuItem | null)[] = [];
				if (business) {
					if (searchString) {
						const normalizedSearch = searchString.toLocaleLowerCase();
						filtered = searchFiltered!.filter(item => item?.searchIndex?.includes(normalizedSearch));
						set({
							searchFiltered: filtered
						});
					} else {
						set({
							searchFiltered: buildIndex(business)
						});
					}
				}
				analytics.track("Search Filter applied", {
					searchString
				});
				return filtered;
			},

			applyTagFilter: (filterTags: Tag[], index: number | null) => {
				const { business } = get();
				let filtered: (MenuItem | null)[] = [];
				if (business && filterTags[0] !== undefined) {
					filtered = get()
						.business!.menus[0]!.menuItems!.filter(item => {
							let matchesAll = true;
							filterTags &&
								filterTags[0] &&
								filterTags.forEach(searchTag => {
									if (
										(item && item?.tags === null) ||
										(searchTag && !item!.tags!.find(t => t?.slug === searchTag.slug))
									) {
										matchesAll = false;
									}
								});
							return matchesAll;
						})
						.sort(tagSortByTagItemDisplayOrder(filterTags[0].slug));
					set({
						searchTags: filterTags,
						filtered,
						currentMenuItemIndex: index ? index : 0,
						currentMenuItem: index ? filtered[index] : null,
						isVerticalScrolling: false
					});
				} else {
					set({ searchTags: filterTags, filtered: [] });
				}
				analytics.track("Tag filter applied", {
					tags: filterTags
				});
				return filtered;
			},

			applyTagFilterByTagSlug: (tagSlug: string) => {
				const { allTags, applyTagFilter } = get();
				return applyTagFilter([allTags.find(f => f.slug === tagSlug)!], 1);
			},

			setOrderFulfillmentType: async (newOrderFulfillmentType: OrderFulfillmentType) => {
				const { orderFulfillmentType } = get();

				if (
					orderFulfillmentType &&
					orderFulfillmentType.orderFulfillmentMethod == OrderFulfillmentMethods.dineIn &&
					newOrderFulfillmentType.orderFulfillmentMethod !== OrderFulfillmentMethods.dineIn
				) {
					const { orderTabLeave } = get();
					await orderTabLeave(newOrderFulfillmentType);
					set({
						table: null,
						tableScannedAt: null,
						tabHost: null,
						tableMemberId: null,
						orderTabStatus: null,
						tableMembers: []
					});
				}

				if (newOrderFulfillmentType.orderFulfillmentMethod === OrderFulfillmentMethods.delivery) {
					set(state => ({
						orderFulfillmentType: newOrderFulfillmentType,
						orderTotals: {
							...state.orderTotals,
							deliveryFeeCents: newOrderFulfillmentType.priceCents!,
							orderTotalCents:
								state.orderTotals.foodAndBeverageTotalCents -
								(state.orderTotals.discountCents || 0) +
								state.orderTotals.taxCents! +
								(state.orderTotals.tipCents ? state.orderTotals.tipCents : 0) +
								newOrderFulfillmentType.priceCents!
						}
					}));
				} else {
					set(state => ({
						orderFulfillmentType: newOrderFulfillmentType,
						orderTotals: {
							...state.orderTotals,
							deliveryFeeCents: 0,
							orderTotalCents:
								state.orderTotals.foodAndBeverageTotalCents -
								(state.orderTotals.discountCents || 0) +
								state.orderTotals.taxCents! +
								(state.orderTotals.tipCents ? state.orderTotals.tipCents : 0)
						}
					}));
				}
			},

			setOrderTipPercentage: (tipPercentage: number) => {
				set(state => ({
					orderTotals: {
						...state.orderTotals,
						tipPercentage: tipPercentage,
						tipCents: Math.round(
							(state.orderTotals.foodAndBeverageTotalCents - (state.orderTotals.discountCents ?? 0)) *
								(tipPercentage / 100)
						),
						orderTotalCents:
							state.orderTotals.foodAndBeverageTotalCents -
							(state.orderTotals.discountCents ?? 0) +
							state.orderTotals.taxCents! +
							Math.round(
								(state.orderTotals.foodAndBeverageTotalCents - (state.orderTotals.discountCents ?? 0)) *
									(tipPercentage / 100)
							) +
							state.orderTotals.deliveryFeeCents
					}
				}));
			},

			setOrderTip: (tipCents: number) => {
				const tip = tipCents ? tipCents : 0;
				set(state => ({
					orderTotals: {
						...state.orderTotals,
						tipCents: tip,
						tipPercentage:
							tip > 0
								? +(
										(tip / (state.orderTotals.foodAndBeverageTotalCents - (state.orderTotals.discountCents ?? 0))) *
										100
								  ).toFixed(1)
								: 0,
						orderTotalCents:
							state.orderTotals.foodAndBeverageTotalCents -
							(state.orderTotals.discountCents || 0) +
							state.orderTotals.taxCents! +
							tip +
							state.orderTotals.deliveryFeeCents
					}
				}));
			},

			addOrderItems: async (orderItems: (CartItem | null)[]) => {
				const state = get();
				set({
					loading: true
				});
				const orderTotals = computeOrderTotals(
					state.business!,
					state.orderTotals.tipPercentage!,
					state.cart.concat(orderItems),
					state.orderTotals.deliveryFeeCents,
					state.orderTotals.automaticGratuityPercentage,
					state.discountPercent
				);

				set(state => ({
					cart: state.cart.concat(orderItems),
					orderTotals
				}));

				// call the mutation
				const { business, orderId, localId, tableMemberId } = get();
				if (business) {
					const newTableMemberId = tableMemberId || getLocalId();
					if (!tableMemberId) {
						set({
							tableMemberId: newTableMemberId
						});
					}
					const orderedBy = `${state.customerInfo.firstName}`;
					const res = await executeMutation(AddOrderItemsMutation, {
						variables: {
							addOrderItemsInput: {
								businessId: business._id,
								menuId: business.menus[0]!._id!,
								orderId,
								localId,
								items: orderItems.map(
									orderItem =>
										orderItem && {
											localId: orderItem.localId,
											orderedById: newTableMemberId,
											orderedBy,
											menuItemId: orderItem.menuItem._id,
											specialInstructions: orderItem.specialInstructions,
											selectedMenuItemModifiers:
												orderItem.selectedModifiers &&
												orderItem.selectedModifiers.map(
													x =>
														x && {
															menuItemModifierGroupId: x.menuItemModifierGroupId,
															menuItemModifierId: x.menuItemModifierId
														}
												),
											quantity: orderItem.quantity,
											unitPriceCents: orderItem.unitPriceCents,
											extendedPriceCents: orderItem.extendedPriceCents
										}
								)
							}
						}
					});
					if (res.data) {
						set({
							orderId: res.data.addOrderItems?._id || undefined,
							cartItemCount: orderTotals.itemsCount
						});
						if (orderItems.length === 1 && orderItems[0]) {
							analytics.track("Menu Item Added To Cart", {
								menuItem: orderItems[0].menuItem.name,
								menuItemId: orderItems[0].menuItem._id,
								quantity: orderItems[0].quantity,
								extendedPriceCents: orderItems[0].extendedPriceCents
							});
						} else {
							analytics.track("Multiple Menu Items Added To Cart", {
								menuItems: orderItems,
								quantity: orderItems.length
							});
						}
					}
				}
			},

			removeOrderItem: async (orderItemLocalId: string) => {
				const updatedCart = get().cart.filter(f => f && f.localId !== orderItemLocalId);

				set(state => ({
					cart: updatedCart,
					orderTotals: computeOrderTotals(
						state.business!,
						state.orderTotals.tipPercentage!,
						updatedCart,
						state.orderTotals.deliveryFeeCents,
						state.orderTotals.automaticGratuityPercentage,
						state.discountPercent
					)
				}));

				try {
					// call the mutation
					const { business, orderId } = get();
					if (business) {
						const res = await executeMutation(RemoveOrderItemMutation, {
							variables: {
								removeOrderItemInput: {
									businessId: business._id,
									orderId,
									orderItemLocalId
								}
							}
						});
						if (res.data) {
							// console.log("res.data.removeOrderItem", res.data.removeOrderItem);
						}
					}
				} catch (e) {
					console.error("Error removing orderItem", e);
				}
			},

			clearOrderItems: async ({
				startNewOrder,
				tableMemberId
			}: {
				startNewOrder?: boolean;
				tableMemberId?: string;
			}) => {
				set({
					isPlacingOrder: false
				});
				// call the mutation
				const { business, orderId, orderFulfillmentType, cart } = get();
				if (business) {
					if (tableMemberId) {
						const updatedCart = cart.filter(i => i?.orderedById !== tableMemberId);
						set(state => ({
							orderTotals: computeOrderTotals(
								business!,
								state.orderTotals.tipPercentage!,
								updatedCart,
								state.orderTotals.deliveryFeeCents,
								state.orderTotals.automaticGratuityPercentage,
								state.discountPercent
							),
							cart: updatedCart,
							cartItemCount: updatedCart.length
						}));
						// Fix order totals
					} else {
						const orderTotals = initialOrderTotals;

						if (
							orderFulfillmentType &&
							orderFulfillmentType?.orderFulfillmentMethod === OrderFulfillmentMethods.delivery
						) {
							orderTotals.deliveryFeeCents = orderFulfillmentType.priceCents!;
						}
						set({
							orderTotals,
							cart: [],
							cartItemCount: 0
						});
					}

					try {
						if (!orderId) {
							// console.error("MISSING ORDERID", orderId);
							return;
						}
						const res = await executeMutation(ClearOrderItemsMutation, {
							variables: {
								clearOrderItemsInput: {
									businessId: business._id,
									orderId,
									tableMemberId: tableMemberId
								}
							}
						});
						// if (res.data) {
						// 	console.log("res.data.clearOrderItems", res.data.clearOrderItems);
						// }
					} catch (e) {
						console.error("Error clearing cart", e);
					}
					// console.log("startNewOrder", startNewOrder);
					if (startNewOrder) {
						set({
							orderId: undefined,
							localId: getLocalId()
						});
					}
				}
			},

			toggleMenuItemFavorite: async (menuItemId: string, isFavorite: boolean) => {
				const { business } = get();
				if (business) {
					try {
						const res = await executeMutation(ToggleMenuItemFavoriteMutation, {
							variables: {
								toggleMenuItemFavoriteInput: {
									businessId: business._id,
									menuItemId,
									isFavorite
								}
							}
						});
						if (res.data) {
							set({
								favorites: res.data.toggleMenuItemFavorite
							});
						}
					} catch (e) {
						console.error("Error in toggleMenuItemFavorite", e);
					}
				}
			},

			updateOrderItem: async (orderItem: CartItem) => {
				const updatedCart = get().cart.map(i => (i && i.localId !== orderItem.localId ? i : orderItem));
				set(state => ({
					cart: updatedCart,
					orderTotals: computeOrderTotals(
						state.business!,
						state.orderTotals.tipPercentage!,
						updatedCart,
						state.orderTotals.deliveryFeeCents,
						state.orderTotals.automaticGratuityPercentage,
						state.discountPercent
					)
				}));

				// call the mutation
				const { business, orderId } = get();
				if (business) {
					const res = await executeMutation(UpdateOrderItemMutation, {
						variables: {
							updateOrderItemInput: {
								businessId: business._id,
								menuId: business.menus[0]!._id,
								orderId,
								item: {
									localId: orderItem.localId,
									menuItemId: orderItem.menuItem._id,
									specialInstructions: orderItem.specialInstructions,
									selectedMenuItemModifiers:
										orderItem.selectedModifiers &&
										orderItem.selectedModifiers.map(
											x =>
												x && {
													menuItemModifierGroupId: x.menuItemModifierGroupId,
													menuItemModifierId: x.menuItemModifierId
												}
										),
									quantity: orderItem.quantity,
									unitPriceCents: orderItem.unitPriceCents,
									extendedPriceCents: orderItem.extendedPriceCents
								}
							}
						}
					});
					if (res.data) {
						// console.log("res.data.updateOrderItem", res.data.updateOrderItem);
					}
				}
			},

			placeOrder: async ({
				isPayInStore,
				stripe,
				cardElement,
				savePaymentCard
			}: {
				isPayInStore: boolean;
				stripe?: Stripe | null;
				cardElement?: StripeCardElement;
				savePaymentCard?: boolean;
			}): Promise<boolean> => {
				const { isPlacingOrder } = get();
				if (isPlacingOrder) {
					return false;
				}
				set({
					isPlacingOrder: true
				});

				// Use your card Element with other Stripe.js APIs
				if (cardElement) {
					const { error, paymentMethod } = await stripe!.createPaymentMethod({
						type: "card",
						card: cardElement!
					});

					if (error) {
						console.error("[error]", error);
						set({
							isPlacingOrder: false
						});
						throw error;
					}
				}

				// call the mutation
				const {
					business,
					orderId,
					deviceToken,
					cart,
					isAsapOrder,
					isLoggedIn,
					customerInfo,
					orderFulfillmentType,
					orderDeliveryAddress,
					deliveryOrPickupAt,
					orderNote,
					orderDeliveryInstructions,
					orderTotals,
					newOrder,
					orderPromotions,
					includeUtensils,
					logEvent
				} = get();

				if (business && cart.length > 0) {
					const res = await executeMutation(PlaceOrderStartMutation, {
						variables: {
							placeOrderStartInput: {
								businessId: business._id,
								menuId: business.menus[0]!._id,
								deviceToken: deviceToken!,
								orderId: orderId,
								paymentMethod: isPayInStore ? PaymentMethods.inStore : PaymentMethods.card,
								customer: {
									firstName: customerInfo.firstName,
									lastName: customerInfo.lastName,
									mobileNumber: customerInfo.mobileNumber
								},
								nameOnCard: customerInfo.nameOnCard,
								savePaymentCard,
								orderFulfillmentType: {
									orderFulfillmentTypeId: orderFulfillmentType!._id!,
									orderFulfillmentMethod: orderFulfillmentType!.orderFulfillmentMethod
								},
								deliveryAddress:
									orderFulfillmentType!.orderFulfillmentMethod === OrderFulfillmentMethods.delivery
										? orderDeliveryAddress
										: null,
								deliveryOrPickupAt: isAsapOrder ? null : deliveryOrPickupAt,
								deliveryInstructions: orderDeliveryInstructions,
								isAsapOrder,
								includeUtensils,
								orderNote,
								useDefaultPaymentMethod: isLoggedIn && !isPayInStore,

								promotions: orderPromotions.map(p => p && { promotionId: p._id }),
								discountCents: orderTotals.discountCents,
								orderSubTotalCents: orderTotals.foodAndBeverageTotalCents,
								taxes: orderTotals.taxes.map(tx => ({
									type: tx.type,
									taxRate: tx.taxRate,
									taxCents: tx.taxCents
								})),
								taxCents: orderTotals.taxCents!,
								tipCents: orderTotals.tipCents || 0,
								deliveryFeeCents: orderTotals.deliveryFeeCents,
								orderTotalCents: orderTotals.orderTotalCents!,
								items:
									cart &&
									cart.map(
										orderItem =>
											orderItem && {
												localId: orderItem.localId,
												menuItemId: orderItem.menuItem._id,
												specialInstructions: orderItem.specialInstructions,
												selectedMenuItemModifiers:
													orderItem.selectedModifiers &&
													orderItem.selectedModifiers.map(
														x =>
															x && {
																menuItemModifierGroupId: x.menuItemModifierGroupId,
																menuItemModifierId: x.menuItemModifierId
															}
													),
												quantity: orderItem.quantity,
												unitPriceCents: orderItem.unitPriceCents,
												extendedPriceCents: orderItem.extendedPriceCents
											}
									)
							}
						}
					});

					if (res.errors || !res.data) {
						res.errors?.forEach(error => {
							console.error("order placement failed:\r\n", error);
						});
						set({
							isPlacingOrder: false
						});
						throw new Error("Order placement failed");
					}

					if (!isPayInStore) {
						const { paymentIntentKey, order } = res.data.placeOrderStart!;

						if (!isLoggedIn) {
							const { error, ...rest } = await stripe!.confirmCardPayment(paymentIntentKey!, {
								payment_method: {
									card: cardElement!
								},
								setup_future_usage: savePaymentCard ? "off_session" : null
							});

							if (error) {
								// console.error("order confirmation failed:\r\n", error);
								await logEvent({
									eventType: "Error",
									title: `Error confirming card payment:  ${error.message}`,
									level: EventLevels.error,
									data: error
								});
								set({
									isPlacingOrder: false
								});
								throw error;
							}
						}
					}
					// complete the order
					try {
						const completeResult = await executeMutation(PlaceOrderCompleteMutation, {
							variables: {
								placeOrderCompleteInput: {
									businessId: business._id,
									orderId
								}
							}
						});
						if (completeResult.errors || !completeResult.data) {
							completeResult.errors?.forEach(error => {
								console.error("order placement failed:\r\n", error);
							});
							throw new Error("Order placement complete failed");
						} else {
							// completeResult.data.placeOrderComplete
						}
					} catch (ce) {
						set({
							isPlacingOrder: false
						});
						console.error(ce);
						throw ce;
					}
					analytics.people.set({
						$first_name: customerInfo.firstName,
						$last_name: customerInfo.lastName
					});

					newOrder();
					const { subscribeToOrderChanged } = get();
					await subscribeToOrderChanged(orderId);
					set({
						isPlacingOrder: false
					});
					return true;
				}
				return false;
			},

			placeWalletOrder: async ({ customer, walletName }) => {
				const { isPlacingOrder } = get();
				if (isPlacingOrder) {
					return;
				}
				set({
					isPlacingOrder: true
				});

				// call the mutation
				const {
					business,
					orderId,
					deviceToken,
					cart,
					isAsapOrder,
					orderFulfillmentType,
					orderDeliveryAddress,
					deliveryOrPickupAt,
					orderNote,
					orderDeliveryInstructions,
					orderTotals,
					orderPromotions,
					includeUtensils
				} = get();

				if (business && cart.length > 0) {
					const res = await executeMutation(PlaceWalletOrderStartMutation, {
						variables: {
							placeWalletOrderStartInput: {
								businessId: business._id,
								menuId: business.menus[0]!._id,
								deviceToken: deviceToken!,
								orderId: orderId,
								paymentMethod: PaymentMethods.card,
								customer,
								walletName,
								orderFulfillmentType: {
									orderFulfillmentTypeId: orderFulfillmentType!._id!,
									orderFulfillmentMethod: orderFulfillmentType!.orderFulfillmentMethod
								},
								deliveryAddress:
									orderFulfillmentType!.orderFulfillmentMethod === OrderFulfillmentMethods.delivery
										? orderDeliveryAddress
										: null,
								deliveryOrPickupAt: isAsapOrder ? null : deliveryOrPickupAt,
								deliveryInstructions: orderDeliveryInstructions,
								isAsapOrder,
								includeUtensils,
								orderNote,
								promotions: orderPromotions.map(p => p && { promotionId: p._id }),
								discountCents: orderTotals.discountCents,
								orderSubTotalCents: orderTotals.foodAndBeverageTotalCents,
								taxes: orderTotals.taxes.map(tx => ({
									type: tx.type,
									taxRate: tx.taxRate,
									taxCents: tx.taxCents
								})),
								taxCents: orderTotals.taxCents!,
								tipCents: orderTotals.tipCents || 0,
								deliveryFeeCents: orderTotals.deliveryFeeCents,
								orderTotalCents: orderTotals.orderTotalCents!,
								items:
									cart &&
									cart.map(
										orderItem =>
											orderItem && {
												localId: orderItem.localId,
												menuItemId: orderItem.menuItem._id,
												specialInstructions: orderItem.specialInstructions,
												selectedMenuItemModifiers:
													orderItem.selectedModifiers &&
													orderItem.selectedModifiers.map(
														x =>
															x && {
																menuItemModifierGroupId: x.menuItemModifierGroupId,
																menuItemModifierId: x.menuItemModifierId
															}
													),
												quantity: orderItem.quantity,
												unitPriceCents: orderItem.unitPriceCents,
												extendedPriceCents: orderItem.extendedPriceCents
											}
									)
							}
						}
					});

					if (res.errors || !res.data) {
						res.errors?.forEach(error => {
							console.error("order placement failed:\r\n", error);
						});
						set({
							isPlacingOrder: false
						});
						throw new Error("Order placement failed");
					}
					analytics.people.set({
						$name: customer.name
					});

					const { paymentIntentKey } = res.data.placeWalletOrderStart!;
					return paymentIntentKey;
				}
			},

			completeWalletOrder: async () => {
				// complete the order
				const { business, orderId, newOrder, logEvent } = get();
				try {
					const completeResult = await executeMutation(PlaceOrderCompleteMutation, {
						variables: {
							placeOrderCompleteInput: {
								businessId: business!._id,
								orderId
							}
						}
					});
					if (completeResult.errors || !completeResult.data) {
						completeResult.errors?.forEach(error => {
							console.error("order placement failed:\r\n", error);
						});
						set({
							isPlacingOrder: false
						});
						throw new Error("Order placement complete failed");
					} else {
						// completeResult.data.placeOrderComplete
					}
				} catch (ce) {
					console.error(ce);
					set({
						isPlacingOrder: false
					});
					throw ce;
				}

				newOrder();
				set({
					isPlacingOrder: false
				});
				const { subscribeToOrderChanged } = get();
				await subscribeToOrderChanged(orderId);
			},

			subscribeToOrderChanged: async orderId => {
				const { onOrderChanged, subscribedOrderIds } = get();
				const orderSubscription = executeSubscription(OrderChangedSubscription, {
					variables: {
						orderChangedInput: {
							orderId
						}
					}
				}).subscribe(async nextResult => {
					await onOrderChanged(nextResult.data);
				});

				if (!subscribedOrderIds.includes(orderId)) {
					subscribedOrderIds.push(orderId);
				}

				_orderSubscriptions[orderId] = orderSubscription;
			},

			unsubscribeOrderChanged: async orderId => {
				_orderSubscriptions[orderId]?.unsubscribe();
			},

			onOrderChanged: async data => {
				const { orderId, refreshOrder, orderFulfillmentType } = get();
				if (
					data.orderChanged.orderStatus !== OrderStatuses.new ||
					orderFulfillmentType?.orderFulfillmentMethod === OrderFulfillmentMethods.dineIn
				) {
					if (orderId) {
						await refreshOrder(data.orderChanged._id!);
					}
				}
			},

			refreshOrder: async (orderId?: string) => {
				const { business, getOrder, newOrder, tableMemberId, orderId: savedOrderId, orderFulfillmentType } = get();
				const currentOrder = orderId ? orderId : savedOrderId;
				if (currentOrder) {
					// Ensure only one refresh
					if (_refreshing && _refreshing === currentOrder) {
						return;
					}
					_refreshing = currentOrder;

					set({
						loading: true
					});

					const order = await getOrder(currentOrder);

					_refreshing = undefined;

					if (order) {
						if (order.orderStatus === OrderStatuses.placed) {
							if (order.tabHost && order.tabHost?._id !== tableMemberId) {
								// notification
								set({
									notificationText: i18n.t("orderPlaced1"),
									notificationLevel: NotificationLevels.information
								});
							}
							await newOrder(false);
							set({
								tabHost: null,
								tableMembers: []
							});
							return;
						}

						if (order.orderStatus === OrderStatuses.new && order._id === savedOrderId) {
							const currentMember = order.tableMembers?.find(m => m && m._id === tableMemberId);
							if (
								orderFulfillmentType?.orderFulfillmentMethod === OrderFulfillmentMethods.dineIn &&
								order.tableMembers &&
								order.tableMembers.length > 0 &&
								(!currentMember || currentMember.tableMemberStatus === TableMemberStatuses.removed)
							) {
								const { addOrderItems, cart } = get();
								const myCartItems = cart.filter(
									i => i && i.orderedById === tableMemberId // && i.orderItemStatus === OrderItemStatuses.new  // TODO: Process based on status later
								);
								await addOrderItems(myCartItems);
							}

							set({
								tabHost: order.tabHost,
								tableMembers: order.tableMembers!,
								cart: order
									.orderItems!.map(
										i =>
											i && {
												localId: i.localId!,
												menuItem: i.menuItem as MenuItem,
												specialInstructions: i.specialInstructions,
												quantity: i.quantity!,
												orderedById: i.orderedById!,
												selectedModifiers: i.selectedMenuItemModifiers?.map(
													m =>
														m && {
															menuItemModifierId: m._id,
															menuItemModifierGroupId: m.menuItemModifierGroupId,
															name: m.name,
															localeNames: m.localeNames,
															isDefault: m.isDefault,
															priceCents: m.priceCents,
															displayPrice: m.displayPrice
														}
												),
												unitPriceCents: i.unitPriceCents!,
												extendedPriceCents: i.extendedPriceCents!
											}
									)
									.filter(i => i !== null)
							});
							const { cart, orderTotals, discountPercent } = get();
							const updatedOrderTotals = computeOrderTotals(
								business!,
								orderTotals.tipPercentage!,
								cart,
								orderTotals.deliveryFeeCents,
								orderTotals.automaticGratuityPercentage,
								discountPercent
							);
							set({
								orderTotals: updatedOrderTotals,
								cartItemCount: updatedOrderTotals.itemsCount
							});
						}
					}

					set({
						loading: false
					});
				}
			},

			refreshOrderSubscriptions: async () => {
				if (Object.keys(_orderSubscriptions).length === 0) {
					const { subscribedOrderIds, subscribeToOrderChanged } = get();
					if (subscribedOrderIds.length > 0) {
						subscribedOrderIds.forEach(async orderId => {
							await subscribeToOrderChanged(orderId);
						});
					}
				}
			},

			newOrder: async (clearTable?: boolean) => {
				// console.log("newOrder clearTable", clearTable);
				const {
					business,
					getBusinessPromotions,
					orderFulfillmentType,
					orderFulfillmentTimeString,
					orderId,
					unsubscribeOrderChanged
				} = get();
				const orderTotals = initialOrderTotals;
				await unsubscribeOrderChanged(orderId);
				if (orderFulfillmentType && orderFulfillmentType?.orderFulfillmentMethod === OrderFulfillmentMethods.delivery) {
					orderTotals.deliveryFeeCents = orderFulfillmentType.priceCents!;
				}
				set({
					orderId: undefined,
					order: null,
					orderNote: undefined,
					orderStatus: OrderStatuses.new,
					localId: getLocalId(),
					isAsapOrder: true,
					orderFulfillmentTimeString:
						orderFulfillmentType?.orderFulfillmentMethod === OrderFulfillmentMethods.pickup
							? getTimeString(business!, orderFulfillmentType!, true, null)
							: orderFulfillmentType?.orderFulfillmentMethod === OrderFulfillmentMethods.dineIn
							? orderFulfillmentTimeString
							: "",
					cart: [],
					orderTotals
				});
				if (clearTable) {
					set({
						table: null,
						tabHost: null,
						tableMembers: [],
						orderFulfillmentTimeString: ""
					});
				}

				await getBusinessPromotions();
			},

			getOrder: async (orderId: string, accessToken?: string) => {
				if (orderId) {
					try {
						const res = await executeQuery(OrderQuery, {
							variables: {
								orderInput: {
									orderId,
									accessToken
								}
							}
						});

						if (res.data && res.data.order) {
							return res.data.order;
						}
					} catch (err) {
						// console.error("getOrder", orderId, err);
						set({
							notificationText: i18n.t("sorryErrorOccurred"),
							notificationLevel: NotificationLevels.error
						});
						// create a new order
						const { newOrder } = get();
						newOrder(true); // clear table
					}
				}
			},

			paymentMethodAddStart: async ({
				stripe,
				cardElement,
				nameOnCard,
				isDefault
			}: {
				stripe: Stripe;
				cardElement: StripeCardElement;
				nameOnCard: string;
				isDefault: boolean;
			}) => {
				const res = await executeMutation(PaymentMethodAddStartMutation, {
					variables: {
						customerPaymentMethodStartInput: {}
					}
				});
				if (res.data?.customerPaymentMethodAddStart) {
					const { setupIntent } = res.data.customerPaymentMethodAddStart;

					const cardSetup = await stripe.confirmCardSetup(setupIntent, {
						payment_method: {
							card: cardElement,
							billing_details: { name: nameOnCard }
						}
					});

					if (cardSetup.error) {
						// console.error("cardSetup.error", cardSetup.error);
						const { logEvent } = get();
						await logEvent({
							title: "Error saving card",
							eventType: "Error",
							level: EventLevels.error,
							data: cardSetup.error
						});
						throw cardSetup.error;
					}
					const retrieveSetupIntentResult = await stripe.retrieveSetupIntent(setupIntent);

					const setupIntentResult = retrieveSetupIntentResult.setupIntent;

					if (setupIntentResult && setupIntentResult.payment_method) {
						const addResult = await executeMutation(PaymentMethodAddMutation, {
							variables: {
								customerPaymentMethodAddInput: {
									paymentMethod: setupIntentResult.payment_method,
									nameOnCard,
									isDefault
								}
							}
						});
					}
				}
			},

			paymentMethodDelete: async (_id: string) => {
				const res = await executeMutation(PaymentMethodDeleteMutation, {
					variables: {
						customerPaymentMethodDeleteInput: {
							_id
						}
					}
				});
				const { getPaymentMethods } = get();
				await getPaymentMethods();
			},

			paymentMethodUpdate: async (_id: string) => {
				const res = await executeMutation(PaymentMethodUpdateMutation, {
					variables: {
						customerPaymentMethodUpdateInput: {
							_id,
							isDefault: true
						}
					}
				});
				const { getPaymentMethods } = get();
				await getPaymentMethods();
			},

			customerUpdateProfile: async (firstName: string, lastName: string) => {
				const res = await executeMutation(CustomerUpdateProfileMutation, {
					variables: {
						customerUpdateProfileInput: {
							firstName,
							lastName
						}
					}
				});
				const { deviceToken, ensureToken, setCustomerInfo, customerInfo } = get();
				await ensureToken(deviceToken);
				setCustomerInfo({ ...customerInfo, firstName, lastName });
			},

			onNetworkConnectionChanged: (isConnected: boolean) => {
				set({
					isClientConnected: isConnected
				});
				if (!isConnected) {
					const { tryNetworkReconnect } = get();
					tryNetworkReconnect();
				}
			},

			logEvent: async ({
				title,
				data,
				level,
				source,
				eventType,
				publish
			}: {
				title: string;
				level: EventLevels;
				source?: string;
				data?: any;
				eventType?: string;
				publish?: boolean;
			}) => {
				console.log("title, level, source, data", title, level, source, data);
				const result = await executeMutation(LogEventMutation, {
					variables: {
						logEventInput: {
							title,
							source,
							data,
							level,
							eventType: eventType!,
							publish
						}
					}
				});
			},

			register: async (mobileNumber: string) => {
				const { business } = get();
				const res = await executeMutation(RegisterMutation, {
					variables: {
						registerInput: {
							businessId: business?._id,
							domain: window.location.hostname,
							mobileNumber
						}
					}
				});
				// return res.data.register;
			},

			beginOtpAuthentication: async (mobileNumber: string) => {
				const { business } = get();
				const res = await executeMutation(BeginOtpAuthenticationMutation, {
					variables: {
						beginOtpAuthenticationInput: {
							authType: AuthTypes.consumer,
							domain: window.location.hostname,
							businessId: business?._id,
							mobileNumber
						}
					}
				});

				return res.data?.beginOtpAuthentication || false;
			},

			authenticateOtp: async (mobileNumber: string, otp: string) => {
				const { business } = get();
				const res = await executeMutation(AuthenticateOtpMutation, {
					variables: {
						authenticateOtpInput: {
							authType: AuthTypes.consumer,
							mobileNumber,
							otp,
							businessId: business?._id
						}
					}
				});

				if (
					res.data?.authenticateOtp &&
					res.data?.authenticateOtp?.accessToken &&
					res.data?.authenticateOtp?.accessToken.length > 0
				) {
					const { accessToken, refreshToken } = res.data.authenticateOtp;

					const decodedToken: any = jwtDecode(accessToken!);

					setAccessToken(accessToken);

					set({
						isLoggedIn: Boolean(accessToken && refreshToken),
						hasAccount: true,
						customerId: decodedToken.customerId,
						accessToken,
						refreshToken,
						customerInfo: {
							firstName: decodedToken.firstName,
							lastName: decodedToken.lastName,
							mobileNumber: decodedToken.mobileNumber,
							name: decodedToken.name,
							email: decodedToken.email
						}
					});

					return Boolean(accessToken && refreshToken);
				}
				return false;
			},

			logout: async () => {
				setAccessToken(null);

				set({ isLoggedIn: false, customerId: null, recentOrders: [], orderedItems: [] });
				const { logEvent } = get();
				await logEvent({
					title: "Customer logout",
					level: EventLevels.information,
					eventType: "information",
					publish: true
				});
			},

			checkAppVersion: async () => {
				if (!config.isAppVersionCheckEnabled) {
					console.log("checkAppVersion disabled");
				}

				// get the app version for the build
				if (_appVersionPollingRef) {
					clearInterval(_appVersionPollingRef);
				}
				const currentVersion = process.env.REACT_APP_VERSION?.trim();
				const branch = process.env.REACT_APP_BRANCH;

				// console.log("currentVersion, branch", currentVersion, branch);
				await getCustomerAppInfo(branch!, currentVersion);

				_appVersionPollingRef = setInterval(async () => {
					await getCustomerAppInfo(branch!, currentVersion);
				}, config.appVersionCheckIntervalMinutes * 60 * 1000);
			},

			// NotificationModel
			notificationText: undefined,
			isNotificationVisible: false,
			notificationType: undefined,
			notificationLevel: undefined,

			clearNotification: () => {
				set({
					notificationText: undefined,
					notificationType: undefined,
					notificationLevel: undefined
				});
			},

			subscribeToNotifications: async () => {
				const { customerId, decodedDeviceToken, onNotificationChanged } = get();
				const notificationUpdatedInput = customerId
					? {
							customerId
					  }
					: {
							deviceToken: decodedDeviceToken
					  };
				_notificationSubscription = await executeSubscription(NotificationUpdatedSubscription, {
					variables: {
						notificationUpdatedInput
					}
				}).subscribe(nextResult => {
					onNotificationChanged(nextResult);
				});
			},

			onNotificationChanged: async ({ data }) => {
				if (data?.notificationUpdated?.notificationType) {
					const { tableMembers } = get();
					if (!tableMembers || (tableMembers.length === 1 && data.notificationUpdated.title === "orderCleared")) {
						// don't show
						return;
					}
					set({
						notificationText: i18n.t(data.notificationUpdated.title),
						notificationType: data.notificationUpdated.notificationType,
						notificationLevel: data.notificationUpdated.notificationLevel || NotificationLevels.information
					});
					if (data.notificationUpdated.notificationType === NotificationTypes.orderUpdated) {
						set(state => ({
							recentOrders: state.recentOrders.map(r =>
								r && r._id === data.notificationUpdated.data._id
									? {
											...r,
											...data.notificationUpdated.data
									  }
									: r
							)
						}));
						const { refreshOrder } = get();
						await refreshOrder();
						// getRecentOrders(DateTime.local().minus({ minutes: 1 }));
					}
				}
			},

			refreshSubscriptions: async (): Promise<void> => {
				const { refreshOrderSubscriptions, subscribeToNotifications } = get();
				await refreshOrderSubscriptions();
				await subscribeToNotifications();
			},

			tryNetworkReconnect: () => {
				const { isClientConnected } = get();
				if (!isClientConnected && !_networkReconnectTimeout) {
					_networkReconnectTimeout = setTimeout(() => {
						const { refreshSubscriptions } = get();
						refreshSubscriptions();
						_networkReconnectTimeout = null;
					}, 60 * 1000);
				}
			}
		}),

		{
			name: "nomsquared",
			onRehydrateStorage: () => (state?: StoreType) => {
				if (state) {
					if (state.isPlacingOrder) {
						state.isPlacingOrder = false;
					}
					if (state.deliveryOrPickupAt && DateTime.fromISO(state.deliveryOrPickupAt?.toString()) <= DateTime.local()) {
						state.isAsapOrder = true;
					}
					if (state.isAsapOrder) {
						state.orderFulfillmentTimeString = "";
						state.setIsAsapOrder(true);
					}
					state.orderTime = state.updateOrderTime();
					state.businessPromotions = undefined;
					state.isClientConnected = false;
				}
				return state;
			}
		}
	)
);

export default useStore;
