/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { OrderChangedSubscription as OrderChangedSubscriptionType, OrderChangedSubscriptionVariables } from "gql/types/subscriptions/OrderChangedSubscription";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`subscription OrderChangedSubscription($orderChangedInput: OrderChangedInput!) {
	orderChanged(orderChangedInput: $orderChangedInput) {
		_id
		orderStatus
		data
	}
}
`;

const OrderChangedSubscription: IGqlOperation<OrderChangedSubscriptionType, OrderChangedSubscriptionVariables> = { text } as any;

export default OrderChangedSubscription;
