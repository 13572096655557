import React, { useEffect, useState } from "react";

import { Box, Button, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from "react-router";
import useStore from "store";

import { TabInfo } from "api/types/TabInfo";

import BusinessInfoHeader from "components/BusinessInfoHeader";
import BusinessLogoBadge from "components/BusinessLogoBadge";
import Head from "components/Head";
import Layout from "components/Layout";
import AppLoading from "components/Loading";
import { OrderFulfillmentMethods } from "gql/types/globals";
import useForm from "hooks/useForm";
import useLocalize from "hooks/useLocalize";

import CustomerInputPanel from "./CustomerInputPanel";
import validateCustomerInfo from "./validateCustomerInfo";

const useStyles = makeStyles(theme => {
	return {
		container: {
			position: "relative",
			display: "flex",
			flexDirection: "column",
			flexGrow: 1,
			alignItems: "center",
			margin: 0,
			padding: theme.spacing(2),
			flex: 1
		},
		businessName: {
			textAlign: "center",
			width: "100%",
			margin: 0,
			padding: 0,
			paddingTop: theme.spacing(1),
			fontSize: "1.5rem",
			fontWeight: 600,
			color: theme.palette.primary.main
		},
		contents: {
			position: "relative",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			margin: 0,
			padding: 0,
			[theme.breakpoints.up("sm")]: {
				width: 600
			}
		},
		section: {
			display: "flex",
			flex: 1,
			width: "100%",
			flexDirection: "column",
			marginBottom: theme.spacing(1),
			justifyContent: "center",
			justifyItems: "center",
			alignContent: "center",
			alignItems: "center",
			[theme.breakpoints.up("sm")]: {
				width: 600
			}
		},
		title: {
			marginTop: theme.spacing(3),
			fontSize: "1.5rem"
		},
		subTitle: {
			textAlign: "center",
			margin: theme.spacing(2),
			fontSize: "1.2rem"
		},
		subTitle2: {
			textAlign: "center",
			fontSize: "1.2rem"
		},
		btn: {
			borderRadius: 8,
			width: "100%",
			maxWidth: 600
		},
		formControl: {
			textAlign: "left",
			margin: 0,
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		}
	};
});

interface Props {}

const Table: React.FC<Props> = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { l } = useLocalize();

	const business = useStore(store => store.business);
	const table = useStore(store => store.table);
	const customerInfo = useStore(store => store.customerInfo);
	const setCustomerInfo = useStore(store => store.setCustomerInfo);
	const setLoading = useStore(store => store.setLoading);
	const loading = useStore(store => store.loading);
	const orderTabOpen = useStore(store => store.orderTabOpen);

	const [openTabs, setOpenTabs] = useState<any>([]);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const params = useParams();

	const navigate = useNavigate();

	const {
		values,
		setValue,
		errors,
		onChange,
		onSubmit: submitForm
	} = useForm<TabInfo, Partial<TabInfo>>(
		() => {},
		{
			name: customerInfo.name
		},
		values => validateCustomerInfo(values)
	);

	if (!business) {
		return <AppLoading />;
	}

	const tableSlug = params.tableSlug;
	const businessTable = business.tables?.find(t => t && t.slug === tableSlug);

	const isEditing = !customerInfo.firstName || customerInfo!.firstName?.length == 0;

	if (!businessTable) {
		throw new Error(`Table not found: '${tableSlug}'`); // shouldn't happen
	}

	useEffect(() => {
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const updateAsync = async () => {
			if (values) {
				setCustomerInfo({
					name: values.name
				});
				if (!table || table._id !== businessTable._id) {
					await orderTabOpen({
						table: businessTable,
						name: values.name
					});
				}
				setLoading(false);
				navigate("..");
			}
		};

		if (isSubmitting) {
			updateAsync();
		}
	}, [isEditing, isSubmitting, navigate, orderTabOpen, setLoading, businessTable, table, values, setCustomerInfo]);

	const dineIn = business.orderFulfillmentTypes.find(t => t?.orderFulfillmentMethod === OrderFulfillmentMethods.dineIn);
	if (!dineIn) {
		console.error("NOT dineIn");
		// navigate("..");
	}

	const onCustomerInfoSubmit = isValid => {
		if (!isValid) {
			setIsSubmitting(false);
			setLoading(false);
		}
	};

	const onFormSubmit = async e => {
		e.preventDefault();
		if (!isSubmitting) {
			setLoading(true);
			setIsSubmitting(true);
			setValue({
				...values
			});
			submitForm(e);
		}
	};

	return (
		<Layout
			businessName={business.name}
			hideHeader
			pageTitle={t("dineInSetup")}
			bottom={
				<Button
					type="submit"
					size={"medium"}
					variant={"contained"}
					color={"secondary"}
					className={classes.btn}
					disabled={loading}
				>
					{t("next")}
				</Button>
			}
			onSubmit={onFormSubmit}
		>
			<Stack>
				<BusinessInfoHeader />

				<Box className={classes.container}>
					<BusinessLogoBadge />

					<Box className={classes.section}>
						{isEditing ? (
							<>
								<Typography className={clsx(classes.title)}>{t("welcomeTo")}</Typography>
							</>
						) : (
							<>
								<Typography className={clsx(classes.title)}>
									{t("welcomeCustomer", { name: customerInfo.firstName })}
								</Typography>
							</>
						)}

						<Typography className={clsx(classes.businessName)}>{l(business.localeNames)}</Typography>
						<Typography className={clsx(classes.businessName)}>
							{t("tableWithName", { name: businessTable.name })}
						</Typography>
						{openTabs.length > 0 && <Typography className={clsx(classes.subTitle)}>{t("areYouDiningWith")}</Typography>}
						{customerInfo!.firstName && customerInfo!.firstName?.length > 0 ? null : (
							<>
								<Typography className={clsx(classes.subTitle)}>{t("getTabStartedStep1")}</Typography>
								<Typography className={clsx(classes.subTitle2)}>{t("getTabStartedStep2")}</Typography>
								<CustomerInputPanel
									name={values!.name}
									// firstName={values!.firstName}
									// lastName={values!.lastName}
									errors={errors}
									onChange={onChange}
									onSubmit={onCustomerInfoSubmit}
								/>
							</>
						)}
					</Box>
				</Box>
			</Stack>
		</Layout>
	);
};

export default Table;
