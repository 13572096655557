/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { OrderTabFindQuery as OrderTabFindQueryType, OrderTabFindQueryVariables } from "gql/types/queries/OrderTabFindQuery";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`query OrderTabFindQuery($orderTabFindInput: OrderTabFindInput!) {
	orderTabFind(orderTabFindInput: $orderTabFindInput) {
		_id
		orderTabStatus
		tabHost {
			name
		}
	}
}
`;

const OrderTabFindQuery: IGqlOperation<OrderTabFindQueryType, OrderTabFindQueryVariables> = { text } as any;

export default OrderTabFindQuery;
