import React, { useEffect, useState } from "react";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/system";

import loadable from "@loadable/component";

import clsx from "clsx";
import { isIOS } from "react-device-detect";

import { Link, Route, Routes, useNavigate } from "react-router-dom";

import useStore from "store";

import FullMenuList from "components/FullMenuList";
import Head from "components/Head";

import OrderFulfillmentPanel from "components/OrderFulfillmentPanel";

import MenuIcon from "icons/MenuIcon";
import NomIcon from "icons/NomIcon";
import ProfileIcon from "icons/ProfileIcon";

import CartIcon from "./CartIcon";
import StoreIcon from "./StoreIcon";

import Table from "./Table/TableCustomerInfo";

// import StoreHoursWarningPanel from "components/StoreHoursWarningPanel";

const Account = loadable(() => import("./Account/Account"));
const Cart = loadable(() => import("./Cart"));
const Info = loadable(() => import("./Info"));
const Noms = loadable(() => import("./Noms"));

// import Account from "./Account/Account";
// import Cart from "./Cart";
// import Info from "./Info";

const StyledRoot = styled(Box)`
	min-height: 100%;
	overflow-x: hidden;
	background-color: #fff;
	/* background-color: ${p => p.theme.palette.background.paper}; */
`;

const StyledAppBar = styled(Box)`
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-items: center;
	justify-content: center;
	align-items: center;
	z-index: 100;
	bottom: 0;
	padding: 0;
	margin: 0;
	width: 100vw;
	background-color: transparent;
`;

const useStyles = makeStyles(theme => {
	return {
		bottomContainer: {
			textAlign: "center",
			height: 44,
			width: "calc(100vw - 100px)",
			minWidth: 275,
			borderRadius: 22,
			marginBottom: theme.spacing(3),
			[theme.breakpoints.up("sm")]: {
				width: 600
			}
			// backgroundColor: theme.palette.primary.main
		},
		bottomContainerShadow: {
			//filter: "drop-shadow(0px 12px 25px #000)"
			filter: "drop-shadow(0px 2px 4px #aaa)"
		},
		iOS: {
			height: 44,
			marginBottom: 30
		}
	};
});

const navIcon = makeStyles(theme => {
	return {
		root: {
			padding: 0,
			paddingBottom: "12px !important",
			paddingTop: "12px !important",
			minWidth: 40,
			fill: "#707070",
			"&$selected": {
				color: theme.palette.primary.main,
				fill: theme.palette.primary.main
			},
			[theme.breakpoints.down(400)]: {
				minWidth: 40
			}
		},
		wrapper: {
			padding: 0,
			width: 30,
			maxWidth: 30,
			minWidth: 30
		},
		selected: {
			color: theme.palette.primary.main,
			padding: 0,
			paddingBottom: "0px !important",
			paddingTop: "0px !important"
		}
	};
});

// tabs
const TAB_NOMS = "noms";
const TAB_MENU = "menu";
const TAB_CART = "cart";
const TAB_INFO = "info";
const TAB_ACCOUNT = "account";

interface Props {}

const MobileMain: React.FC<Props> = () => {
	const classes = useStyles();
	const navIcons = navIcon();
	const [value, setValue] = useState(TAB_NOMS);

	const business = useStore(store => store.business);
	const filtered = useStore(store => store.filtered);
	const isLoggedIn = useStore(store => store.isLoggedIn);
	const hasAccount = useStore(store => store.hasAccount);
	const applyTagFilterByTagSlug = useStore(store => store.applyTagFilterByTagSlug);

	const navigate = useNavigate();

	if (!business) {
		return null;
	}
	const path = window.location.pathname;

	useEffect(() => {
		if (!filtered) {
			applyTagFilterByTagSlug(business.defaultTagSlug || "highlights");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const filteredLinkPath = `${TAB_NOMS}/highlights/${filtered[0]?.slug}`;

	useEffect(() => {
		const segments: string[] = path.split("/");
		const tabIndex = segments.findIndex(segment => segment === "main") + 1;
		switch (segments[tabIndex]) {
			case TAB_NOMS:
			case TAB_MENU:
			case TAB_CART:
			case TAB_INFO:
			case TAB_ACCOUNT:
				setValue(segments[tabIndex]);
				break;
			case "table":
				break;
			default:
				navigate(filteredLinkPath);
				break;
		}
	}, [filteredLinkPath, navigate, path, setValue]);

	const onMenuItemChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<StyledRoot>
			<Head title={business.name} />
			{value !== TAB_ACCOUNT && <OrderFulfillmentPanel />}
			<Routes>
				<Route path={`${TAB_NOMS}`} element={<Noms />} />
				<Route path={`${TAB_NOMS}/:tagSlug/*`} element={<Noms />} />
				<Route path={`${TAB_NOMS}/:tagSlug/:slug/*`} element={<Noms />} />
				<Route
					path={`${TAB_MENU}/*`}
					element={<FullMenuList business={business!} hideOrderFulfillment hideHighlights />}
				/>
				<Route path={`${TAB_CART}/*`} element={<Cart />} />
				<Route path={`${TAB_INFO}/*`} element={<Info />} />
				<Route path={`${TAB_ACCOUNT}/*`} element={<Account />} />
				<Route path={"table/*"} element={<Table />} />
			</Routes>

			<StyledAppBar>
				{/* <StoreHoursWarningPanel /> */}
				{/* {currentTab && <TabName tabName={currentTab!.title!} description={currentTab?.desc} />} */}
				<BottomNavigation
					value={value}
					onChange={onMenuItemChange}
					showLabels={false}
					className={clsx(
						classes.bottomContainer,
						value !== TAB_NOMS && classes.bottomContainerShadow,
						isIOS && classes.iOS
					)}
				>
					<BottomNavigationAction
						classes={navIcons}
						component={Link}
						to={filteredLinkPath}
						value={TAB_NOMS}
						aria-label={TAB_NOMS}
						icon={<NomIcon />}
					/>
					<BottomNavigationAction
						classes={navIcons}
						component={Link}
						to={TAB_MENU}
						value={TAB_MENU}
						aria-label="menu"
						icon={<MenuIcon />}
					/>
					<BottomNavigationAction
						classes={navIcons}
						component={Link}
						to={TAB_CART}
						value={TAB_CART}
						aria-label="cart"
						icon={<CartIcon />}
					/>
					<BottomNavigationAction
						classes={navIcons}
						component={Link}
						to={TAB_INFO}
						value={TAB_INFO}
						aria-label="restaruant info"
						icon={<StoreIcon />}
					/>
					<BottomNavigationAction
						classes={navIcons}
						component={Link}
						to={isLoggedIn ? TAB_ACCOUNT : hasAccount ? "account/login" : "account/signup"}
						value={TAB_ACCOUNT}
						aria-label={TAB_ACCOUNT}
						icon={<ProfileIcon />}
					/>
					{/* <BottomNavigationAction
						classes={navIcons}
						component={Link}
						to="profile"
						value="profile"
						icon={<PersonIcon />}
					/> */}
				</BottomNavigation>
			</StyledAppBar>
		</StyledRoot>
	);
};

export default React.memo(MobileMain);
