const getDisplayPhoneNumber = (phoneNumber: string | undefined): string | undefined => {
	let phone = phoneNumber;
	if (phone) {
		if (phone.startsWith("+1")) {
			phone = phone.substr(2);
		}
		if (phone.length === 10) {
			phone = `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}`;
		}
	}
	return phone;
};

export default getDisplayPhoneNumber;
