import React, { Suspense } from "react";

import { ApolloProvider } from "@apollo/client";
import apolloClient from "apolloClient";
import { GA4React } from "ga-4-react";
import ReactDOM from "react-dom";

import TagManager from "react-gtm-module";

import AppLoading from "components/Loading";
import config from "config";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./i18n";

const tagManagerArgs = {
	gtmId: config.googleTagManagerKey
};

TagManager.initialize(tagManagerArgs);

// const ga4react = new GA4React(config.googleTagManagerKey!);
// ga4react.initialize().then(
// 	ga4 => {
// 		ga4.pageview("path");
// 		ga4.gtag("event", "pageview", "path"); // or your custom gtag event
// 	},
// 	err => {
// 		console.error(err);
// 	}
// );

let ga4react;
if (config.isGoogleAnalyticsEnabled) {
	ga4react = new GA4React(config.googleAnalyticsId!, {}, [], 5000);
	ga4react.initialize().then(
		ga4 => {
			ga4.pageview("path");
			ga4.gtag("event", "pageview", "path");
		},
		err => {
			console.error(err);
		}
	);
}
ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<AppLoading />}>
			<ApolloProvider client={apolloClient}>
				<App />
			</ApolloProvider>
		</Suspense>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
