const TOKEN_KEY = "at";

const listeners: ((token: string | null) => void)[] = [];

let _token: string | null = null;

export const setAccessToken = (token: string | null): void => {
	// Also store the token in localstorage
	if (token) {
		localStorage.setItem(TOKEN_KEY, token);
		_token = token;
	} else {
		localStorage.removeItem(TOKEN_KEY);
		_token = null;
	}

	listeners.forEach(x => x(token));
};

export const getAccessToken = (): string | null => {
	if (_token) {
		return _token;
	}
	return localStorage.getItem(TOKEN_KEY);
};

export const subscribeToAccessToken = (callback: (token: string | null) => void): void => {
	listeners.push(callback);
};
