/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from "react";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, List, ListSubheader, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import { AnimatePresence, motion } from "framer";

import { DateTime } from "luxon";

import {
	BusinessQuery_business_menuTags,
	BusinessQuery_business_menus_menuItems
} from "gql/types/queries/BusinessQuery";
import useLocalize from "hooks/useLocalize";

import MenuItem from "./MenuItem";

const useStyles = makeStyles(theme => {
	return {
		ul: {
			padding: 0,
			margin: 0
		},
		section: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			margin: 0,
			marginBottom: 3
		},
		container: {
			backgroundColor: "#f0f0f0",
			display: "flex",
			flex: 1,
			flexGrow: 1,
			flexDirection: "column",
			width: "100%",
			margin: 0,
			padding: theme.spacing(1)
		},
		children: {},
		name: {
			color: "#333",
			fontKerning: "normal",
			WebkitFontKerning: "normal",
			fontSize: 20,
			fontWeight: 600
		},
		sectionDesc: {
			fontSize: 16,
			color: "#606060",
			width: "100%"
		},
		desc: {
			fontSize: 16,
			fontStyle: "italic",
			width: "100%",
			display: "-webkit-box",
			lineClamp: 2,
			"-webkit-box-orient": "vertical",
			overflow: "hidden",
			textOverflow: "ellipsis"
		},
		descRow: {
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
			height: 48,
			maxHeight: 48
		},
		row: {
			display: "flex",
			width: "100%",
			flexDirection: "row",
			justifyItems: "center",
			alignItems: "center",
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		},
		list: {
			padding: 0
		},
		menuIcon: {
			height: 25
		},
		icon: {
			transition: "transform 0.25s ease"
		},
		rotated: {
			transform: "rotate(180deg)"
		},
		justify: {
			justifyContent: "space-between"
		},
		right: {
			justifyContent: "flex-end"
		},
		col: {
			display: "flex",
			flexDirection: "column"
		},
		price: {
			marginRight: 5
		}
	};
});

const spring = {
	duration: 0.25,
	opacity: { duration: 0.2, delay: 0.1 }
};

interface Props {
	sectionTag: BusinessQuery_business_menuTags;
	menuItems: (BusinessQuery_business_menus_menuItems | null)[];
	isDynamic?: boolean;
}

const MenuSection: React.FC<Props> = ({ sectionTag, menuItems, isDynamic }) => {
	const classes = useStyles();
	const { l } = useLocalize();
	const [open, setOpen] = useState(true);

	if (!sectionTag) {
		return null;
	}

	const toggleSection = () => {
		setOpen(!open);
	};

	const filtered = isDynamic
		? menuItems.sort((a, b) => (a!.name > b!.name ? 1 : -1))
		: menuItems!
				.filter(m => m && m.tags!.find(t => t && t.slug === sectionTag.slug))
				.sort((a, b) => (a!.name > b!.name ? 1 : -1));

	return (
		<ul className={classes.ul}>
			<Box className={classes.section}>
				<ListSubheader className={classes.container} onClick={toggleSection}>
					<Box className={clsx(classes.row, classes.justify)}>
						<Box className={clsx(classes.col)}>
							<Typography className={classes.name}>{l(sectionTag.localeTags, sectionTag.tag)}</Typography>
							{sectionTag.localeDescriptions && (
								<Typography className={classes.sectionDesc}>{l(sectionTag.localeDescriptions)}</Typography>
							)}
						</Box>

						<KeyboardArrowUpIcon className={clsx(classes.icon, !open && classes.rotated)} />
					</Box>
				</ListSubheader>
				<AnimatePresence>
					{open && (
						<motion.span
							layout
							key={sectionTag.slug}
							transition={spring}
							initial={{ height: 0, opacity: 0 }}
							animate={{ height: "auto", opacity: 1 }}
							exit={{ height: 0, opacity: 0 }}
						>
							<List className={classes.list}>
								{filtered?.map(f => f && <MenuItem key={`${sectionTag}-${f._id}`} menuItem={f} />)}
							</List>
						</motion.span>
					)}
				</AnimatePresence>
			</Box>
		</ul>
	);
};

export default React.memo(MenuSection);
