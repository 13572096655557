import { ApolloLink, Observable } from "@apollo/client";

import { getAccessToken } from "utils/accessToken";
import { getDeviceToken } from "utils/deviceToken";

const request = async operation => {
	// use the accessToken if it exists, otherwise use the device token
	let token = getAccessToken();
	if (token) {
		operation.setContext({
			headers: {
				Authorization: `Bearer ${token}`,
				"x-access-token": token
			}
		});
	} else {
		token = getDeviceToken();
		if (token) {
			operation.setContext({
				headers: {
					Authorization: `Bearer ${token}`,
					"x-device-token": token
				}
			});
		}
	}
};

export const observerLink = new ApolloLink(
	(operation, forward) =>
		new Observable(observer => {
			let handle;
			try {
				Promise.resolve(operation)
					.then(oper => request(oper))
					.then(() => {
						handle = forward(operation).subscribe({
							next: observer.next.bind(observer),
							error: observer.error.bind(observer),
							complete: observer.complete.bind(observer)
						});
					})
					.catch(err => {
						console.log("gql subscription err:", err);
						observer.error.bind(observer);
					});
				return () => {
					try {
						if (handle) {
							handle.unsubscribe();
						}
					} catch (e) {
						console.error("error in unsubscribe", e);
					}
				};
			} catch (error) {
				console.error("observable err", error);
			}
		})
);
