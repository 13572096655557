import React, { useCallback, useEffect, useState } from "react";

import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import useStore from "store";
import { getNextOrderFulfillmentTime, getTimeSlots } from "utils/timeUtils";

import getTimeString from "utils/timeUtils/getTimeString";

import OrderFulfillmentType from "api/types/OrderFulfillmentType";
import CalendarStrip from "components/CalendarStrip";
import { BusinessQuery_business } from "gql/types/queries/BusinessQuery";

const useStyles = makeStyles(theme => {
	return {
		tabRoot: {
			display: "grid",
			padding: 0,
			margin: 0,
			overflowX: "hidden",
			overflowY: "auto",
			height: "calc(100vh - 100px)",
			gridTemplateRows: "1fr 70px",
			[theme.breakpoints.up("sm")]: {
				width: 500,
				height: "calc(100vh - 170px)",
				overflowY: "auto"
			}
		},
		content: {
			gridRow: "1 / 2",
			overflowX: "hidden",
			overflowY: "auto",
			display: "flex",
			flexDirection: "column",
			padding: 0,
			margin: 0,
			flex: 1,
			flexGrow: 1,
			marginTop: theme.spacing(3),
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
			[theme.breakpoints.up("sm")]: {
				overflowY: "auto"
			}
		},
		scroll: {
			display: "flex",
			flex: 1,
			flexDirection: "column",
			maxHeight: "calc(var(--vh, 1vh) * 100 - 280px)",
			padding: 0,
			margin: 0,
			overflowY: "auto",
			[theme.breakpoints.up("sm")]: {
				width: "100%",
				elevation: 10,
				maxHeight: "calc(var(--vh, 1vh) * 100 - 400px)"
			}
		},
		section: {
			marginTop: theme.spacing(3)
		},
		option: {
			width: "100%",
			padding: 0,
			margin: 0,
			borderBottom: "1px solid #eee"
		},
		noBorder: {
			border: "none"
		},
		inactive: {
			color: theme.palette.text.disabled
		},
		bottom: {
			gridRow: "2 / 2",
			padding: theme.spacing(1),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "100%"
		},
		row: {
			display: "flex",
			flexDirection: "row"
		},
		btn: {
			height: 45,
			borderRadius: 8,
			width: "100%"
		},
		btnText: {
			fontSize: "1.1rem"
		},
		selectedValue: {
			fontSize: 22,
			textAlign: "center",
			borderBottom: "1px solid #eee",
			marginBottom: theme.spacing(2)
		},
		calendar: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1)
		},
		closed: {
			marginTop: theme.spacing(1),
			fontSize: 18
		}
	};
});

interface Props {
	business: BusinessQuery_business;
	onClose: () => void;
	orderFulfillmentType: OrderFulfillmentType;
}

const PickupTab: React.FC<Props> = ({ business, onClose, orderFulfillmentType }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const isAsapOrder = useStore(store => store.isAsapOrder);
	const deliveryOrPickupAt = useStore(store => store.deliveryOrPickupAt);
	const orderFulfillmentTimeString = useStore(store => store.orderFulfillmentTimeString);
	const setIsAsapOrder = useStore(store => store.setIsAsapOrder);
	const setDeliveryOrPickupAt = useStore(store => store.setDeliveryOrPickupAt);
	const setOrderFulfillmentType = useStore(store => store.setOrderFulfillmentType);
	const setOrderFulfillmentTimeString = useStore(store => store.setOrderFulfillmentTimeString);
	const setLoading = useStore(store => store.setLoading);

	const convertedDeliveryOrPickupAt =
		deliveryOrPickupAt && deliveryOrPickupAt?.toMillis
			? deliveryOrPickupAt
			: deliveryOrPickupAt
			? DateTime.fromISO(deliveryOrPickupAt!.toString())
			: DateTime.local();

	const [selectedDate, setSelectedDate] = useState(convertedDeliveryOrPickupAt);
	const [value, setValue] = useState(convertedDeliveryOrPickupAt ? convertedDeliveryOrPickupAt?.toMillis() : 0);

	const [isAsap, setIsAsap] = useState<boolean>(isAsapOrder);
	const [previewValue, setPreviewValue] = useState<string | null>(orderFulfillmentTimeString);
	const [isScheduleChanged, setIsScheduleChanged] = useState<boolean>(false);

	const prepTime = orderFulfillmentType!.minPrepTimeMinutes ? orderFulfillmentType!.minPrepTimeMinutes : 15;

	const orderingInterval = business?.orderingIntervalMinutes ? business.orderingIntervalMinutes : 15;
	const startTime = selectedDate.hasSame(DateTime.local(), "day") ? DateTime.local() : selectedDate.startOf("day");

	const time = getNextOrderFulfillmentTime(startTime, prepTime, business!.orderingHours!, business!.timeZone!);

	const calendarStart =
		getNextOrderFulfillmentTime(DateTime.local(), prepTime, business!.orderingHours!, business!.timeZone!) ??
		DateTime.local();

	const asapSelected = useCallback(() => {
		setIsAsap(true);
		setIsScheduleChanged(false); // reset
		setValue(0);

		const timeString = getTimeString(business, orderFulfillmentType, true, time);
		setPreviewValue(timeString);
	}, [business, orderFulfillmentType, time]);

	useEffect(() => {
		if (isAsap && orderFulfillmentTimeString?.startsWith("Table")) {
			asapSelected();
		}
	}, [asapSelected, isAsap, orderFulfillmentTimeString]);

	const timeSlots = getTimeSlots(time!, orderingInterval, prepTime, business!.orderingHours!, business!.timeZone!).map(
		(s, indx) => ({
			label: s.toFormat("h:mm a"),
			isAsapOrder: indx === 0,
			value: s
		})
	);

	const timeChanged = event => {
		setIsAsap(false);
		const newValue = event.target.value;
		setValue(newValue);
		setIsScheduleChanged(false); // reset
		const timeString = getTimeString(
			business,
			orderFulfillmentType,
			isAsap,
			DateTime.fromMillis(parseInt(newValue.toString()))
		);
		setPreviewValue(timeString);
	};

	const updateClicked = async () => {
		setLoading(true);
		setIsScheduleChanged(true);
		await setOrderFulfillmentType(orderFulfillmentType);

		const timeString = getTimeString(
			business,
			orderFulfillmentType,
			isAsap,
			DateTime.fromMillis(parseInt(value.toString()))
		);

		// setOrderDeliveryAddress(null); // don't clear the address
		if (isAsap) {
			setIsAsapOrder(true);
			// setOrderFulfillmentTimeString(t("asapWithRange", { start: prepTime, end: maxPrepTime }));
		} else {
			const due = DateTime.fromMillis(parseInt(value.toString()));
			setDeliveryOrPickupAt(due);
			// setOrderFulfillmentTimeString(due.toFormat("h:mm a"));
		}
		setOrderFulfillmentTimeString(timeString);
		setLoading(false);
		onClose();
	};

	const onSelectedDateChanged = useCallback(
		value => {
			const newValue = (value as DateTime).hasSame(DateTime.local(), "day")
				? value
				: (value as DateTime).startOf("day");

			setSelectedDate(newValue);
			setIsScheduleChanged(true);
			setIsAsap(false);

			const preview = `${newValue.toFormat("ccc M/d")} ...`;

			setPreviewValue(preview);
		},
		[setSelectedDate, setIsScheduleChanged]
	);

	const asapTimeString = getTimeString(business, orderFulfillmentType, true, time);

	// Get the schedule start date

	const slotsToRender =
		timeSlots &&
		timeSlots
			.map((s, indx) => {
				if (indx === 0 && selectedDate.hasSame(DateTime.local(), "day")) {
					// Skip the first entry
					return null;
				} else {
					const stringVal = value.toString();

					if (s.value.day === ((time && time.day) || selectedDate.day)) {
						return (
							<FormControlLabel
								className={clsx(classes.option, isAsap && classes.inactive)}
								key={indx}
								value={s.value.toMillis().toString()}
								checked={s.value.toMillis().toString() === stringVal}
								control={<Radio />}
								label={s.label}
							/>
						);
					}
				}
			})
			.filter(f => f && f !== undefined);

	return (
		<Box className={classes.tabRoot}>
			<Box className={classes.content}>
				<Box className={classes.selectedValue}>{previewValue}</Box>
				<FormControlLabel
					className={clsx(classes.option, classes.noBorder)}
					onChange={asapSelected}
					value={"0"}
					checked={isAsap}
					control={<Radio />}
					label={asapTimeString}
				/>
				<Typography className={classes.section}>{t("scheduled")}</Typography>

				{business.orderAheadMaxDays && (
					<CalendarStrip
						isActive={!isAsap}
						className={classes.calendar}
						daysAhead={business.orderAheadMaxDays}
						selectedDate={selectedDate}
						startDate={calendarStart}
						onChange={onSelectedDateChanged}
					/>
				)}
				<Box className={classes.scroll}>
					<RadioGroup aria-label="pickup time" name="pickupTime" value={value} onChange={timeChanged}>
						{slotsToRender && slotsToRender.length > 0 ? (
							slotsToRender
						) : (
							<Typography className={classes.closed}>{t("closed")}</Typography>
						)}
					</RadioGroup>
				</Box>
			</Box>
			<Box className={classes.bottom}>
				<Button
					variant={"contained"}
					color="secondary"
					className={classes.btn}
					onClick={updateClicked}
					disabled={isScheduleChanged}
				>
					<Typography className={classes.btnText}>{t("update")}</Typography>
				</Button>
			</Box>
		</Box>
	);
};

export default PickupTab;
