import { DateTime } from "luxon";

import ScheduleException from "api/types/ScheduleException";
import { BusinessQuery_business_orderingHours } from "gql/types/queries/BusinessQuery";

/**
 * Returns all future schedule exceptions
 * @param testTime
 * @param endTime
 * @param schedule
 * @returns
 */
const getScheduleExceptions = (
	testTime: DateTime,
	schedule: BusinessQuery_business_orderingHours,
	timeZone: string
): (ScheduleException | null)[] => {
	const ret = [];
	if (schedule.scheduleExceptions) {
		return schedule.scheduleExceptions.filter(
			s =>
				(s &&
					s.date &&
					DateTime.fromISO(s.date).setZone(timeZone).startOf("day") <= testTime &&
					testTime < DateTime.fromISO(s.date).setZone(timeZone).endOf("day")) ||
				(s &&
					s.startsAt &&
					s.endsAt &&
					testTime >= DateTime.fromISO(s?.startsAt) &&
					testTime <= DateTime.fromISO(s?.endsAt))
		);
	}
	return ret;
};

export default getScheduleExceptions;
