import { StyledEngineProvider } from "@mui/material";

import { useApolloClient } from "@apollo/react-hooks";

import analytics from "analytics";
import { BrowserRouter } from "react-router-dom";

import { initStore } from "store";

import useViewHeight from "hooks/useViewHeight";

import AppRoutes from "./AppRoutes";

import "../style/app.css";

function App() {
	const client = useApolloClient();
	useViewHeight();
	initStore(client);
	analytics.initialize();

	return (
		<BrowserRouter>
			<StyledEngineProvider injectFirst>
				<AppRoutes />
			</StyledEngineProvider>
		</BrowserRouter>
	);
}

export default App;
