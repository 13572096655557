/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { ActiveBusinessPromotionsQuery as ActiveBusinessPromotionsQueryType, ActiveBusinessPromotionsQueryVariables } from "gql/types/queries/ActiveBusinessPromotionsQuery";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`query ActiveBusinessPromotionsQuery($businessPromotionsInput: BusinessPromotionsInput!) {
	activeBusinessPromotions(businessPromotionsInput: $businessPromotionsInput) {
		_id
		businessId
		name
		localeNames
		localeDescriptions
		localeTerms
		mediaLinks {
			mediaUrl
			mediaType
		}
		promotionType
		orderMinimumCents
		discountCents
		discountPercent
		isAutomatic
		isStackable
		showOnLanding
	}
}
`;

const ActiveBusinessPromotionsQuery: IGqlOperation<ActiveBusinessPromotionsQueryType, ActiveBusinessPromotionsQueryVariables> = { text } as any;

export default ActiveBusinessPromotionsQuery;
