/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { OrderedItemsQuery as OrderedItemsQueryType, OrderedItemsQueryVariables } from "gql/types/queries/OrderedItemsQuery";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`query OrderedItemsQuery ($businessId: String!){
	orderedItems(businessId: $businessId)
}
`;

const OrderedItemsQuery: IGqlOperation<OrderedItemsQueryType, OrderedItemsQueryVariables> = { text } as any;

export default OrderedItemsQuery;
