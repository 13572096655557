/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { AddOrderItemsMutation as AddOrderItemsMutationType, AddOrderItemsMutationVariables } from "gql/types/mutations/AddOrderItemsMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation AddOrderItemsMutation($addOrderItemsInput: AddOrderItemsInput!) {
	addOrderItems(addOrderItemsInput: $addOrderItemsInput) {
		_id
	}
}
`;

const AddOrderItemsMutation: IGqlOperation<AddOrderItemsMutationType, AddOrderItemsMutationVariables> = { text } as any;

export default AddOrderItemsMutation;
