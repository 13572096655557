import React, { useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router";
import useStore from "store";

import OrderFulfillmentType from "api/types/OrderFulfillmentType";
import QrCodeScanner from "components/QrCodeScanner";
import TableQrCode from "components/TableQrCode/TableQrCode";
import { BusinessQuery_business } from "gql/types/queries/BusinessQuery";

const useStyles = makeStyles(theme => {
	return {
		tabRoot: {
			display: "grid",
			padding: 0,
			margin: 0,
			overflowX: "hidden",
			overflowY: "auto",
			height: "100%",
			gridTemplateRows: "1fr 70px",
			[`${theme.breakpoints.up("md")} and (orientation: landscape)`]: {
				width: 500,
				overflowY: "auto"
			}
		},
		content: {
			gridRow: "1 / 2",
			overflowX: "hidden",
			overflowY: "scroll",
			display: "flex",
			flexDirection: "column",
			padding: 0,
			margin: 0,
			flex: 1,
			flexGrow: 1,
			marginTop: theme.spacing(3),
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
			[theme.breakpoints.up("sm")]: {
				overflowY: "auto"
			}
		},
		tableName: {
			fontSize: "1.3rem",
			fontWeight: 600,
			textAlign: "center"
		},
		bottom: {
			gridRow: "2 / 2",
			padding: theme.spacing(1),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "100%"
		},
		row: {
			display: "flex",
			flexDirection: "row"
		},
		qrContainer: {
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(4),
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center"
		},
		sharingInstructions: {
			fontSize: "1.2rem"
		},
		sharingInstructions2: {
			fontSize: "1.2rem",
			marginBottom: theme.spacing(4)
		},
		qr: {
			minHeight: 250,
			minWidth: 250
		},
		shareIconBtn: {
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.primary.contrastText,
			marginTop: theme.spacing(4)
		},
		instructions: {
			fontSize: 18,
			textAlign: "center"
		},
		scannerContainer: {
			paddingTop: theme.spacing(2),
			minHeight: 300,
			width: "100%",
			margin: 0
		},
		btnText: {
			fontSize: "1.1rem"
		},
		bottomBtn: {
			height: 45,
			width: "100%"
		}
	};
});

interface Props {
	business: BusinessQuery_business;
	onClose: () => void;
	orderFulfillmentType: OrderFulfillmentType;
}

const DineInTab: React.FC<Props> = ({ business, onClose, orderFulfillmentType }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const locale = useStore(store => store.locale);
	const table = useStore(store => store.table);
	const orderTabFind = useStore(store => store.orderTabFind);
	const orderTabOpen = useStore(store => store.orderTabOpen);
	const orderTabJoin = useStore(store => store.orderTabJoin);
	const customerInfo = useStore(store => store.customerInfo);
	const loading = useStore(store => store.loading);
	const setLoading = useStore(store => store.setLoading);

	const [isScanningEnabled, setIsScanningEnabled] = useState(false);

	const navigate = useNavigate();

	const onScanned = async result => {
		setIsScanningEnabled(false);
		setLoading(true);
		const scannedTable = business.tables?.find(t => t && t.slug === result.tableSlug);
		if (scannedTable) {
			if (result.isJoinExisting) {
				0;
				const order = await orderTabFind(result.joinId!, result.tableSlug);
				if (scannedTable && order) {
					await orderTabJoin({
						table: scannedTable,
						orderId: order!._id!,
						joinId: result.joinId!,
						name: customerInfo.name
					});
					navigate(`/${locale}/main`);
				}
			} else {
				await orderTabOpen({
					table: scannedTable,
					name: customerInfo.name!
				});
				setLoading(false);
			}
		}
	};

	const joinAnotherOrderClicked = () => {
		if (!isScanningEnabled) {
			setIsScanningEnabled(true);
		} else {
			setIsScanningEnabled(false);
		}
	};

	return (
		<Box className={classes.tabRoot}>
			<Box className={classes.content}>
				{loading ? (
					<Typography className={clsx(classes.tableName)}>{t("joining")}</Typography>
				) : (
					<>
						{table && (
							<Typography className={clsx(classes.tableName)}>{t("tableWithName", { name: table.name })}</Typography>
						)}

						{isScanningEnabled ? (
							<>
								<Typography className={clsx(classes.instructions)}>{t("scanPhoneQrCode")}</Typography>
								<Box className={classes.scannerContainer}>
									<QrCodeScanner onScanned={onScanned} />
								</Box>
							</>
						) : (
							<>
								{!table && <QrCodeScanner onScanned={onScanned} />}
								{table ? (
									<TableQrCode businessName={business.name} tableSlug={table.slug} />
								) : (
									<Box className={classes.qrContainer}>
										<Typography className={classes.sharingInstructions}>To start ordering:</Typography>
										<Typography className={classes.sharingInstructions2}>{t("scanTableQrCode")}</Typography>
										<Typography className={classes.sharingInstructions2}>{t("or")}</Typography>
										<Typography className={classes.sharingInstructions}>{t("joinInstructions")}</Typography>
										<Typography className={classes.sharingInstructions}>{t("joinInstructions2")}</Typography>
									</Box>
								)}
							</>
						)}
					</>
				)}
			</Box>
			<Box className={classes.bottom}>
				<Button variant={"contained"} color="secondary" className={classes.bottomBtn} onClick={joinAnotherOrderClicked}>
					<Typography className={classes.btnText}>{!isScanningEnabled ? t("rescan") : t("cancel")}</Typography>
				</Button>
			</Box>
		</Box>
	);
};

export default DineInTab;
