import React from "react";

import { Route, Routes } from "react-router";

import OrderFulfillmentModal from "components/OrderFulfillmentModal";

export const ORDER_TYPE = "ordertype";

interface Props {
	path: string;
}

const OrderFulfillmentModalRoutes: React.FC<Props> = ({ path }) => {
	return (
		<Routes>
			<Route path={path} element={<OrderFulfillmentModal />} />
		</Routes>
	);
};

export default React.memo(OrderFulfillmentModalRoutes);
