/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { CustomerUpdateProfileMutation as CustomerUpdateProfileMutationType, CustomerUpdateProfileMutationVariables } from "gql/types/mutations/CustomerUpdateProfileMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation CustomerUpdateProfileMutation($customerUpdateProfileInput: CustomerUpdateProfileInput!) {
	customerUpdateProfile(customerUpdateProfileInput: $customerUpdateProfileInput) {
		firstName
		lastName
		name
		email
		phoneNumber
	}
}
`;

const CustomerUpdateProfileMutation: IGqlOperation<CustomerUpdateProfileMutationType, CustomerUpdateProfileMutationVariables> = { text } as any;

export default CustomerUpdateProfileMutation;
