/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { RemoveOrderItemMutation as RemoveOrderItemMutationType, RemoveOrderItemMutationVariables } from "gql/types/mutations/RemoveOrderItemMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation RemoveOrderItemMutation($removeOrderItemInput: RemoveOrderItemInput!) {
	removeOrderItem(removeOrderItemInput: $removeOrderItemInput) {
		_id
	}
}
`;

const RemoveOrderItemMutation: IGqlOperation<RemoveOrderItemMutationType, RemoveOrderItemMutationVariables> = { text } as any;

export default RemoveOrderItemMutation;
