/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { BeginOtpAuthenticationMutation as BeginOtpAuthenticationMutationType, BeginOtpAuthenticationMutationVariables } from "gql/types/mutations/BeginOtpAuthenticationMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation BeginOtpAuthenticationMutation($beginOtpAuthenticationInput: BeginOtpAuthenticationInput!) {
	beginOtpAuthentication(beginOtpAuthenticationInput: $beginOtpAuthenticationInput)
}
`;

const BeginOtpAuthenticationMutation: IGqlOperation<BeginOtpAuthenticationMutationType, BeginOtpAuthenticationMutationVariables> = { text } as any;

export default BeginOtpAuthenticationMutation;
