import React from "react";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Drawer, Link, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import { motion } from "framer-motion";
// import clientHeight from "utils/clientHeight";

import useStore from "store";

import Head from "components/Head";
import ProgressBar from "components/ProgressBar";

// const height = clientHeight;
const useStyles = makeStyles(theme => {
	return {
		container: {
			position: "relative",
			top: 0,
			display: "flex",
			flexDirection: "column",
			backgroundColor: theme.palette.background.default,
			padding: 0,
			overflow: "hidden",
			overflowY: "auto",
			minWidth: "100%",
			height: "100%",
			minHeight: "100%",
			justifyContent: "space-between",
			objectFit: "cover"
		},
		bottomContainer: {
			bottom: 0,
			left: 0,
			right: 0,
			position: "fixed",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			textAlign: "center",
			margin: 0,
			padding: theme.spacing(2)
		},
		progress: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			zIndex: 5000
		},
		hdr: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText,
			padding: 0,
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			borderRadius: 0
		},
		hdrLink: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			color: theme.palette.primary.contrastText,
			padding: 0,
			borderRadius: 0
		},
		section: {
			fontSize: 16,
			fontWeight: 600,
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1)
		},
		innerContainer: {
			display: "flex",
			flexDirection: "column",
			// height: clientHeight,
			// minHeight: "100%",

			minWidth: "100%",
			justifyContent: "center",
			padding: 0,
			paddingBottom: theme.spacing(2),
			[theme.breakpoints.up("sm")]: {
				elevation: 10
				// height: "calc(var(--vh, 1vh) * 100 - 110px)"
			},
			[theme.breakpoints.up("md")]: {
				elevation: 10,
				textAlign: "center",
				justifyContent: "center"
			}
		},
		scrollContainer: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			justifyContent: "flex-start",
			overflowX: "hidden",
			marginRight: -10,
			paddingRight: 10
		},
		title: {
			fontSize: 24,
			textAlign: "center"
		},
		right: {
			textAlign: "right",
			width: "100%",
			[theme.breakpoints.up("sm")]: {
				alignSelf: "center",
				maxWidth: 700
			}
		},
		bottom: {
			height: "auto",
			paddingTop: 0,
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			paddingBottom: theme.spacing(2),
			margin: 0,
			width: "100%",
			[theme.breakpoints.up("sm")]: {
				alignSelf: "center",
				maxWidth: 600
			}
		}
	};
});

interface Props {
	businessName: string;
	className?: string;
	pageTitle?: string;
	hideHeader?: boolean;
	onBackClick?: () => void;
	onSubmit?: (e) => void;
	children: React.ReactElement | React.ReactElement[];
	bottom?: React.ReactElement;
}

const Layout: React.FC<Props> = ({
	businessName,
	className,
	pageTitle,
	children,
	bottom,
	onBackClick,
	onSubmit,
	hideHeader = false
}) => {
	const classes = useStyles();
	const loading = useStore(store => store.loading);

	const renderLayout = () => {
		return (
			<Box className={clsx(classes.container, className)}>
				{pageTitle ? <Head title={businessName} subTitle={pageTitle} /> : <Head title={businessName} subTitle={""} />}
				{loading && <ProgressBar className={classes.progress} />}
				<Box className={classes.innerContainer}>
					<>
						{pageTitle && !hideHeader && (
							<Paper className={classes.hdr}>
								<motion.div
									layout
									transition={{ duration: 0.25, ease: "easeOut" }}
									initial={{ x: 100, opacity: 0 }}
									exit={{ opacity: 0 }}
									animate={{ x: 0, opacity: 1 }}
								>
									{onBackClick ? (
										<Link onClick={onBackClick} className={classes.hdrLink}>
											<ArrowBackIosIcon />
											<Typography className={classes.section}>{pageTitle}</Typography>
										</Link>
									) : (
										<Typography className={classes.section}>{pageTitle}</Typography>
									)}
								</motion.div>
							</Paper>
						)}
						{children}
					</>
					<Box className={classes.bottomContainer}>{bottom}</Box>
				</Box>
			</Box>
		);
	};
	if (onSubmit) {
		return <form onSubmit={onSubmit}>{renderLayout()}</form>;
	} else {
		return renderLayout();
	}
};

export default Layout;
