import React, { useEffect, useMemo, useState } from "react";

import { Box, Button, FormControlLabel, Radio, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import useStore from "store";
import { getNextOrderFulfillmentTime, getTimeSlots } from "utils/timeUtils";

import getTimeString from "utils/timeUtils/getTimeString";

import Business from "api/types/Business";
import OrderFulfillmentType from "api/types/OrderFulfillmentType";
import { OrderFulfillmentMethods } from "gql/types/globals";

import DeliveryInfo from "./DeliveryInfo";
import TimeList from "./TimeList";

const useStyles = makeStyles(theme => {
	return {
		tabRoot: {
			display: "grid",
			padding: 0,
			margin: 0,
			overflowX: "hidden",
			overflowY: "auto",
			height: "100%",
			gridTemplateRows: "1fr 70px",
			[`${theme.breakpoints.up("md")} and (orientation: landscape)`]: {
				width: 500,
				overflowY: "auto"
			}
		},
		content: {
			gridRow: "1 / 2",
			overflowX: "hidden",
			overflowY: "scroll",
			display: "flex",
			flexDirection: "column",
			padding: 0,
			margin: 0,
			flex: 1,
			flexGrow: 1,
			marginTop: theme.spacing(3),
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
			[theme.breakpoints.up("sm")]: {
				overflowY: "auto"
			}
		},
		section: {
			marginTop: theme.spacing(3)
		},
		option: {
			// width: "100%",
			padding: 0,
			margin: 0,
			borderBottom: "1px solid #eee"
		},
		bottom: {
			gridRow: "2 / 2",
			padding: theme.spacing(1),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "100%"
		},
		row: {
			display: "flex",
			flexDirection: "row"
		},
		btn: {
			height: 45,
			borderRadius: 8,
			width: "100%"
		},
		btnText: {
			fontSize: "1.1rem"
		},
		deliveryArea: {
			marginBottom: theme.spacing(2)
		}
	};
});

interface Props {
	business: Business;
	orderFulfillmentType: OrderFulfillmentType;
	onClose: () => void;
}

const DeliveryTab: React.FC<Props> = ({ business, onClose, orderFulfillmentType }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const isAsapOrder = useStore(state => state.isAsapOrder);
	const setOrderFulfillmentType = useStore(state => state.setOrderFulfillmentType);
	const deliveryOrPickupAt = useStore(state => state.deliveryOrPickupAt);
	const setIsAsapOrder = useStore(action => action.setIsAsapOrder);
	const setDeliveryOrPickupAt = useStore(action => action.setDeliveryOrPickupAt);
	const orderDeliveryAddress = useStore(state => state.orderDeliveryAddress);
	const setOrderFulfillmentTimeString = useStore(action => action.setOrderFulfillmentTimeString);

	const convertedDeliveryOrPickupAt =
		deliveryOrPickupAt && deliveryOrPickupAt?.toMillis
			? deliveryOrPickupAt
			: deliveryOrPickupAt
			? DateTime.fromISO(deliveryOrPickupAt!.toString())
			: null;

	const [value, setValue] = useState(convertedDeliveryOrPickupAt ? convertedDeliveryOrPickupAt?.toMillis() : 0);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isAsap, setIsAsap] = useState<boolean>(isAsapOrder);
	const [hasAddress, setHasAddress] = useState<boolean>(orderDeliveryAddress !== null);

	useEffect(() => {
		if (isSubmitting) {
			setIsSubmitting(false);
			onClose();
		}
	}, [isSubmitting, setIsSubmitting, onClose]);

	const orderFulfillmentMethod = orderFulfillmentType?.orderFulfillmentMethod;

	const prepTime = orderFulfillmentType!.minPrepTimeMinutes!;
	const totalLeadTime =
		orderFulfillmentMethod === OrderFulfillmentMethods.delivery
			? prepTime + orderFulfillmentType!.defaultDeliveryTimeMinutes!
			: prepTime;

	const orderingInterval = business?.orderingIntervalMinutes ? business.orderingIntervalMinutes : 15;
	const nextOrderFulfillmentTime = getNextOrderFulfillmentTime(
		DateTime.local(),
		totalLeadTime,
		business.orderingHours,
		business.timeZone!
	);

	const timeSlots = useMemo(
		() =>
			getTimeSlots(
				nextOrderFulfillmentTime!.plus({ minutes: 10 }),
				orderingInterval,
				totalLeadTime,
				business!.orderingHours!,
				business.timeZone!
			).map((s, indx) => ({
				label: s.toFormat("h:mm a"),
				isAsapOrder: indx === 0,
				value: s
			})),
		[business, nextOrderFulfillmentTime, orderingInterval, totalLeadTime]
	);

	const asapSelected = () => {
		setIsAsap(true);
		setValue(0);
	};

	const timeChanged = useMemo(
		() => event => {
			setIsAsap(false);
			setValue(event.target.value);
		},
		[setIsAsap, setValue]
	);

	const update = () => {
		setOrderFulfillmentType(orderFulfillmentType);

		const timeString = getTimeString(
			business,
			orderFulfillmentType,
			isAsap,
			DateTime.fromMillis(parseInt(value.toString()))
		);
		if (isAsap) {
			setIsAsapOrder(true);
		} else {
			const due = DateTime.fromMillis(parseInt(value.toString()));
			setDeliveryOrPickupAt(due);
		}
		setOrderFulfillmentTimeString(timeString);
		setIsSubmitting(true);
	};

	const onAddressChanged = hasValue => {
		if (hasValue) {
			setHasAddress(hasValue);
		}
	};

	const asapTimeString = getTimeString(business, orderFulfillmentType, true, nextOrderFulfillmentTime);

	return (
		<Box className={classes.tabRoot}>
			<Box className={classes.content}>
				<DeliveryInfo
					business={business}
					orderFulfillmentType={orderFulfillmentType}
					isSubmitting={isSubmitting}
					onAddressChanged={onAddressChanged}
				/>

				<FormControlLabel
					className={classes.option}
					onChange={asapSelected}
					value={"0"}
					checked={isAsap}
					control={<Radio />}
					label={asapTimeString}
				/>

				<Typography className={classes.section}>{t("scheduled")}</Typography>
				<TimeList timeSlots={timeSlots} onTimeSelected={timeChanged} value={value} />
			</Box>
			<Box className={classes.bottom}>
				<Button variant={"contained"} color="secondary" className={classes.btn} onClick={update} disabled={!hasAddress}>
					<Typography className={classes.btnText}>{t("update")}</Typography>
				</Button>
			</Box>
		</Box>
	);
};

export default DeliveryTab;
