import React, { useEffect, useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useStore from "store";

import Layout from "components/Layout";
import AppLoading from "components/Loading";
import Redirect from "components/Redirect";

import useLocalize from "hooks/useLocalize";

const useStyles = makeStyles(theme => {
	return {
		root: {
			display: "flex",
			flexDirection: "column",
			padding: theme.spacing(2)
		},
		progress: {
			position: "absolute",
			top: 0,
			left: 0,
			right: 0
		},
		icon: {
			width: 48
		},
		iconBack: {
			width: 48,
			"-webkit-transform": "rotate(180deg)",
			"-ms-transform": "rotate(180deg)",
			transform: "rotate(180deg)"
		},

		title: {
			marginTop: theme.spacing(3),
			fontSize: "1.5rem"
		},
		section: {
			display: "flex",
			flexDirection: "column",
			marginBottom: theme.spacing(1),
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			padding: theme.spacing(1),
			justifyContent: "center",
			justifyItems: "center",
			alignContent: "center",
			alignItems: "center"
		},
		section2: {
			padding: theme.spacing(2),
			marginTop: theme.spacing(2),
			alignItems: "center",
			justifyItems: "center"
		},
		content: {
			display: "flex",
			flexDirection: "column",
			margin: 0,
			padding: 0,
			boxShadow: "none",
			alignContent: "center",
			justifyContent: "center",
			alignItems: "center",
			justifyItems: "center"
		},
		formTitle: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
			textAlign: "center"
		},
		formTitleText: {
			marginTop: theme.spacing(1),
			fontSize: "0.9rem",
			textAlign: "center",
			textTransform: "uppercase"
		},
		btn: {
			borderRadius: 20,
			marginTop: theme.spacing(1),
			textAlign: "center",
			textTransform: "uppercase"
		},
		formAction: {
			textAlign: "center",
			alignContent: "center",
			marginTop: theme.spacing(2)
		},
		instructions: {
			fontSize: 18,
			textAlign: "center"
		},
		instructions2: {
			paddingTop: theme.spacing(1),
			fontSize: 18,
			textAlign: "center"
		},
		err: {
			backgroundColor: "rgba(255,0,0,0.1)",
			border: "1px solid #f00"
		},
		link: {
			fontSize: 15,
			color: theme.palette.text.primary
		},
		hLink: {
			fontSize: 15,
			color: theme.palette.primary.main,
			fontWeight: 600
		},
		chk: {
			display: "table-cell"
		},
		reset: {
			marginTop: theme.spacing(3),
			textAlign: "center"
		},
		businessName: {
			textAlign: "center",
			width: "100%",
			margin: 0,
			padding: 0,
			paddingTop: theme.spacing(1),
			fontSize: "1.5rem",
			fontWeight: 600,
			color: theme.palette.primary.main
		},
		subTitle: {
			textAlign: "center",
			margin: theme.spacing(2),
			fontSize: "1.2rem"
		},
		formControl: {
			width: "100%",
			display: "table",
			margin: 0,
			padding: 0
		},
		text: {
			color: theme.palette.text.primary
		},
		bottomBtn: {
			width: "100%"
		}
	};
});

export interface CardDetails {
	nameOnCard?: string;
	savePaymentInfo: boolean;
}

interface Props {}

const TableLookup: React.FC<Props> = () => {
	const classes = useStyles();

	const { t } = useTranslation();
	const { l } = useLocalize();

	const business = useStore(store => store.business);

	const setLoading = useStore(store => store.setLoading);
	const findOpenTab = useStore(store => store.findOpenTab);
	const customerInfo = useStore(store => store.customerInfo);

	const [currentOpenTabs, setCurrentOpenTabs] = useState<any>([]);
	const [hasNoTabs, setHasNoOpenTabs] = useState<boolean | undefined>(undefined);

	const navigate = useNavigate();

	const params = useParams();

	if (!business) {
		return <AppLoading />;
	}

	const tableSlug = params.tableSlug;
	const businessTable = business.tables?.find(t => t && t.slug === tableSlug);

	if (!businessTable) {
		throw new Error(`Table not found: '${tableSlug}'`); // shouldn't happen
	}

	useEffect(() => {
		const findAsync = async () => {
			if (tableSlug) {
				const openTabs = await findOpenTab(tableSlug);
				if (openTabs && openTabs.length === 0) {
					setHasNoOpenTabs(false);
				}
				setCurrentOpenTabs(openTabs);
			}
			setLoading(false);
		};
		setLoading(true);
		findAsync();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (hasNoTabs === false) {
		return <Redirect to={`table/${tableSlug}/customerinfo`} />;
	}

	return (
		<Layout
			businessName={business.name}
			bottom={
				<Button variant={"contained"} color="secondary" className={classes.bottomBtn} type="submit">
					{t("next")}
				</Button>
			}
		>
			<Box className={classes.root}>
				<Box className={classes.section}>
					<Typography className={clsx(classes.businessName)}>{l(business.localeNames)}</Typography>
					<Typography className={clsx(classes.businessName)}>
						{t("tableWithName", { name: businessTable.name })}
					</Typography>
					<Typography className={clsx(classes.title)}>
						{t("welcomeCustomer", { name: customerInfo.firstName })}
					</Typography>
					<Typography className={clsx(classes.title)}>{t("checkingForOpenTab")}</Typography>
				</Box>
			</Box>
		</Layout>
	);
};

export default TableLookup;
