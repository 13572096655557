import React, { useEffect } from "react";

import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";

import { useNavigate } from "react-router";
import useStore from "store";

import { tagSortByItemDisplayOrder } from "utils/tagSort";

import BusinessInfoPanel from "components/BusinessInfoPanel";
import Head from "components/Head";
import AppLoading from "components/Loading";
import ProgressBar from "components/ProgressBar";

const height = "calc(var(--vh, 1vh) * 100)";

const useStyles = makeStyles(() => {
	return {
		outer: {
			display: "flex",
			flexDirection: "column",
			flexGrow: 1,
			flex: 1,
			opacity: 1,
			margin: 0,
			padding: 0,
			overflow: "hidden",
			width: "100vw",
			height,
			objectFit: "cover"
		},
		progress: {
			height: 4,
			width: "100vw"
		}
	};
});

interface Props {}

const Landing: React.FC<Props> = () => {
	const classes = useStyles();
	const business = useStore(store => store.business);
	const loading = useStore(store => store.loading);
	const locale = useStore(store => store.locale);

	const setLoading = useStore(store => store.setLoading);
	const applyTagFilterByTagSlug = useStore(store => store.applyTagFilterByTagSlug);
	const navigate = useNavigate();

	if (!business) {
		return <AppLoading />;
	}
	const backgroundStyles = makeStyles(business?.styles);
	const backgroundStyle = backgroundStyles();

	const items = applyTagFilterByTagSlug("highlights").sort(tagSortByItemDisplayOrder);

	useEffect(() => {
		let timeout: number | null = null;
		setLoading(true);
		// if (loading) {
		timeout = window.setTimeout(() => {
			setLoading(false);
			navigate(`/${locale}/main/noms/highlights/${items[0]?.slug}`);
		}, 3500);
		// }
		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const close = () => {
		if (loading) {
			setLoading(false);
			navigate(`/${locale}/main/noms/highlights/${items[0]?.slug}`);
		} else {
			navigate(`/${locale}/main`);
		}
	};

	const background = business.menus[0]!.defaultMedia?.mediaUrl
		? {
				backgroundSize: "cover",
				backgroundImageOpacity: 0.2,
				backgroundImage: `url('${business.menus[0]!.defaultMedia?.mediaUrl}')`
		  }
		: {};

	return (
		<Box className={clsx(classes.outer, backgroundStyle["infoBackground"])} style={background} onClick={close}>
			{loading && <ProgressBar className={classes.progress} />}
			<Head title={business.name} subTitle={"Loading"} />

			<BusinessInfoPanel business={business} />
		</Box>
	);
};

export default Landing;
