/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { AuthenticateOtpMutation as AuthenticateOtpMutationType, AuthenticateOtpMutationVariables } from "gql/types/mutations/AuthenticateOtpMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation AuthenticateOtpMutation($authenticateOtpInput: AuthenticateOtpInput!) {
	authenticateOtp(authenticateOtpInput: $authenticateOtpInput) {
		accessToken
		refreshToken
	}
}
`;

const AuthenticateOtpMutation: IGqlOperation<AuthenticateOtpMutationType, AuthenticateOtpMutationVariables> = { text } as any;

export default AuthenticateOtpMutation;
