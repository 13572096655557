/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { OrderTabRemoveMemberMutation as OrderTabRemoveMemberMutationType, OrderTabRemoveMemberMutationVariables } from "gql/types/mutations/OrderTabRemoveMemberMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation OrderTabRemoveMemberMutation($orderTabRemoveMemberInput: OrderTabRemoveMemberInput!) {
	orderTabRemoveMember(orderTabRemoveMemberInput: $orderTabRemoveMemberInput) {
		_id
		tableMembers {
			_id
			name
		}
	}
}
`;

const OrderTabRemoveMemberMutation: IGqlOperation<OrderTabRemoveMemberMutationType, OrderTabRemoveMemberMutationVariables> = { text } as any;

export default OrderTabRemoveMemberMutation;
