/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { LogEventMutation as LogEventMutationType, LogEventMutationVariables } from "gql/types/mutations/LogEventMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation LogEventMutation($logEventInput: LogEventInput!) {
	logEvent(logEventInput: $logEventInput)
}
`;

const LogEventMutation: IGqlOperation<LogEventMutationType, LogEventMutationVariables> = { text } as any;

export default LogEventMutation;
