/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { UpdateOrderItemMutation as UpdateOrderItemMutationType, UpdateOrderItemMutationVariables } from "gql/types/mutations/UpdateOrderItemMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation UpdateOrderItemMutation($updateOrderItemInput: UpdateOrderItemInput!) {
	updateOrderItem(updateOrderItemInput: $updateOrderItemInput) {
		_id
	}
}
`;

const UpdateOrderItemMutation: IGqlOperation<UpdateOrderItemMutationType, UpdateOrderItemMutationVariables> = { text } as any;

export default UpdateOrderItemMutation;
