import React, { useRef, useState } from "react";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import { InputAdornment } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

import { useTranslation } from "react-i18next";

import Address from "api/types/Address";

import loadScript from "./loadScript";

type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type AutocompleteService = google.maps.places.AutocompleteService;
type AutocompleteSessionToken = google.maps.places.AutocompleteSessionToken;
type AutocompletionRequest = google.maps.places.AutocompletionRequest;
type PlacesServiceStatus = google.maps.places.PlacesServiceStatus;

const useStyles = makeStyles(theme => ({
	inputIcon: {
		color: theme.palette.text.secondary
	},
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2)
	},
	helper: {
		fontSize: "1rem"
	}
}));

const autocompleteService: {
	current: null | AutocompleteService;
} = { current: null };

type PlaceType = AutocompletePrediction;

// interface PlaceType {
// 	description: string;
// 	structured_formatting: {
// 		main_text: string;
// 		secondary_text: string;
// 		main_text_matched_substrings: [
// 			{
// 				offset: number;
// 				length: number;
// 			}
// 		];
// 	};
// }

const isLocationValid = (search: AutocompletePrediction, cities: Address[]) => {
	let isValid = false;
	cities.forEach(c => {
		if (!isValid) {
			const isMatch =
				search.terms.find(t => t.value === c.country) &&
				search.terms.find(t => t.value === c.state) &&
				search.terms.find(t => t.value === c.city);
			if (isMatch) {
				isValid = true;
			}
		}
	});
	return isValid;
};

interface Props {
	cities?: Address[];
	className?: string;
	defaultValue?: null | PlaceType;
	helperText?: string;
	onChange?(event: React.ChangeEvent<{}>, value: null | PlaceType);
	origin?: null | {
		latitude: number;
		longitude: number;
	};
	placeholder?: string;
	radius?: number | null;
	filterRadius?: number | null;
}

const PlacesAutocomplete = ({
	cities,
	className,
	defaultValue,
	helperText,
	onChange,
	origin,
	placeholder,
	radius,
	filterRadius
}: Props) => {
	const { t } = useTranslation();

	const sessionToken = useRef<AutocompleteSessionToken>();
	const classes = useStyles();
	const [value, setValue] = useState<PlaceType | null>(defaultValue || null);
	const [inputValue, setInputValue] = useState("");
	const [hasError, setHasError] = useState(false);
	const [options, setOptions] = useState<PlaceType[]>([]);
	const loaded = useRef(false);

	if (typeof window !== "undefined" && !loaded.current) {
		if (!document.querySelector("#google-maps")) {
			console.log("process.env.REACT_APP_GOOGLE_PLACES_API_KEY", process.env.REACT_APP_GOOGLE_PLACES_API_KEY);
			loadScript(
				`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}&libraries=places,geometry`,
				document.querySelector("head"),
				"google-maps"
			);
		}
		loaded.current = true;
	}

	const fetch = React.useMemo<
		(
			request: AutocompletionRequest,
			callback: (result: AutocompletePrediction[], status: PlacesServiceStatus) => void
		) => void
	>(
		() =>
			throttle(
				(
					request: AutocompletionRequest,
					callback: (result: AutocompletePrediction[], status: PlacesServiceStatus) => void
				) => {
					autocompleteService.current!.getPlacePredictions(request, callback);
				},
				200
			),
		[]
	);

	React.useEffect(() => {
		let active = true;

		if (!autocompleteService.current && (window as any).google && (window as any).google.maps) {
			autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
		}
		if (!autocompleteService.current) {
			console.warn("autocompleteService.current undefined");
			return undefined;
		}

		if (!sessionToken.current) {
			sessionToken.current = new google.maps.places.AutocompleteSessionToken();
		}

		if (inputValue === "") {
			setOptions(value ? [value] : []);
			return undefined;
		}

		let bounds: undefined | google.maps.LatLngBounds;

		if (origin && filterRadius) {
			const center = new google.maps.LatLng(origin.latitude, origin.longitude);
			const distanceFromCenterToCorner = filterRadius * Math.sqrt(2);
			const swCorner = google.maps.geometry.spherical.computeOffset(center, distanceFromCenterToCorner, 225);
			const neCorner = google.maps.geometry.spherical.computeOffset(center, distanceFromCenterToCorner, 45);
			bounds = new google.maps.LatLngBounds(swCorner, neCorner);
		}

		fetch(
			{
				input: inputValue,
				origin: origin
					? {
							lat: origin.latitude,
							lng: origin.longitude
					  }
					: undefined,
				bounds,
				types: ["address"],
				componentRestrictions: {
					country: "US"
				},
				sessionToken: sessionToken.current
			},
			results => {
				if (active) {
					let newOptions = [] as PlaceType[];
					if (value) {
						newOptions = [value];
					}
					if (results) {
						if (filterRadius) {
							// eslint-disable-next-line no-param-reassign
							results = results.filter(x => x.distance_meters! <= filterRadius);
						}
						newOptions = [...newOptions, ...results];
					}
					setOptions(newOptions);
				}
			}
		);
		return () => {
			active = false;
		};
	}, [origin, filterRadius, value, inputValue, fetch]);

	const onPlacesChanged = (event: any, newValue: PlaceType | null) => {
		let isValid = false;
		if (cities && newValue && newValue.terms && newValue.terms.length >= 3) {
			// const termsLength = newValue.terms.length;

			isValid = isLocationValid(newValue, cities);
			// 	newValue.terms.find(cities.find(
			// 		c =>
			// 			c.city!.toUpperCase() === newValue.terms[termsLength - 3].value.toLocaleUpperCase() &&
			// 			c.state!.toUpperCase() === newValue.terms[termsLength - 2].value.toUpperCase() &&
			// 			c.country!.toUpperCase() === newValue.terms[termsLength - 1].value.toLocaleUpperCase()
			// 	) != null;
		} else if (radius && newValue && radius > newValue.distance_meters!) {
			isValid = true;
			// console.log("radius", radius);
		}

		setHasError(!isValid);

		setOptions(newValue ? [newValue, ...options] : options);
		setValue(newValue);
		if (onChange) {
			onChange(event, isValid ? newValue : null);
		}
		sessionToken.current = undefined;
	};

	const inputHelperText = hasError ? t("outsideOfDeliveryZone") : helperText;
	return (
		<Autocomplete
			fullWidth
			getOptionLabel={option => (typeof option === "string" ? option : option.description)}
			filterOptions={x => x}
			options={options}
			autoComplete
			includeInputInList
			clearText=""
			clearIcon={null}
			// disableClearable
			// disableCloseOnSelect
			filterSelectedOptions
			value={value}
			onChange={onPlacesChanged}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			renderInput={params => (
				<TextField
					{...params}
					className={className}
					placeholder={placeholder}
					helperText={inputHelperText}
					FormHelperTextProps={{ classes: { root: classes.helper } }}
					fullWidth
					error={hasError}
					InputProps={{
						...params.InputProps,
						type: "search",
						startAdornment: (
							<InputAdornment position="start">
								<LocationOnIcon className={classes.inputIcon} />
							</InputAdornment>
						),
						style: { paddingLeft: 3, paddingBottom: 3, marginLeft: 0, paddingRight: 20, borderWidth: 0 }
					}}
					inputProps={{
						...params.inputProps,
						style: { padding: 2, borderWidth: 0, paddingLeft: 10 }
					}}
				/>
			)}
			renderOption={(option: any) => {
				const matches = option.structured_formatting.main_text_matched_substrings;
				const parts = parse(
					option.structured_formatting.main_text,
					matches.map((match: any) => [match.offset, match.offset + match.length])
				);

				return (
					<Grid container alignItems="center">
						<Grid item>
							<LocationOnIcon className={classes.icon} />
						</Grid>
						<Grid item xs>
							{parts.map((part, index) => (
								<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
									{part.text}
								</span>
							))}
							<Typography variant="body2" color="textSecondary">
								{option.structured_formatting.secondary_text}
							</Typography>
						</Grid>
					</Grid>
				);
			}}
		/>
	);
};

export default PlacesAutocomplete;
