import React from "react";

import { Badge, Box } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import useStore from "store";

import ShoppingCartIcon from "icons/ShoppingCartIcon";

const useStyles = makeStyles(() => {
	return {
		root: {
			display: "flex",
			position: "relative"
		},
		fabIcon: {
			elevation: 10
		},
		badge: {
			top: 2,
			right: -4
		}
	};
});

interface Props {
	className?: string;
}

const CartIcon: React.FC<Props> = ({ className }) => {
	const cls = useStyles();
	const orderTotals = useStore(state => state.orderTotals);

	return (
		<Box className={cls.root}>
			<ShoppingCartIcon className={clsx(cls.fabIcon, className)} height={24} width={24} />
			<Badge badgeContent={orderTotals.itemsCount} color={"secondary"} className={cls.badge} />
		</Box>
	);
};

export default CartIcon;
