/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { ToggleMenuItemFavoriteMutation as ToggleMenuItemFavoriteMutationType, ToggleMenuItemFavoriteMutationVariables } from "gql/types/mutations/ToggleMenuItemFavoriteMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation ToggleMenuItemFavoriteMutation($toggleMenuItemFavoriteInput: ToggleMenuItemFavoriteInput!) {
	toggleMenuItemFavorite(toggleMenuItemFavoriteInput: $toggleMenuItemFavoriteInput) {
		menuItemId
	}
}
`;

const ToggleMenuItemFavoriteMutation: IGqlOperation<ToggleMenuItemFavoriteMutationType, ToggleMenuItemFavoriteMutationVariables> = { text } as any;

export default ToggleMenuItemFavoriteMutation;
