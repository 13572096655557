import React, { useRef, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, Paper, Stack, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import analytics from "analytics";
import { AnimatePresence, motion } from "framer";
import { useTranslation } from "react-i18next";
import useStore from "store";

import useDebouncedEffect from "hooks/useDebouncedEffect";

const useStyles = makeStyles(theme => {
	return {
		hdr: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText,
			padding: 0,
			paddingRight: theme.spacing(1),
			borderRadius: 0,
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			alignContent: "center",
			alignItems: "center"
		},
		section: {
			display: "inline-block",
			fontSize: 16,
			fontWeight: 600,
			paddingLeft: theme.spacing(2),
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1)
		},
		searchIcon: {
			fill: theme.palette.primary.contrastText
		},
		textInputContainer: {
			width: "calc(100% - 10px)"
		},
		iconBtn: {},
		textInput: {
			color: theme.palette.primary.contrastText
		}
	};
});

const spring = {
	duration: 0.25,
	ease: "easeOut"
};

interface Props {}

const MenuHeader: React.FC<Props> = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	const business = useStore(store => store.business);
	const applySearchFilter = useStore(store => store.applySearchFilter);
	const searchText = useStore(store => store.searchText);
	const setSearchText = useStore(store => store.setSearchText);

	const [isSearchMode, setIsSearchMode] = useState(Boolean(searchText));

	const searchRef = useRef<any>(null);

	const searchClicked = () => {
		if (isSearchMode) {
			applySearchFilter();
		}
		setIsSearchMode(!isSearchMode);
	};

	useDebouncedEffect(
		() => {
			analytics.track("Searching", { business: business?.name, searchText: searchText });
		},
		[analytics, searchText],
		2000
	);

	const onChange = e => {
		setSearchText(e.target.value);
		applySearchFilter(e.target.value);
	};

	const onSearchClearClicked = () => {
		searchRef.current?.focus();
		setSearchText("");
		applySearchFilter("");
		analytics.track("Clear search", { business: business?.name });
	};

	return (
		<Paper className={classes.hdr}>
			<AnimatePresence exitBeforeEnter>
				{!isSearchMode && (
					<motion.span
						layout
						className={classes.textInputContainer}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ width: 0, opacity: 0 }}
					>
						<Typography className={classes.section}>{t("menu")}</Typography>
					</motion.span>
				)}
			</AnimatePresence>
			<IconButton className={classes.iconBtn} aria-label={t("search")} onClick={searchClicked} size="large">
				<SearchIcon className={classes.searchIcon} />
			</IconButton>
			<AnimatePresence exitBeforeEnter>
				{isSearchMode && (
					<motion.span
						layout
						transition={spring}
						className={classes.textInputContainer}
						initial={{ width: 0, opacity: 0 }}
						animate={{ width: "100%", opacity: 1 }}
						exit={{ width: 0, opacity: 0 }}
					>
						<Stack direction={"row"}>
							<TextField
								autoFocus
								className={classes.textInput}
								fullWidth
								required
								aria-required="true"
								inputRef={searchRef}
								placeholder={t("search")}
								id={"search"}
								name={"search"}
								value={searchText}
								inputProps={{ maxLength: 80, className: classes.textInput }}
								onChange={onChange}
							/>
							<IconButton onClick={onSearchClearClicked}>
								<ClearIcon sx={{ color: "primary.contrastText" }} />
							</IconButton>
						</Stack>
					</motion.span>
				)}
			</AnimatePresence>
		</Paper>
	);
};

export default React.memo(MenuHeader);
