import i18n from "i18next";
import getPhoneNumberDigits from "utils/getPhoneNumberDigits";

const validate = (values: Record<string, string>): Record<string, string> => {
	const errors: Record<string, string> = {};
	if (!values.mobileNumber) {
		errors.mobileNumber = i18n.t("required");
	}
	const phoneDigits = getPhoneNumberDigits(values.mobileNumber);

	if (phoneDigits?.length != 10) {
		errors.mobileNumber = i18n.t("invalid");
	}
	return errors;
};

export default validate;
