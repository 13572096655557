/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { ClearOrderItemsMutation as ClearOrderItemsMutationType, ClearOrderItemsMutationVariables } from "gql/types/mutations/ClearOrderItemsMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation ClearOrderItemsMutation($clearOrderItemsInput: ClearOrderItemsInput!) {
	clearOrderItems(clearOrderItemsInput: $clearOrderItemsInput) {
		_id
	}
}
`;

const ClearOrderItemsMutation: IGqlOperation<ClearOrderItemsMutationType, ClearOrderItemsMutationVariables> = { text } as any;

export default ClearOrderItemsMutation;
