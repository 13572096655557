import MenuItem from "api/types/MenuItem";
import SelectedModifier from "components/MenuItemModal/SelectedModifier";

/**
 * Calculate the unit price of the MenuItem + selected options
 */
const calculateMenuItemUnitPriceCents = ({
	menuItem,
	selectedModifiers
}: {
	menuItem?: MenuItem;
	selectedModifiers?: (SelectedModifier | null)[];
}): number => {
	let unitPrice = menuItem?.priceCents || 0;

	if (menuItem?.menuItemModifierGroups?.length) {
		for (const group of menuItem.menuItemModifierGroups) {
			if (group && group.menuItemModifiers) {
				for (const modifier of group.menuItemModifiers) {
					if (modifier && modifier.priceCents) {
						if (
							selectedModifiers?.find(
								x => x && x.menuItemModifierGroupId === group._id && x.menuItemModifierId === modifier._id
							)
						) {
							if (modifier.priceCents) {
								unitPrice += modifier.priceCents;
							}
						}
					}
				}
			}
		}
	}

	return unitPrice;
};

export default calculateMenuItemUnitPriceCents;
