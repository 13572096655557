import React from "react";

import { TextField, TextFieldProps } from "@mui/material";

const PhoneNumber: React.FC<TextFieldProps> = ({ ...props }) => {
	// const onTextChanged = val => {
	// 	const { value } = val.target;
	// 	let normalized = value.replace("+", "").trim();
	// 	if (normalized.startsWith("1")) {
	// 		normalized = normalized.substring(1);
	// 	}

	// 	setValue(normalized);
	// };

	// const mobileNumberPasted = e => {
	// 	const pastedData = e.clipboardData.getData("text/plain");
	// 	let normalized = pastedData.replace("+", "").trim();
	// 	if (normalized.startsWith("1")) {
	// 		normalized = normalized.substring(1);
	// 	}
	// 	setValue(normalized);
	// };

	const onKeyDown = e => {
		const { keyCode } = e;
		const aollowed = [
			8, 35, 36, 37, 38, 39, 40, 41, 45, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 86, 96, 97, 98, 99, 100, 101, 102,
			103, 104, 105
		];
		if (aollowed.includes(keyCode)) {
			return true;
		}
		e.preventDefault();
	};

	return (
		<TextField
			{...props}
			inputProps={{
				...props.inputProps,
				type: "tel",
				autoComplete: "tel",
				onKeyDown: onKeyDown
			}}
			// value={value}
			// onChange={onTextChanged}
			// onPaste={mobileNumberPasted}
		/>
	);
};

export default PhoneNumber;
