import React, { useCallback, useEffect, useState } from "react";

import { Box, Button, Checkbox, FormControlLabel, Link, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import analytics from "analytics/analytics";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import MuiPhoneNumber from "material-ui-phone-number";

import { isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { useNavigate, useParams } from "react-router-dom";

import useStore from "store";

import getDisplayPhoneNumber from "utils/getDisplayPhoneNumber";
import getPhoneNumberDigits from "utils/getPhoneNumberDigits";

import Layout from "components/Layout";
import PhoneNumber from "components/PhoneNumber";
import ProgressBar from "components/ProgressBar";
import Redirect from "components/Redirect";
import config from "config";
import useForm from "hooks/useForm";

import useLocalize from "hooks/useLocalize";

import BusinessHeader from "./BusinessHeader";
import validate from "./validate";

const useStyles = makeStyles(theme => {
	return {
		root: {
			display: "flex",
			flexDirection: "column",
			padding: theme.spacing(2)
		},
		progress: {
			position: "absolute",
			top: 0,
			left: 0,
			right: 0
		},
		icon: {
			width: 48
		},
		iconBack: {
			width: 48,
			"-webkit-transform": "rotate(180deg)",
			"-ms-transform": "rotate(180deg)",
			transform: "rotate(180deg)"
		},

		title: {
			marginTop: theme.spacing(3),
			fontSize: "1.5rem"
		},
		section: {
			display: "flex",
			flexDirection: "column",
			marginBottom: theme.spacing(1),
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			padding: theme.spacing(1),
			justifyContent: "center",
			justifyItems: "center",
			alignContent: "center",
			alignItems: "center"
		},
		section2: {
			padding: theme.spacing(2),
			marginTop: theme.spacing(2),
			alignItems: "center",
			justifyItems: "center"
		},
		content: {
			display: "flex",
			flexDirection: "column",
			margin: 0,
			padding: 0,
			boxShadow: "none",
			alignContent: "center",
			justifyContent: "center",
			alignItems: "center",
			justifyItems: "center"
		},
		formTitle: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
			textAlign: "center"
		},
		formTitleText: {
			marginTop: theme.spacing(1),
			fontSize: "0.9rem",
			textAlign: "center",
			textTransform: "uppercase"
		},
		btn: {
			borderRadius: 20,
			marginTop: theme.spacing(1),
			textAlign: "center",
			textTransform: "uppercase"
		},
		formAction: {
			textAlign: "center",
			alignContent: "center",
			marginTop: theme.spacing(2)
		},
		instructions: {
			fontSize: 18,
			textAlign: "center"
		},
		instructions2: {
			paddingTop: theme.spacing(1),
			fontSize: 18,
			textAlign: "center"
		},
		otpInputContainer: {
			display: "flex",
			paddingTop: theme.spacing(2)
		},
		otpInstructions: {
			display: "block",
			alignContent: "center",
			alignItems: "center",
			justifySelf: "center",
			fontWeight: 400,
			fontSize: "1.1rem"
		},
		otpPhone: {
			justifySelf: "center",
			fontWeight: 600,
			fontSize: "1.2rem"
		},
		otpInputStyle: {
			margin: 5,
			paddingLeft: 5,
			paddingRight: 5,
			fontSize: "2.2rem",
			width: "auto",
			height: "auto",
			borderRadius: 4,
			border: "1px solid #888"
		},
		otpTextInputStyle: {
			marginTop: theme.spacing(1),
			width: 250,
			fontWeight: 600,
			fontSize: "1.1rem",
			justifySelf: "center",
			textAlign: "center"
		},
		err: {
			backgroundColor: "rgba(255,0,0,0.1)",
			border: "1px solid #f00"
		},
		link: {
			fontSize: 15,
			color: theme.palette.text.primary
		},
		hLink: {
			fontSize: 15,
			color: theme.palette.primary.main,
			fontWeight: 600
		},
		chk: {
			display: "table-cell"
		},
		phone: {
			alignSelf: "center",
			maxWidth: 400,
			fontSize: "1.2rem",
			textAlign: "center"
		},
		reset: {
			marginTop: theme.spacing(3),
			textAlign: "center"
		},
		businessName: {
			textAlign: "center",
			width: "100%",
			margin: 0,
			padding: 0,
			paddingTop: theme.spacing(1),
			fontSize: "1.5rem",
			fontWeight: 600,
			color: theme.palette.primary.main
		},
		subTitle: {
			textAlign: "center",
			margin: theme.spacing(2),
			fontSize: "1.2rem"
		},
		formControl: {
			width: "100%",
			display: "table",
			margin: 0,
			padding: 0
		},
		text: {
			color: theme.palette.text.primary
		}
	};
});

const OTP_CHARS_COUNT = 6;

const spring = {
	duration: 0.25,
	opacity: { duration: 0.2, delay: 0.1 }
};

interface Props {}

const TableSetup: React.FC<Props> = () => {
	const classes = useStyles();

	const { t } = useTranslation();
	const { l } = useLocalize();

	const business = useStore(store => store.business);
	const register = useStore(store => store.register);
	const authenticateOtp = useStore(store => store.authenticateOtp);

	const isLoggedIn = useStore(store => store.isLoggedIn);

	const setLoading = useStore(store => store.setLoading);
	const customerInfo = useStore(store => store.customerInfo);
	const getPaymentMethods = useStore(store => store.getPaymentMethods);
	const paymentMethods = useStore(store => store.paymentMethods);

	const [isStarted, setIsStarted] = useState(false);
	const [step, setStep] = useState(1);
	const [isMobileNumberPasted, setMobileNumberPasted] = React.useState(false);

	const [openTabs, setOpenTabs] = useState<any>([]);
	const [isAgreementAccepted, setIsAgreementAccepted] = useState(false);

	const navigate = useNavigate();

	const params = useParams();

	if (!business) {
		return null;
	}

	const { onChange, onSubmit, values, errors, isSubmitting, setErrors, setIsSubmitting, setValue } = useForm(
		() => {},
		{
			mobileNumber: "",
			token: ""
		},
		validate
	);

	const tableSlug = params.tableSlug;
	const table = business.tables?.find(t => t && t.slug === tableSlug);

	if (!table) {
		throw new Error("Table not found"); // shouldn't happen
	}

	useEffect(() => {
		const getPaymentMethodsAsync = async () => {
			await getPaymentMethods();
		};
		if (isLoggedIn) {
			setLoading(true);
			getPaymentMethodsAsync();
		}
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const executeStartOtpLogin = useCallback(async () => {
		const formattedNumber = getDisplayPhoneNumber(values?.mobileNumber);
		if (isSubmitting && values!.mobileNumber && formattedNumber?.length === 14) {
			setIsStarted(true);
			const result = await register(values!.mobileNumber);
		}
		setIsSubmitting(false);
	}, [register, isSubmitting, setIsSubmitting, values]);

	const executeValidateOtpLogin = useCallback(async () => {
		if (values!.mobileNumber && values!.token && values!.token.length === OTP_CHARS_COUNT) {
			const validateResult = await authenticateOtp(values!.mobileNumber, values!.token);
			if (validateResult) {
				console.log("validateResult", validateResult);
				analytics.track("Customer Tab Setup OTP Login Success");
				// await getOrderedItems();

				// valid login

				if (
					!customerInfo ||
					customerInfo == undefined ||
					!customerInfo?.firstName ||
					customerInfo?.firstName.length === 0
				) {
					navigate("../cust");
				} else {
					navigate("..");
				}
			} else {
				setErrors({ token: "Invalid" });
				analytics.track("Dine-In Customer OTP Login Failed");
			}
		}
	}, [authenticateOtp, customerInfo, navigate, setErrors, values]);

	useEffect(() => {
		if (isSubmitting) {
			const formattedNumber = getDisplayPhoneNumber(values?.mobileNumber);
			if (!isStarted && (!errors || Object.keys(errors).length === 0) && formattedNumber?.length === 14) {
				executeStartOtpLogin();
				analytics.track("Dine-In Table Customer Login Started");
				setStep(2);
			} else {
				executeValidateOtpLogin();
			}
		}
	}, [errors, executeStartOtpLogin, executeValidateOtpLogin, isStarted, isSubmitting, values?.mobileNumber]);

	if (isLoggedIn) {
		console.log("paymentMethods", paymentMethods);
		if (paymentMethods.length > 0) {
			return <Redirect to={`table/${tableSlug}`} />;
		} else {
			return <Redirect to={`table/${tableSlug}/payment`} />;
		}
	}

	const onPhoneChanged = e => {
		const value = e.target ? e.target.value : e;
		if (value.startsWith("(1")) {
			onChange({ target: { name: "mobileNumber", value: value.replace("(1", "(") } });
		} else {
			if (value.length < 15) {
				onChange({ target: { name: "mobileNumber", value: value.substring(0, 14) } });
			}
		}
		if (isMobileNumberPasted) {
			if (values!.mobileNumber?.length === 14) {
				submitForm(null);
			}
		}
		setMobileNumberPasted(false);
	};

	const mobileNumberPasted = e => {
		const pastedData = e.clipboardData.getData("text/plain");
		let normalized = pastedData.replace("+", "");
		if (normalized.startsWith("1")) {
			normalized = normalized.substring(1);
		}
		if (pastedData.length === 12) {
			onChange({ target: { name: "mobileNumber", value: getDisplayPhoneNumber(pastedData.replace("+1", "")) } });
		}
		setMobileNumberPasted(true);
	};

	const onPhonePasted = e => {
		const pastedData = e.clipboardData.getData("text/plain");
		const numericData = getPhoneNumberDigits(pastedData);
		const formattedNumber = getDisplayPhoneNumber(numericData);
		onChange({ target: { name: "mobileNumber", value: formattedNumber } });
		e.preventDefault();
	};

	const onOtpChange = otp => {
		setValue({ token: otp });
		if (otp.length === OTP_CHARS_COUNT) {
			values!.token = otp;
			submitForm(null);
		}
	};

	const onOtpTextChange = event => {
		const otp = event.target.value;
		setValue({ token: otp });
		if (otp.length === OTP_CHARS_COUNT) {
			values!.token = otp;
			submitForm(null);
		}
	};

	const isSubmitDisabled =
		isSubmitting || !values!.mobileNumber || (isStarted && (!values!.token || values!.token.length < OTP_CHARS_COUNT));

	function cancelForm() {
		setIsSubmitting(false);
		setIsStarted(false);
		setStep(1);
		setValue({ mobileNumber: "", token: "" });
	}

	function submitForm(e) {
		onSubmit(e);
	}

	const agreementOnChange = e => {
		setIsAgreementAccepted(e.target.checked);
	};

	const codePasted = pastedValue => {
		setValue({ ...values, token: pastedValue });
		executeValidateOtpLogin();
	};

	return (
		<Layout businessName={business.name} pageTitle={t("dineInSetup")}>
			<Box className={classes.root}>
				{isSubmitting && <ProgressBar className={classes.progress} />}
				<form onSubmit={submitForm} action="#">
					<BusinessHeader business={business} />

					<Box className={classes.section}>
						<Typography className={clsx(classes.title)}>{t("welcomeTo")}</Typography>
						<Typography className={clsx(classes.businessName)}>{l(business.localeNames)}</Typography>
						<Typography className={clsx(classes.businessName)}>{t("tableWithName", { name: table.name })}</Typography>
						{openTabs.length > 0 ? (
							<Typography className={clsx(classes.subTitle)}>{t("areYouDiningWith")}</Typography>
						) : (
							<Typography className={clsx(classes.subTitle)}>{t("getTabStarted")}</Typography>
						)}
					</Box>

					<AnimatePresence>
						{step === 1 && (
							<motion.div
								layout
								key={"step1"}
								transition={spring}
								initial={{ height: 0, opacity: 0 }}
								animate={{ height: "auto", opacity: 1 }}
								exit={{ height: 0, opacity: 0 }}
							>
								<Box className={classes.content}>
									<Box className={classes.section}>
										<Typography className={classes.instructions2}>{t("getTabStartedStep1")}</Typography>
										<Typography className={classes.instructions2}>{t("getTabStartedStep2")}</Typography>
									</Box>
									<Box className={classes.section}>
										{isIOS ? (
											<PhoneNumber
												id="mobileNumber"
												name="mobileNumber"
												autoFocus
												className={classes.phone}
												placeholder={t("mobileNumber")}
												inputProps={{
													className: classes.phone,
													autoComplete: "tel"
												}}
												fullWidth
												required
												aria-required="true"
												value={values!.mobileNumber}
												onChange={onPhoneChanged}
												onPaste={onPhonePasted}
												error={Boolean(errors?.mobileNumber)}
												helperText={errors?.mobileNumber}
												variant="standard"
											/>
										) : (
											<MuiPhoneNumber
												id="mobileNumber"
												name="mobileNumber"
												autoFocus
												className={classes.phone}
												inputProps={{
													className: classes.phone,
													placeholder: t("mobileNumber"),
													autoComplete: "tel"
												}}
												defaultCountry={"us"}
												onlyCountries={["us"]}
												disableCountryCode
												disableDropdown
												fullWidth
												required
												aria-required="true"
												value={values!.mobileNumber}
												onChange={onPhoneChanged}
												error={Boolean(errors?.mobileNumber)}
												helperText={errors?.mobileNumber}
												onPaste={mobileNumberPasted}
												variant="standard"
											/>
										)}
									</Box>
									<Box className={classes.section}>
										<FormControlLabel
											className={classes.formControl}
											control={
												<div className={classes.chk}>
													<Checkbox onChange={agreementOnChange} checked={isAgreementAccepted} />
												</div>
											}
											labelPlacement="end"
											label={
												<Typography className={classes.link}>
													{t("userAgreement")}{" "}
													<Link href={config.tosUrl} target="_blank" className={classes.hLink}>
														{t("termsOfService")}
													</Link>{" "}
													{t("and")}{" "}
													<Link href={config.privacyPolicyUrl} target="_blank" className={classes.hLink}>
														{t("privacyPolicy")}
													</Link>
												</Typography>
											}
										/>
									</Box>

									<Button className={classes.btn} type="submit" variant={"outlined"} disabled={!isAgreementAccepted}>
										{t("go")}
									</Button>
								</Box>
							</motion.div>
						)}
						{step === 2 && (
							<motion.div
								layout
								key={"step2"}
								transition={spring}
								initial={{ height: 0, opacity: 0 }}
								animate={{ height: "auto", opacity: 1 }}
								exit={{ height: 0, opacity: 0 }}
							>
								<>
									<Box className={classes.content}>
										<Box className={classes.formTitle}>
											<Typography className={classes.formTitleText}>{t("otp")}</Typography>
										</Box>
										<Box className={classes.instructions}>
											<Typography className={classes.otpInstructions}>{t("otpInstructions")}</Typography>
											<Typography className={classes.otpPhone}>
												{getDisplayPhoneNumber(values!.mobileNumber)}
											</Typography>
										</Box>
										{isIOS ? (
											<TextField
												autoFocus
												className={classes.otpTextInputStyle}
												fullWidth
												required
												aria-required="true"
												placeholder={t("otp")}
												value={values!.token || ""}
												id={"token"}
												name={"token"}
												inputProps={{
													autoComplete: "one-time-code",
													className: classes.otpTextInputStyle,
													inputMode: "numeric",
													maxLength: OTP_CHARS_COUNT
												}}
												onChange={onOtpTextChange}
												error={Boolean(errors?.token)}
												helperText={errors?.token}
											/>
										) : (
											<OtpInput
												value={values!.token || ""}
												onChange={onOtpChange}
												numInputs={OTP_CHARS_COUNT}
												containerStyle={classes.otpInputContainer}
												inputStyle={classes.otpInputStyle}
												errorStyle={classes.err}
												shouldAutoFocus={true}
												isInputNum={true}
												hasErrored={Boolean(errors?.token)}
											/>
										)}
									</Box>

									<Box className={classes.formAction}>
										<Button
											type="submit"
											size={"medium"}
											variant={"outlined"}
											color={"secondary"}
											className={classes.btn}
										>
											{t("submit")}
										</Button>
									</Box>
									<Box className={classes.reset}>
										<a onClick={cancelForm}>{t("startOver")}</a>
									</Box>
								</>
							</motion.div>
						)}
					</AnimatePresence>
				</form>
			</Box>
		</Layout>
	);
};

export default TableSetup;
