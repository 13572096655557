import React, { useEffect, useRef } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Grow, IconButton, StyledEngineProvider, Theme, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

import analytics from "analytics";
import { SnackbarProvider } from "notistack";

import useStore from "store";
import theme from "style/theme";

import AppLoading from "components/AppLoading";

import BusinessRoutes from "./BusinessRoutes";

declare module "@mui/styles/defaultTheme" {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

// const hostParts = window.location.host.split(".");
// const businessSlug = hostParts.length > 1 ? hostParts[0].toLowerCase() : undefined;
// const domainHostName = window.location.host.toLowerCase();
// const businessSlug = hostParts.length > 1 ? hostParts[0].toLowerCase() : undefined;
const { host } = window.location;
// const hostParts = host.split(".");
const domainHostName = host.toLowerCase();
const businessSlug = "k-town-bbq-newark-ca"; // Temp fix
// const businessSlug = hostParts.length > 1 ? hostParts[0].toLowerCase() : undefined;

interface Props {}

const Business: React.FC<Props> = () => {
	const notistackRef = useRef<SnackbarProvider | null>(null);
	const getBusiness = useStore(store => store.getBusiness);
	const setLoading = useStore(store => store.setLoading);
	const business = useStore(store => store.business);
	const ensureToken = useStore(store => store.ensureToken);

	useEffect(() => {
		if (business) {
			analytics.setBusiness(business);
			analytics.track("Loading Menu", {});
			ensureToken(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (domainHostName.includes("nomsquared.com") || domainHostName.includes("localhost") || host.includes("ngrok")) {
			getBusiness(
				!businessSlug ||
					businessSlug.startsWith("dev") ||
					businessSlug.indexOf("demo") > -1 ||
					domainHostName.includes("ngrok")
					? process.env.REACT_APP_DEMO_SLUG!
					: businessSlug
			);
		} else {
			getBusiness(undefined, domainHostName);
		}
		setLoading(false);
	}, [getBusiness, setLoading]);

	if (!business) {
		return (
			<>
				<CssBaseline />
				<AppLoading />
			</>
		);
	}

	const onClickDismiss = key => () => {
		notistackRef.current?.closeSnackbar(key);
	};

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme("light", business?.theme)}>
				<CssBaseline />
				<SnackbarProvider
					TransitionComponent={Grow as any}
					anchorOrigin={{
						vertical: "top",
						horizontal: "center"
					}}
					style={{ fontSize: "1.1rem", minHeight: 52 }}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					ref={notistackRef}
					preventDuplicate
					action={key => (
						<IconButton onClick={onClickDismiss(key)} size={"small"}>
							<CloseIcon style={{ color: "#fff" }} />
						</IconButton>
					)}
				>
					<BusinessRoutes />
				</SnackbarProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export default Business;
