/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { RegisterMutation as RegisterMutationType, RegisterMutationVariables } from "gql/types/mutations/RegisterMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation RegisterMutation($registerInput: RegisterInput!) {
	register(registerInput: $registerInput)
}
`;

const RegisterMutation: IGqlOperation<RegisterMutationType, RegisterMutationVariables> = { text } as any;

export default RegisterMutation;
