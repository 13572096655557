/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { PaymentMethodUpdateMutation as PaymentMethodUpdateMutationType, PaymentMethodUpdateMutationVariables } from "gql/types/mutations/PaymentMethodUpdateMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation PaymentMethodUpdateMutation($customerPaymentMethodUpdateInput: CustomerPaymentMethodUpdateInput!) {
	customerPaymentMethodUpdate(customerPaymentMethodUpdateInput: $customerPaymentMethodUpdateInput)
}
`;

const PaymentMethodUpdateMutation: IGqlOperation<PaymentMethodUpdateMutationType, PaymentMethodUpdateMutationVariables> = { text } as any;

export default PaymentMethodUpdateMutation;
