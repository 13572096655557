export enum modes {
	DEMO = 1,
	STAGING = 2,
	PROD = 3
}

const config = {
	isAppVersionCheckEnabled: process.env.REACT_APP_IS_APP_VERSION_CHECK_ENABLED === "true",
	appVersionCheckIntervalMinutes: parseInt(process.env.REACT_APP_VERSION_CHECK_INTERVAL_MINUTES || "60", 10),
	STRIPE_API_KEY: process.env.REACT_APP_STRIPE_API_KEY,
	STRIPE_PLATFORM_ID: process.env.REACT_APP_STRIPE_PLATFORM_ID,
	mode: modes.DEMO,
	profilingEnabled: ["1", "true"].includes(process.env.REACT_APP_PROFILING_ENABLED?.toLowerCase() || ""),
	PASSWORD_MIN_LENGTH: 6,
	GOOGLE_PLACES_AUTOCOMPLETE_RADIUS_MILES: process.env.REACT_APP_PLACES_AUTOCOMPLETE_RADIUS_MILES
		? parseFloat(process.env.REACT_APP_PLACES_AUTOCOMPLETE_RADIUS_MILES)
		: 10,
	googleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
	googleTagManagerKey: process.env.REACT_APP_GOOGLE_TAG_MGR_KEY,
	googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,

	isGoogleAnalyticsEnabled: process.env.REACT_APP_IS_GOOGLE_ANALYTICS_ENABLED === "true",
	TOKEN_STORAGE_KEY: "at",

	mixPanelToken: process.env.REACT_APP_MIX_PANEL_TOKEN!,

	// FACEBOOK_APP_ID: process.env.REACT_APP_FB_APP_ID || "undefined",
	// GOOGLE_APP_ID: process.env.REACT_APP_GOOGLE_APP_ID,

	refreshTokenUrl: process.env.REACT_APP_REFRESH_TOKEN_URL!,
	graphQlurl: process.env.REACT_APP_API_URI!,
	wsUrl: process.env.REACT_APP_GQL_WS_URI!,

	defaultImageUrl: process.env.REACT_APP_DEFAULT_IMG_URL!,
	tosUrl: process.env.REACT_APP_TOS_URL!,
	privacyPolicyUrl: process.env.REACT_APP_PRIVACY_URL!,
	wsPingIntervalSeconds: process.env.REACT_APP_WS_PING_INTERVAL_SECONDS
		? parseInt(process.env.REACT_APP_WS_PING_INTERVAL_SECONDS)
		: 30
};

export default config;
