// eslint-disable-next-line import/no-named-as-default
import Helmet from "react-helmet";

interface Props {
	title: string;
	subTitle?: string;
}

const Head: React.FC<Props> = ({ title, subTitle }) => {
	return (
		<Helmet>
			<title>{subTitle ? `${title} | ${subTitle}` : title}</title>
		</Helmet>
	);
};

export default Head;
