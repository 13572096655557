import React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";

import analytics from "analytics/analytics";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useStore from "store";

import { OrderFulfillmentMethods } from "gql/types/globals";

const StyledRootBox = styled(Box)`
	position: fixed;
	top: 0;
	z-index: 100;
	width: 100vw;
	background-color: ${p => p.theme.palette.secondary.main};
	color: ${p => p.theme.palette.secondary.contrastText};
	display: flex;
	flex-direction: column;
	justify-items: center;
	justify-content: center;
	align-items: center;
	align-content: center;
	min-height: 40px;
	margin: 0px;
	padding: 0px;
	filter: drop-shadow(0px 2px 4px #000);
`;

const StyledInfo = styled(Typography)`
	font-size: 1.1rem;
	vertical-align: middle;
`;

const StyledAddr = styled(Typography)`
	font-size: 1.1rem;
	max-width: 55%;
	text-overflow: ellipsis;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	white-space: nowrap;
`;

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)`
	color: ${p => p.theme.palette.secondary.contrastText};
	align-self: center;
`;

const StyledNetworkWarning = styled(Box)`
	vertical-align: center;
	align-self: center;
	justify-self: center;
	position: fixed;
	margin: 0;
	padding: 0;
	height: 23;
	right: ${p => p.theme.spacing(1)};
`;

interface Props {}

const OrderFulfillmentPanel: React.FC<Props> = () => {
	const { t } = useTranslation();

	const isOrderingEnabled = useStore(store => store.isOrderingEnabled);
	const orderFulfillmentType = useStore(store => store.orderFulfillmentType);
	const orderDeliveryAddress = useStore(store => store.orderDeliveryAddress);
	const orderFulfillmentTimeString = useStore(store => store.orderFulfillmentTimeString);
	const isClientConnected = useStore(store => store.isClientConnected);

	const theme = useTheme();

	const navigate = useNavigate();
	const clicked = () => {
		if (!window.location.pathname.endsWith("ordertype")) {
			navigate(
				window.location.pathname.endsWith("/")
					? `${window.location.pathname}ordertype`
					: `${window.location.pathname}/ordertype`
			);
			analytics.track("Order Type clicked");
		}
	};

	const orderFulfillmentMethod = orderFulfillmentType?.orderFulfillmentMethod;

	if (!isOrderingEnabled) {
		return (
			<StyledRootBox sx={{ backgroundColor: "#777", filter: "drop-shadow(0px 2px 2px #555)" }}>
				{t("sorryOrderingDisabled")}
			</StyledRootBox>
		);
	}

	if (orderFulfillmentMethod === OrderFulfillmentMethods.delivery) {
		return (
			<StyledRootBox onClick={clicked} role={"button"}>
				<Stack direction={"row"}>
					<StyledInfo sx={{ mr: 1 }}>{`${t("delivery")}: `}</StyledInfo>
					<StyledInfo>{orderFulfillmentTimeString}</StyledInfo>
					{/* {isAsapOrder && (
						<Typography className={classes.info}>
							{t("asapWithRange", { start: totalLeadTime, end: totalLeadTime + 10 })}
						</Typography>
					)}
					{!isAsapOrder && deliveryOrPickupAt && (
						<Typography>
							{"at " + convertedDeliveryOrPickupAt && convertedDeliveryOrPickupAt!.toFormat
								? convertedDeliveryOrPickupAt!.toFormat("h:mm a")
								: null}
						</Typography>
					)} */}
					{/* <Typography className={clsx(classes.addr, textClass)}> */}
					<StyledAddr
						sx={{ ml: 0.5 }}
					>{` • ${orderDeliveryAddress?.place.structured_formatting.main_text}`}</StyledAddr>
					{/* </Typography> */}
					<StyledKeyboardArrowDownIcon fill={theme.palette.secondary.contrastText} />
				</Stack>
			</StyledRootBox>
		);
	}

	return (
		<StyledRootBox onClick={clicked} role={"button"}>
			<Stack direction={"row"}>
				<StyledInfo sx={{ mr: 0.5 }}>{`${t(orderFulfillmentType!.orderFulfillmentMethod!)}`}</StyledInfo>

				<StyledInfo>{orderFulfillmentTimeString}</StyledInfo>
				{/* {isAsapOrder && <Typography>{t("asapWithTime", { time: asapTime })}</Typography>}
				{!isAsapOrder && deliveryOrPickupAt && (
					<Typography>
						{"at " + convertedDeliveryOrPickupAt && convertedDeliveryOrPickupAt!.toFormat
							? convertedDeliveryOrPickupAt!.toFormat("h:mm a")
							: null}
					</Typography>
				)} */}
				<StyledKeyboardArrowDownIcon fill={theme.palette.secondary.contrastText} />
				{!isClientConnected && (
					<StyledNetworkWarning>
						<OfflineBoltIcon />
					</StyledNetworkWarning>
				)}
			</Stack>
		</StyledRootBox>
	);
};

export default React.memo(OrderFulfillmentPanel);
