/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { OrderTabMakeHostMutation as OrderTabMakeHostMutationType, OrderTabMakeHostMutationVariables } from "gql/types/mutations/OrderTabMakeHostMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation OrderTabMakeHostMutation($orderTabMakeHostInput: OrderTabMakeHostInput!) {
	orderTabMakeHost(orderTabMakeHostInput: $orderTabMakeHostInput) {
		_id
		tabHost {
			_id
			name
		}
		tableMembers {
			_id
			name
		}
	}
}
`;

const OrderTabMakeHostMutation: IGqlOperation<OrderTabMakeHostMutationType, OrderTabMakeHostMutationVariables> = { text } as any;

export default OrderTabMakeHostMutation;
