import React, { useEffect, useRef } from "react";

import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import { DateTime } from "luxon";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexDirection: "column",
		minWidth: 60,
		padding: 0,
		borderLeftWidth: 0.5,
		borderLeftStyle: "solid",
		borderLeftColor: theme.palette.secondary.light
	},
	rightBorder: {
		borderRightWidth: 0.5,
		borderRightStyle: "solid",
		borderRightColor: theme.palette.secondary.light
	},
	month: {
		textTransform: "uppercase",
		fontSize: 14,
		fontWeight: 600,
		display: "block",
		verticalAlign: "top",
		height: 18,
		marginBottom: theme.spacing(0.25),
		color: "#888"
	},
	blank: {
		display: "block",
		height: 25
	},
	dayOfWk: {
		fontSize: 13,
		textTransform: "uppercase",
		display: "block",
		color: "#aaa",
		height: 15
	},
	dayContainer: {
		display: "flex",
		alignContent: "center",
		alignItems: "center",
		justifyContent: "center",
		justifyItems: "center",
		height: 40
	},
	day: {
		fontSize: 18,
		textTransform: "uppercase",
		alignItems: "center",
		display: "block",
		color: "#888",
		height: 30,
		width: 30
	},
	centered: {
		display: "flex",
		alignContent: "center",
		justifyContent: "center",
		textAlign: "center"
	},
	"@keyframes selectAnimate": {
		"0%": { transform: "scale(0.8)" },
		"100%": { transform: "scale(1.1)" }
	},
	selected: {
		display: "flex",
		color: theme.palette.secondary.contrastText,
		backgroundColor: theme.palette.secondary.light,
		boxShadow: "0 2px 5px rgba(0,0,0,0.5)",
		alignContent: "center",
		justifyContent: "center",
		scale: 1.1,
		borderRadius: 15,

		animationName: "$selectAnimate",
		animationDuration: ".1s",
		animationTimingFunction: "ease-in-out"
	},
	isBorderInactive: {
		borderColor: theme.palette.grey[300],
		color: theme.palette.grey[300]
	},
	isInActive: {
		backgroundColor: theme.palette.grey[400],
		boxShadow: "none"
	}
}));

interface DayProps {
	day: DateTime;
	index: number;
	isEnd: boolean;
	selected?: boolean;
	onSelected: (selectedDate) => void;
	isActive: boolean;
}

const Day: React.FC<DayProps> = ({ day, index, selected, onSelected, isActive, isEnd }) => {
	const classes = useStyles();
	const dayRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (isActive && selected) {
			window.setTimeout(() => {
				dayRef && dayRef.current && dayRef!.current!.scrollIntoView();
			}, 250);
		}
	}, [isActive, selected]);

	const clicked = () => {
		onSelected(day);
	};

	return (
		<div
			className={clsx(classes.root, isEnd && classes.rightBorder, !isActive && classes.isBorderInactive)}
			onClick={clicked}
			ref={dayRef}
		>
			<Box className={clsx(classes.month, classes.centered)}>{index === 0 || day.day === 1 ? day.monthShort : " "}</Box>

			<Box className={clsx(classes.dayOfWk, classes.centered)}>{day.toFormat("ccc")}</Box>
			<Box className={clsx(classes.dayContainer)}>
				<div
					className={clsx(
						classes.day,
						classes.centered,
						selected && classes.selected,
						selected && !isActive && classes.isInActive
					)}
				>
					{day.day}
				</div>
			</Box>
		</div>
	);
};

export default Day;
