/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { BusinessChangedSubscription as BusinessChangedSubscriptionType, BusinessChangedSubscriptionVariables } from "gql/types/subscriptions/BusinessChangedSubscription";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`subscription BusinessChangedSubscription($businessId: ID!) {
	businessChanged(businessId: $businessId) {
		_id
		slug
		data
	}
}
`;

const BusinessChangedSubscription: IGqlOperation<BusinessChangedSubscriptionType, BusinessChangedSubscriptionVariables> = { text } as any;

export default BusinessChangedSubscription;
