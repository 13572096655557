import React from "react";

import ShareIcon from "@mui/icons-material/Share";
import { Box, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import useStore from "store";

import IconButton2 from "components/IconButton2";
import QrCode from "components/QrCode";
import { BusinessQuery_business } from "gql/types/queries/BusinessQuery";

const useStyles = makeStyles(theme => {
	return {
		qrContainer: {
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(4),
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center"
		},
		sharingInstructions: {
			fontSize: "1.2rem"
		},
		sharingInstructions2: {
			fontSize: "1.2rem",
			marginBottom: theme.spacing(4)
		},
		qr: {
			height: 200,
			minHeight: 200,
			minWidth: 200
		},
		shareIconBtn: {
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.primary.contrastText,
			marginTop: theme.spacing(4)
		}
	};
});

interface Props {
	businessName: string;
	tableSlug: string;
}

const TableQrCode: React.FC<Props> = ({ businessName, tableSlug }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const locale = useStore(store => store.locale);
	const localId = useStore(store => store.localId);

	const tableUrl = `${window.location.origin}/${locale}/table/${tableSlug}/join/${localId}`;

	const shareClicked = async () => {
		if (navigator.share) {
			try {
				const shareDetails = {
					url: tableUrl,
					title: t("tabShareTitle"),
					text: t("tabShareDesc", { restaurant: businessName })
				};

				await navigator.share(shareDetails).then(() => console.log("CONTENT SHARED!"));
			} catch (error) {
				console.error(`Sharing error: ${error}`);
			}
		} else {
			// fallback code
			console.log("Web share is currently not supported on this browser. Please provide a callback");
		}
	};

	return (
		<>
			{tableUrl && (
				<Box className={classes.qrContainer}>
					<Typography className={classes.sharingInstructions}>{t("sharingInstructions")}</Typography>
					<Typography className={classes.sharingInstructions2}>{t("sharingInstructions2")}</Typography>
					<QrCode url={tableUrl} className={classes.qr} />
					<IconButton2 className={classes.shareIconBtn} size={"medium"} onClick={shareClicked}>
						<ShareIcon style={{ color: "#eee" }} />
					</IconButton2>
					{t("share")}
				</Box>
			)}
		</>
	);
};

export default TableQrCode;
