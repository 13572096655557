/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { OrderTabJoinMutation as OrderTabJoinMutationType, OrderTabJoinMutationVariables } from "gql/types/mutations/OrderTabJoinMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation OrderTabJoinMutation($orderTabJoinInput: OrderTabJoinInput!) {
	orderTabJoin(orderTabJoinInput: $orderTabJoinInput) {
		_id
		orderStatus
		localId
		orderTabStatus
		table {
			slug
			name
		}
		tabHost {
			_id
			name
		}
		tableMembers {
			_id
			tableMemberStatus
			name
		}
	}
}
`;

const OrderTabJoinMutation: IGqlOperation<OrderTabJoinMutationType, OrderTabJoinMutationVariables> = { text } as any;

export default OrderTabJoinMutation;
