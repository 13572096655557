import { Typography } from "@mui/material";

interface PriceProps {
	className?: string;
	priceCents: number | null;
	displayPrice?: string | null;
}

const Price: React.FC<PriceProps> = ({ priceCents, displayPrice, className }) => {
	if (displayPrice) {
		return <Typography className={className}>{displayPrice}</Typography>;
	} else {
		return <Typography className={className}>{formatMoney(priceCents)}</Typography>;
	}
};

export const formatMoney = (amountCents: number | null | undefined, displayPrice?: string): string | null => {
	// TODO: why do we show empty string instead of zero here?
	// return amountCents ? `$${(amountCents / 100).toFixed(2)}` : "";
	if (displayPrice) {
		return displayPrice;
	} else {
		return `$${((amountCents || 0) / 100).toFixed(2)}`;
	}
};

export default Price;
