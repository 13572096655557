import { DateTime } from "luxon";

// parses hh:mm strings into DateTime
const getTimeFromTimeString = (
	time: string,
	baseDate: DateTime = DateTime.local(),
	timeZone: string
): DateTime | undefined => {
	if (!time) {
		return undefined;
	}
	const parts = time?.split(":");
	if (parts.length < 2) {
		throw new Error(`Invalid time: ${time}`);
	}

	return DateTime.fromObject({
		zone: timeZone,
		year: baseDate.year,
		month: baseDate.month,
		day: baseDate.day,
		hour: parseInt(parts[0]),
		minute: parseInt(parts[1]),
		second: parts.length === 3 ? parseInt(parts[2], 10) : 0
	});
	// return baseDate.set({
	// 	hour: parseInt(parts[0]),
	// 	minute: parseInt(parts[1]),
	// 	second: 0,
	// 	millisecond: 0
	// });
};

export default getTimeFromTimeString;
