/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { OrderApplyPromotionMutation as OrderApplyPromotionMutationType, OrderApplyPromotionMutationVariables } from "gql/types/mutations/OrderApplyPromotionMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation OrderApplyPromotionMutation($orderApplyPromotionInput: OrderApplyPromotionInput!) {
	orderApplyPromotion(orderApplyPromotionInput: $orderApplyPromotionInput) {
		_id
		discountPercent
		discountCents
		orderSubTotalCents
		taxCents
		orderTotalCents
	}
}
`;

const OrderApplyPromotionMutation: IGqlOperation<OrderApplyPromotionMutationType, OrderApplyPromotionMutationVariables> = { text } as any;

export default OrderApplyPromotionMutation;
