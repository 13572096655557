import { useCallback, useEffect } from "react";

import debounce from "lodash/debounce";

const useViewHeight = (): void => {
	const setVh = useCallback(() => {
		// const vh = window.innerHeight * 0.01;
		const vh = window.visualViewport ? window.visualViewport.height * 0.01 : window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${vh}px`);
	}, []);

	useEffect(() => {
		// window.addEventListener("load", setVh);
		window.addEventListener("resize", debounce(setVh));
		window.visualViewport && window.visualViewport.addEventListener("resize", debounce(setVh));

		return (): void => {
			// window.removeEventListener("load", setVh);
			window.removeEventListener("resize", debounce(setVh));
			window.visualViewport && window.visualViewport.removeEventListener("resize", debounce(setVh));
		};
	}, [setVh]);

	useEffect(setVh, [setVh]);
};

export default useViewHeight;
