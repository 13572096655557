import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import ProgressBar from "components/ProgressBar";

const useStyles = makeStyles(() => {
	return {
		root: {
			height: "calc(var(--vh, 1vh) * 100)",
			width: "100vw",
			display: "flex",
			flexDirection: "column",
			flex: 1,
			backgroundColor: "#fff",
			margin: 0,
			padding: 0
		}
	};
});

interface Props {}

const AppLoading: React.FC<Props> = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<ProgressBar />
		</div>
	);
};

export default AppLoading;
