/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { OrderQuery as OrderQueryType, OrderQueryVariables } from "gql/types/queries/OrderQuery";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`query OrderQuery($orderInput: OrderQueryInput!) {
	order(orderInput: $orderInput) {
		_id
		orderStatus
		orderFulfillmentType {
			orderFulfillmentMethod
			priceCents
		}
		orderNumber

		discountPercent
		discountCents
		orderSubTotalCents
		automaticGratuityCents
		automaticGratuityPercentage
		automaticGratuityPartySize
		taxCents
		tipCents
		tipPercentage
		deliveryFeeCents
		orderTotalCents
		taxRate

		includeUtensils

		orderTabStatus
		tabStartedAt
		tabClosedAt
		table {
			_id
			name
			slug
		}

		tabHost {
			_id
			name
		}

		tableMembers {
			_id
			tableMemberStatus
			name
		}

		taxes {
			type
			taxRate
			taxCents
		}

		customer {
			firstName
			lastName
			phoneNumber
		}

		deliveryAddress {
			address1
			address2
			city
			state
			postalCode
			gpsCoordinates {
				latitude
				longitude
			}
			placeId
			place
		}

		phoneNumber
		displayPhoneNumber

		user {
			email
		}

		payments {
			_id
			paymentAmountCents
			lastRefreshedAt

			card {
				brand
				last4
			}
		}

		business {
			_id
			slug
			name
			localeNames
			locales
			displayPhoneNumber

			address {
				address1
				address2
				city
				state
				postalCode
				country
				gpsCoordinates {
					latitude
					longitude
				}
			}
		}

		orderItems {
			_id
			localId
			quantity
			unitPriceCents
			extendedPriceCents
			specialInstructions
			orderedById
			orderItemStatus

			menuItem {
				_id
				itemCode
				name
				localeNames
				localeDescriptions
				priceCents
				displayPrice
				isTaxable
				isAlcoholicBeverage
				mediaLinks {
					mediaUrl
					mediaType
				}
			}

			selectedMenuItemModifiers {
				_id
				menuItemModifierGroupId
				name
				localeNames
				isDefault
				priceCents
				displayPrice
			}
			# selectedMenuItemModifierGroups {
			# 	_id
			# 	name
			# 	localeNames
			# }
		}
	}
}
`;

const OrderQuery: IGqlOperation<OrderQueryType, OrderQueryVariables> = { text } as any;

export default OrderQuery;
