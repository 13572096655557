/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Reducer, useCallback, useEffect, useReducer, useRef, useState } from "react";

import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import { motion } from "framer";
import { DateTime } from "luxon";
import { useNavigate } from "react-router";
import useStore, { CartItem } from "store";

import calculateMenuItemUnitPriceCents from "utils/calculateMenuItemUnitPriceCents";

import getLocalId from "utils/getLocalId";

import MenuItem from "api/types/MenuItem";
import Modal from "components/Modal";
import { MediaTypes } from "gql/types/globals";
import useLocalize from "hooks/useLocalize";

import AnimatedImage from "./AnimatedImage";
import MenuItemDetails from "./MenuItemDetails";
import SelectedModifier from "./SelectedModifier";

const useStyles = makeStyles(theme => {
	return {
		container: {
			display: "flex",
			flexDirection: "column",
			minHeight: "100%",
			height: "100vh",
			padding: 0,
			margin: 0,
			zIndex: 1400,
			// paddingRight: theme.spacing(2),
			justifyContent: "space-between",
			[theme.breakpoints.up(600)]: {
				width: 600,
				elevation: 10,
				height: "calc(var(--vh, 1vh) * 90 - 60px)"
			}
		},
		main: {
			display: "flex",
			flex: 1,
			flexGrow: 1,
			flexDirection: "column",
			margin: 0,
			overflowX: "hidden",
			overflowY: "hidden",
			padding: 0
		},
		noImage: {
			marginTop: 50
		},
		unavailable: {
			alignSelf: "flex-end",
			height: 25,
			padding: theme.spacing(2),
			width: "100%"
		},
		unavailableMessage: {
			textAlign: "center"
		},
		row: {
			display: "flex",
			flexDirection: "row",
			flex: 1
		},
		centered: {
			justifyContent: "center"
		},
		play: {
			color: "#fff",
			alignSelf: "center",
			justifySelf: "center",
			zIndex: 2000,
			position: "absolute",
			top: "calc(50% - 40px)",
			left: "calc(50vw - 40px)",
			height: 80,
			width: 80
		},
		itemsRow: {
			paddingBottom: theme.spacing(2)
		},
		disabled: {},
		clippingContainer: {
			height: "100%",
			width: "100%",
			overflow: "hidden"
		},
		videoContainer: {
			height: "100%",
			width: "100%",
			position: "relative"
		},
		img: {
			margin: 0,
			padding: 0,
			maxHeight: "30vh",
			width: "100vw",
			objectFit: "cover",
			transform: "none",
			position: "relative",
			// display: "flex",
			// margin: "auto",
			// justifyContent: "center",
			boxShadow: "0 0 15px",
			[theme.breakpoints.up(600)]: {
				minWidth: 600,
				width: "100%"
			}
		},
		fullScreen: {
			maxHeight: "100%",
			objectFit: "cover",
			width: "100vw",
			height: "100%",
			[theme.breakpoints.up(600)]: {
				height: "calc(var(--vh, 1vh) * 90 - 60px)"
			}
		},
		fsToggle: {
			position: "absolute",
			color: "#fff",
			bottom: 10,
			right: 10,
			zIndex: 2000
		},
		fsToggleFS: {
			position: "absolute",
			color: "#fff",
			bottom: 10,
			right: 10,
			zIndex: 2000
		}
	};
});

type State = {
	mode: string;
	localId: string;
	menuItem: MenuItem;
	specialInstructions: string | null | undefined;
	quantity: number;
	selectedModifiers: (SelectedModifier | null)[];
	unitPriceCents: number;
	extendedPriceCents: number;
	valid: boolean;
};

enum actions {
	loadCartItem
}
type Action = { type: actions.loadCartItem; payload: CartItem };

const isValid = (state: State): boolean => {
	if (state.quantity < 1) {
		return false;
	}

	if (state.menuItem?.menuItemModifierGroups && state.menuItem!.menuItemModifierGroups?.length > 0) {
		for (const group of state.menuItem.menuItemModifierGroups) {
			const selected = state.selectedModifiers.filter(x => x && x.menuItemModifierGroupId === group!._id);

			if (group?.requiredMinimum && selected.length < group.requiredMinimum) {
				return false;
			}

			if (group && group.requiredMaximum && selected.length > group.requiredMaximum) {
				return false;
			}
		}
	}

	return true;
};

const validate = (state: State): State => ({ ...state, valid: isValid(state) });

const calculate = (state: State): State => {
	const unitPriceCents = calculateMenuItemUnitPriceCents(state);
	return { ...state, unitPriceCents, extendedPriceCents: unitPriceCents * state.quantity };
};

const calculateAndValidate = (state: State) => validate(calculate(state));

const initializer = ({
	mode,
	localId,
	menuItem,
	defaultSpecialInstructions,
	defaultQuantity,
	defaultSelectedModifiers
}: {
	mode: State["mode"];
	localId: State["localId"];
	menuItem: State["menuItem"];
	defaultSpecialInstructions?: State["specialInstructions"];
	defaultQuantity?: State["quantity"];
	defaultSelectedModifiers?: State["selectedModifiers"];
}) =>
	calculateAndValidate({
		mode,
		localId,
		menuItem,
		specialInstructions: defaultSpecialInstructions || "",
		quantity: defaultQuantity || 1,
		selectedModifiers: defaultSelectedModifiers || [],
		unitPriceCents: 0,
		extendedPriceCents: 0,
		valid: false
	});

const reducer: Reducer<State, Action> = (state: State, action: Action) => {
	switch (action.type) {
		case actions.loadCartItem:
			return calculateAndValidate({
				...state,
				...action.payload,
				mode: "edit"
			});

		default:
			return state;
	}
};

interface Props {}

const MenuItemModal: React.FC<Props> = () => {
	const classes = useStyles();
	const { l } = useLocalize();

	const cart = useStore(store => store.cart);
	const business = useStore(store => store.business);
	const setLoading = useStore(store => store.setLoading);

	const [playing, setPlaying] = useState(true);
	const [isClosing, setIsClosing] = useState(false);
	const [isFullScreen, setIsFullScreen] = useState(false);

	const navigate = useNavigate();

	const { pathname } = window.location;
	const isEditMode = pathname.includes("/cart");

	const videoRef = useRef(null);

	useEffect(() => {
		setLoading(false);
		if (isEditMode) {
			const hashLocalId = pathname.split("/")[pathname.split("/").length - 2];

			if (hashLocalId && hashLocalId.length > 0) {
				const cartItem = cart.find(i => i && i.localId == hashLocalId);
				if (cartItem) {
					dispatch({ type: actions.loadCartItem, payload: cartItem });
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // only onmount

	const itemSlug = pathname.replace("/detail", "").split("/").splice(-1)[0];

	const currentMenuItem = !isEditMode
		? business!.menus![0]!.menuItems!.find(f => f!.slug === itemSlug)!
		: cart!.find(i => i && i.localId == itemSlug)?.menuItem;

	if (!currentMenuItem) {
		return null;
	}
	const [state, dispatch] = useReducer(
		reducer,
		{
			mode: "new",
			localId: getLocalId(),
			menuItem: currentMenuItem,
			defaultSpecialInstructions: "",
			defaultQuantity: 1,
			defaultSelectedModifiers: []
		},
		initializer
	);

	const { menuItem } = state;

	const goBack = () => {
		setTimeout(() => {
			navigate("..");
		}, 500);
	};

	const onClose = useCallback(() => {
		if (!isClosing) {
			setIsClosing(true);
			const { pathname } = window.location;
			if (pathname.includes("/main/menu")) {
				goBack();
			} else if (pathname.includes("/cart/")) {
				goBack();
			} else {
				goBack();
			}
		}
	}, [isClosing, setIsClosing, navigate]);

	const toggleFullScreen = () => {
		setIsFullScreen(!isFullScreen);
	};

	const togglePlay = () => {
		setPlaying(!playing);
		if (videoRef && videoRef?.current) {
			if (playing) {
				// @ts-ignore
				videoRef.current!.pause();
			} else {
				// @ts-ignore
				videoRef.current!.play();
			}
		}
	};

	const hasImage = menuItem?.mediaLinks && menuItem?.mediaLinks.length > 0;
	return (
		<Modal
			onClose={onClose}
			hasImage={hasImage}
			startClose={isClosing}
			businessName={business!.name}
			title={l(menuItem?.localeNames, menuItem?.name)}
			// layoutId={menuItem._id}
		>
			<Box className={classes.container}>
				{menuItem && (
					<>
						<Box className={classes.main}>
							{hasImage ? (
								menuItem!.mediaLinks![0]!.mediaType === MediaTypes.image ? (
									<AnimatedImage mediaUrl={menuItem!.mediaLinks![0]!.mediaUrl!} onToggleFullScreen={toggleFullScreen} />
								) : (
									<motion.div
										layout
										transition={{ duration: 0.2 }}
										className={clsx(
											classes.videoContainer,
											!isFullScreen && classes.img,
											isFullScreen && classes.fullScreen
										)}
									>
										<video
											ref={videoRef}
											className={clsx(!isFullScreen && classes.img, isFullScreen && classes.fullScreen)}
											onClick={togglePlay}
											autoPlay
											loop
											src={menuItem!.mediaLinks![0]!.mediaUrl}
											playsInline
											muted
										/>
										{!isFullScreen && (
											<FullscreenIcon className={classes.fsToggle} fontSize="large" onClick={toggleFullScreen} />
										)}
										{isFullScreen && (
											<FullscreenExitIcon className={classes.fsToggleFS} fontSize="large" onClick={toggleFullScreen} />
										)}
										{!playing && <PlayArrowIcon className={classes.play} onClick={togglePlay} />}
									</motion.div>
								)
							) : null}
							{!isFullScreen && <MenuItemDetails onClose={onClose} />}
						</Box>
					</>
				)}
			</Box>
		</Modal>
	);
};

export default MenuItemModal;
