import React, { useState } from "react";

import { Box } from "@mui/material";
import { styled } from "@mui/system";

import { motion } from "framer-motion";

import { useTranslation } from "react-i18next";

import useStore from "store";

import AppLoading from "components/Loading";

const StyledBoxContainer = styled(Box)`
	position: relative;
	display: flex;
	flex-direction: column;
	height: 30px;
	max-height: 30px;
	align-items: center;
	flex-grow: 1;
	margin: 0;
	flex: 1;
`;

const StyledLogoImg = styled("img")`
	justify-self: center;
	align-self: center;
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
`;

const StyledMotionDiv = styled(motion.div)`
	top: -65px;
	position: absolute;
	justify-self: center;
	align-self: center;
	margin: 0;
	padding: 0;
	max-height: 96px;
	max-width: 96px;
`;

interface Props {}

const BusinessLogoBadge: React.FC<Props> = () => {
	const { t } = useTranslation();

	const business = useStore(store => store.business);

	if (!business) {
		return <AppLoading />;
	}
	const infoLogo = business.mediaLinks?.find(m => m && m.key === "infoLogo");

	return (
		<StyledBoxContainer>
			{infoLogo && (
				<StyledMotionDiv
					initial={{ opacity: 0, height: 0, width: 0 }}
					animate={{ opacity: 1, height: 96, width: 96 }}
					transition={{ duration: 0.25, ease: "easeInOut" }}
				>
					<StyledLogoImg src={infoLogo.mediaUrl} alt={t("logo")} />
				</StyledMotionDiv>
			)}
		</StyledBoxContainer>
	);
};

export default BusinessLogoBadge;
