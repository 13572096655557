/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { EnsureCustomerDeviceMutation as EnsureCustomerDeviceMutationType, EnsureCustomerDeviceMutationVariables } from "gql/types/mutations/EnsureCustomerDeviceMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation EnsureCustomerDeviceMutation($ensureCustomerDeviceInput: EnsureCustomerDeviceInput!) {
	ensureCustomerDevice(ensureCustomerDeviceInput: $ensureCustomerDeviceInput) {
		deviceToken
	}
}
`;

const EnsureCustomerDeviceMutation: IGqlOperation<EnsureCustomerDeviceMutationType, EnsureCustomerDeviceMutationVariables> = { text } as any;

export default EnsureCustomerDeviceMutation;
