function loadScript(src: string, position: HTMLElement | null, id: string): void {
	if (!position) {
		return;
	}

	const script = document.createElement("script");
	script.setAttribute("async", "");
	script.setAttribute("id", id);
	script.src = src;
	position.appendChild(script);
}

export default loadScript;
