import React, { useEffect } from "react";

import { Box, List } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { AnimatePresence, motion } from "framer-motion";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router";
import useStore from "store";

import { getNextTimeSlot } from "utils/timeUtils";

import Business from "api/types/Business";
import Head from "components/Head";
import Highlights from "components/Highlights";

// import useWindowSize from "hooks/useWindowSize";
import OrderFulfillmentRoutes from "containers/MobileMain/OrderFulfillmentModalRoutes";
import { BusinessQuery_business_menuTags } from "gql/types/queries/BusinessQuery";

import MenuHeader from "./MenuHeader";
import MenuSection from "./MenuSection";

import MenuItemModal from "../MenuItemModal";

import OrderFulfillmentPanel from "../OrderFulfillmentPanel";

const ORDER_TYPE = "ordertype";

const useStyles = makeStyles(theme => {
	return {
		root: {
			position: "fixed",
			top: 40,

			// maxHeight: "calc(100% - 50px)",
			height: "100%",
			minHeight: "100vh",
			// minHeight: "calc(100% - 50px)",
			// maxHeight: "calc(var(--vh, 1vh) * 100 - 50px)",
			overflowX: "hidden",
			// overflowY: "scroll",
			width: "100%",
			display: "flex",
			flex: 1,
			flexDirection: "column",
			backgroundColor: theme.palette.background.default,
			margin: 0,
			padding: 0,
			paddingBottom: "30vh"
		},
		hdr: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText,
			padding: 0,
			borderRadius: 0
		},
		section: {
			fontSize: 16,
			fontWeight: 600,
			paddingLeft: theme.spacing(2),
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1)
		},
		fulfillmentContainer: {
			margin: 0,
			padding: 0
		},
		li: {},
		menuList: {
			margin: 0,
			padding: 0
		}
	};
});

interface Props {
	business: Business;
	hideOrderFulfillment?: boolean;
	hideHighlights?: boolean;
}

const FullMenuList: React.FC<Props> = ({ business, hideOrderFulfillment, hideHighlights }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const isAppBarVisible = useStore(store => store.isAppBarVisible);
	const orderedItems = useStore(store => store.orderedItems);
	const setIsAppBarVisible = useStore(store => store.setIsAppBarVisible);
	const setIsCartButtonVisible = useStore(store => store.setIsCartButtonVisible);
	const isLoggedIn = useStore(store => store.isLoggedIn);
	const favorites = useStore(store => store.favorites);
	const searchFiltered = useStore(store => store.searchFiltered);

	useEffect(() => {
		if (!isAppBarVisible) {
			setIsAppBarVisible(true);
		}
		setIsCartButtonVisible(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const prevOrdered = orderedItems.length > 0 ? searchFiltered?.filter(i => orderedItems.includes(i!._id!)) : [];

	const favoritesIds = favorites.map(f => f?.menuItemId);

	const favoriteMenuItems =
		isLoggedIn && favorites.length > 0 ? searchFiltered?.filter(i => favoritesIds.includes(i!._id!)) : [];

	const isDetail = window.location.pathname.endsWith("/detail");

	return (
		<>
			{isDetail && (
				<AnimatePresence>
					<Routes>
						<Route path=":slug/detail" element={<MenuItemModal />} />
					</Routes>
				</AnimatePresence>
			)}
			<OrderFulfillmentRoutes path={ORDER_TYPE} />

			<motion.div className={classes.root}>
				<Head title={business!.name} subTitle={t("menu")} />
				{!hideOrderFulfillment && (
					<Box className={classes.fulfillmentContainer}>
						<OrderFulfillmentPanel />
					</Box>
				)}
				{!hideHighlights && <Highlights business={business!} />}

				<MenuHeader />

				<List className={classes.menuList} subheader={<li />}>
					{prevOrdered.length > 0 && (
						<li key={"previouslyOrdered"} className={classes.li}>
							<MenuSection
								isDynamic
								sectionTag={
									{
										tag: t("previouslyOrdered"),
										slug: "previously-ordered"
									} as BusinessQuery_business_menuTags
								}
								menuItems={prevOrdered}
							/>
						</li>
					)}

					{favoriteMenuItems.length > 0 && (
						<li key={"yourFavorites"} className={classes.li}>
							<MenuSection
								isDynamic
								sectionTag={
									{
										tag: t("yourFavorites"),
										slug: "yourFavorites"
									} as BusinessQuery_business_menuTags
								}
								menuItems={favoriteMenuItems}
							/>
						</li>
					)}

					{business.menuTags &&
						business.menuTags.map(
							t =>
								t && (
									<li key={`section-${t.slug}`} className={classes.li}>
										<MenuSection sectionTag={t!} menuItems={searchFiltered} />
									</li>
								)
						)}
				</List>
			</motion.div>
		</>
	);
};

export default React.memo(FullMenuList);
