import i18n from "i18next";
import { DateTime } from "luxon";

import Business from "api/types/Business";
import OrderFulfillmentType from "api/types/OrderFulfillmentType";
import { OrderFulfillmentMethods } from "gql/types/globals";

import getNextOrderFulfillmentTime from "./getNextOrderFulfillmentTime";

/**
 *
 * @param orderTime
 * @param interval
 * @param prepTimeMinutes
 */
const getTimeString = (
	business: Business,
	orderFulfillmentType: OrderFulfillmentType,
	isAsapOrder: boolean,
	deliveryOrPickupAt: DateTime | null
): string => {
	const prepTime = orderFulfillmentType?.minPrepTimeMinutes || 30;
	const maxPrepTime = orderFulfillmentType?.maxPrepTimeMinutes ? orderFulfillmentType!.maxPrepTimeMinutes : 10;

	const totalLeadTime =
		orderFulfillmentType.orderFulfillmentMethod === OrderFulfillmentMethods.delivery
			? prepTime + orderFulfillmentType!.defaultDeliveryTimeMinutes!
			: prepTime;

	const maxLeadTime =
		orderFulfillmentType!.orderFulfillmentMethod === OrderFulfillmentMethods.delivery
			? maxPrepTime + orderFulfillmentType!.defaultDeliveryTimeMinutes!
			: maxPrepTime;

	const nextOrderFulfillmentTime = getNextOrderFulfillmentTime(
		DateTime.local(),
		totalLeadTime,
		business.orderingHours,
		business.timeZone!
	);

	if (nextOrderFulfillmentTime === null) {
		return "";
	}

	const convertedDeliveryOrPickupAt =
		deliveryOrPickupAt && deliveryOrPickupAt?.toMillis
			? deliveryOrPickupAt
			: deliveryOrPickupAt
			? DateTime.fromISO(deliveryOrPickupAt!.toString())
			: null;

	const isToday =
		(isAsapOrder && nextOrderFulfillmentTime?.day === DateTime.local().day) ||
		(convertedDeliveryOrPickupAt && DateTime.local().day === convertedDeliveryOrPickupAt.day);
	const formatString = isToday ? "h:mm a" : "ccc M/d h:mm a";

	if (isAsapOrder) {
		if (i18n.isInitialized) {
			return nextOrderFulfillmentTime.diffNow("minutes").minutes <= totalLeadTime
				? totalLeadTime !== maxLeadTime
					? i18n.t("asapWithRange", { start: totalLeadTime, end: maxLeadTime })
					: i18n.t("asapInMinutes", { minutes: totalLeadTime })
				: nextOrderFulfillmentTime
				? i18n.t("asapWithTime", {
						time: (isToday ? i18n.t("today") : "") + nextOrderFulfillmentTime?.toFormat(formatString)
				  })
				: "missing";
		} else {
			// BUGFIX: skip i18n since it's not initialized yet
			return nextOrderFulfillmentTime.diffNow("minutes").minutes <= totalLeadTime
				? totalLeadTime !== maxLeadTime
					? `ASAP in ${totalLeadTime} to ${maxLeadTime} minutes`
					: `ASAP in ~${totalLeadTime} minutes `
				: nextOrderFulfillmentTime
				? `ASAP (${isToday ? "Today " : ""}${nextOrderFulfillmentTime?.toFormat(formatString)})`
				: "missing";
		}
	}

	return convertedDeliveryOrPickupAt
		? (isToday ? i18n.t("today") : "") + convertedDeliveryOrPickupAt?.toFormat(formatString)
		: "missing";
};

export default getTimeString;
