import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from "@apollo/client";

import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { getMainDefinition } from "@apollo/client/utilities";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { persistCache } from "apollo3-cache-persist";
import JwtDecode from "jwt-decode";

import { getAccessToken, setAccessToken } from "utils/accessToken";
import { getDeviceToken, setDeviceToken } from "utils/deviceToken";

import config from "config";

import WebSocketLink from "./WebSocketLink";
import { observerLink } from "./observerLink";
// import { webSocketLink } from "./webSocketLink";

export const cache = new InMemoryCache();

let _onNetworkChangedCallback;
export const onNetworkChanged = (callback: (isConnected: boolean) => void): void => {
	_onNetworkChangedCallback = callback;
};

const isSubscriptionsEnabled = process.env.REACT_APP_SUBSCRIPTIONS_ENABLED === "true";

let _wsLink: WebSocketLink | null = null;

// First, before before instantiating ApolloClient await the persistCache, otherwise queries might execute before persisting
(async () => {
	await persistCache({
		cache,
		storage: window.localStorage
	});
})();

// GQL Subscriptions
// console.log("isSubscriptionsEnabled", isSubscriptionsEnabled);
if (isSubscriptionsEnabled) {
	// subscribeToAccessToken(() => subscriptionClient.close(true, true));
	const deviceToken = { deviceToken: getDeviceToken() };
	_wsLink = new WebSocketLink({
		url: config.wsUrl,
		connectionParams: () => deviceToken,
		keepAlive: config.wsPingIntervalSeconds * 1000,

		retryWait: async function waitForServerHealthyBeforeRetry() {
			// if you have a server healthcheck, you can wait for it to become
			// healthy before retrying after an abrupt disconnect (most commonly a restart)
			// await waitForHealthy(url);

			// after the server becomes ready, wait for a second + random 1-4s timeout
			// (avoid DDoSing yourself) and try connecting again
			await new Promise(resolve => setTimeout(resolve, 1000 + Math.random() * 3000));
		},

		on: {
			opened: () => {
				console.log(`[${new Date()}] socket opened`);
				// console.log(`[${new Date()}] socket opened`, socket);
			},
			connected: () => {
				// console.log(`[${new Date()}] connected`, socket);
				console.log(`[${new Date()}] socket connected`);
				if (_onNetworkChangedCallback) {
					_onNetworkChangedCallback(true);
				}
			},
			error: err => {
				console.error("Socket Error");
				console.error(err);
				if (_onNetworkChangedCallback) {
					_onNetworkChangedCallback(false);
				}
			},
			closed: () => {
				console.log(`[${new Date()}] socket closed`);
				if (_onNetworkChangedCallback) {
					_onNetworkChangedCallback(false);
				}
			}
		}
		// connectionCallback: (error, result) => {
		// 	console.log(`${DateTime.local().toISOTime()} connectionCallback error, result`, error, result);
		// }
	});
}

const httpLink = new HttpLink({
	// credentials: "include",
	uri: config.graphQlurl
});

const retryLink = new RetryLink();

// TOKEN REFRESH
const tokenRefreshLink = new TokenRefreshLink({
	accessTokenField: "at",
	isTokenValidOrUndefined: () => {
		let token = getAccessToken();
		if (!token) {
			token = getDeviceToken();
		}
		if (!token) {
			return true;
		}
		try {
			/// check if expired
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const { exp } = JwtDecode(token) as any;
			if (Date.now() >= exp * 1000) {
				return false;
			} else {
				return true;
			}
		} catch {
			return false;
		}
	},

	fetchAccessToken: () => {
		return fetch(config.refreshTokenUrl, {
			credentials: "include",
			method: "POST"
		});
	},

	handleFetch: accessToken => {
		// console.log("handleFetch accessToken", accessToken);
		setDeviceToken(accessToken);
	},

	handleError: err => {
		if (err) {
			console.error("Token err", err);
			// TODO:  Do something
			setDeviceToken("");
			setAccessToken("");
		}
	}
});

const errorHandler = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		console.error("graphQLErrors in apolloClient: ", graphQLErrors);
		// graphQLErrors.map(({ message, locations, path }) => {
		graphQLErrors.map(({ message }) => {
			console.error("GQL Err message", message);
			if (message === "Not authorized") {
				// TODO:  show message
			} else {
				//TODO:  fix
			}
		});
	}
	if (networkError) {
		// Can be a socked closed error
		console.error("Network Error in apolloClient: ", networkError);
		// logoutUser();
	}
});

const requestLink = ApolloLink.from([
	tokenRefreshLink,
	observerLink,
	retryLink,
	errorHandler,
	// httpLink // use without subscriptions
	isSubscriptionsEnabled && _wsLink
		? split(
				({ query }) => {
					const definition = getMainDefinition(query);
					return definition.kind === "OperationDefinition" && definition.operation === "subscription";
				},
				_wsLink,
				httpLink
		  )
		: httpLink
]);

const apolloClient = new ApolloClient({
	cache,
	link: requestLink
});

export default apolloClient;
