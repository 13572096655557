/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { CustomerPaymentMethodsQuery as CustomerPaymentMethodsQueryType, CustomerPaymentMethodsQueryVariables } from "gql/types/queries/CustomerPaymentMethodsQuery";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`query CustomerPaymentMethodsQuery($customerPaymentMethodsInput: CustomerPaymentMethodsInput!) {
	customerPaymentMethods(customerPaymentMethodsInput: $customerPaymentMethodsInput) {
		_id
		name
		brand
		last4
		isDefault
		expMonth
		expYear
	}
}
`;

const CustomerPaymentMethodsQuery: IGqlOperation<CustomerPaymentMethodsQueryType, CustomerPaymentMethodsQueryVariables> = { text } as any;

export default CustomerPaymentMethodsQuery;
