import React from "react";

import QRCode from "qrcode.react";

interface QrCodeProps {
	className?: string;
	url: string;
}

const QrCode: React.FC<QrCodeProps> = ({ url, className }) => {
	return <QRCode value={url} className={className} renderAs={"svg"} size={256} />;
};

export default QrCode;
