/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { NotificationUpdatedSubscription as NotificationUpdatedSubscriptionType, NotificationUpdatedSubscriptionVariables } from "gql/types/subscriptions/NotificationUpdatedSubscription";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`subscription NotificationUpdatedSubscription($notificationUpdatedInput: NotificationUpdatedInput!) {
	notificationUpdated(notificationUpdatedInput: $notificationUpdatedInput) {
		notificationId
		notificationType
		notificationLevel
		title
		message
		data
	}
}
`;

const NotificationUpdatedSubscription: IGqlOperation<NotificationUpdatedSubscriptionType, NotificationUpdatedSubscriptionVariables> = { text } as any;

export default NotificationUpdatedSubscription;
