import React from "react";

import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => {
	return {
		scroll: {
			display: "flex",
			flex: 1,
			flexDirection: "column",
			maxHeight: "calc(var(--vh, 1vh) * 100 - 400px)",
			padding: 0,
			margin: 0,
			overflowY: "scroll",
			[theme.breakpoints.up("sm")]: {
				width: "100%",
				elevation: 10,
				maxHeight: "calc(var(--vh, 1vh) * 100 - 400px)"
			}
		},
		option: {
			width: "100%",
			padding: 0,
			margin: 0,
			borderBottom: "1px solid #eee"
		},
		bottom: {
			alignSelf: "flex-end",
			height: 55,
			padding: theme.spacing(2),
			width: "100%"
		},
		row: {
			display: "flex",
			flexDirection: "row"
		}
	};
});

interface Props {
	timeSlots: {
		label: string;
		isAsapOrder: boolean;
		value: DateTime;
	}[];
	value: number;
	onTimeSelected: (value: number) => void;
}

const TimeList: React.FC<Props> = ({ timeSlots, value, onTimeSelected }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const timeChanged = event => {
		onTimeSelected(event);
	};

	return (
		<Box className={classes.scroll}>
			<RadioGroup aria-label={t("time")} name="time" value={value} onChange={timeChanged}>
				{timeSlots &&
					timeSlots.map((s, indx) => {
						if (indx === 0) {
							return null;
						} else {
							return (
								<FormControlLabel
									className={classes.option}
									key={indx}
									value={s.value.toMillis().toString()}
									checked={s.value.toMillis().toString() === value.toString()}
									control={<Radio />}
									label={s.label}
								/>
							);
						}
					})}
			</RadioGroup>
		</Box>
	);
};

export default React.memo(TimeList);
