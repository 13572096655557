/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { OrderTabUpdateMemberInfoMutation as OrderTabUpdateMemberInfoMutationType, OrderTabUpdateMemberInfoMutationVariables } from "gql/types/mutations/OrderTabUpdateMemberInfoMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation OrderTabUpdateMemberInfoMutation($orderTabUpdateMemberInfoInput: OrderTabUpdateMemberInfoInput!) {
	orderTabUpdateMemberInfo(orderTabUpdateMemberInfoInput: $orderTabUpdateMemberInfoInput) {
		_id
		orderStatus
		localId
		orderTabStatus
		tabHost {
			_id
			name
		}
		tableMembers {
			_id
			tableMemberStatus
			name
		}
	}
}
`;

const OrderTabUpdateMemberInfoMutation: IGqlOperation<OrderTabUpdateMemberInfoMutationType, OrderTabUpdateMemberInfoMutationVariables> = { text } as any;

export default OrderTabUpdateMemberInfoMutation;
