import React from "react";

import { Box, Button, Typography } from "@mui/material";

import { EventLevels } from "gql/types/globals";

interface Props {
	logEvent: ({
		title,
		data,
		level,
		source,
		eventType
	}: {
		title: string;
		level: EventLevels;
		source?: string;
		data?: any;
		eventType?: string;
	}) => Promise<void>;
}

class ErrorBoundary extends React.Component<Props, { hasError: boolean }> {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		try {
			const location = window.location.href;
			this.props.logEvent({
				title: "Error from ordering web",
				eventType: "Error",
				data: {
					appVersion: process.env.REACT_APP_VERSION,
					userAgent: navigator.userAgent,
					location,
					error: error.toString(),
					errorInfo
				},
				level: EventLevels.error
			});
		} catch (err) {
			//eat
		}
	}

	reloadClicked = () => {
		window.location.reload();
	};

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<Box
					style={{
						margin: 20,
						display: "flex",
						height: "80vh",
						flex: 1,
						flexGrow: 1,
						justifyContent: "center",
						justifyItems: "center",
						flexDirection: "column",
						alignItems: "center"
					}}
				>
					<Typography style={{ textAlign: "center", fontSize: "1.2rem", fontWeight: 600 }}>= (</Typography>
					<Typography style={{ textAlign: "center", fontSize: "1.2rem", fontWeight: 600 }}>OOPS!</Typography>
					<Typography style={{ textAlign: "center" }}>We encountered a problem.</Typography>
					<Typography style={{ textAlign: "center" }}>
						{"We've notified the authorities and will hope to get it fixed soon!"}
					</Typography>
					<Typography style={{ textAlign: "center" }}>Sorry for the inconvenience.</Typography>
					<Button variant={"contained"} style={{ marginTop: 20 }} disableElevation onClick={this.reloadClicked}>
						Reload the Page
					</Button>
				</Box>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
