import React from "react";

import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { tagSortByItemDisplayOrder } from "utils/tagSort";

import Business from "api/types/Business";
import config from "config";

import HighlightItem from "./HighlightItem";

const useStyles = makeStyles(theme => {
	return {
		root: {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
			backgroundColor: theme.palette.background.default,
			opacity: 1
		},
		section: {
			fontSize: 16,
			fontWeight: 600,
			paddingLeft: theme.spacing(2),
			paddingBottom: theme.spacing(1)
		},
		hScroll: {
			width: "100vw",
			overflowX: "scroll",
			[theme.breakpoints.up(480)]: {
				overflowX: "auto"
			}
		},
		scrollContent: {
			display: "flex",
			flexDirection: "row",
			paddingLeft: theme.spacing(2)
		}
	};
});

interface Props {
	business: Business;
}

const Highlights: React.FC<Props> = ({ business }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	if (!business) {
		return null;
	}

	const defaultImageUrl = business.menus[0]!.defaultMedia?.mediaUrl
		? business.menus[0]!.defaultMedia.mediaUrl
		: config.defaultImageUrl;
	const highlights = business
		.menus![0]!.menuItems!.filter(m => m && m.tags!.find(t => t && t.slug === "highlights"))
		.sort(tagSortByItemDisplayOrder);

	return (
		<Box className={classes.root}>
			<Typography className={classes.section}>{t("highlights")}</Typography>
			<Box className={classes.hScroll}>
				<Box className={classes.scrollContent}>
					{highlights &&
						highlights.map(
							(h, indx) => h && <HighlightItem key={`_${indx}`} menuItem={h} defaultImageUrl={defaultImageUrl} />
						)}
				</Box>
			</Box>
		</Box>
	);
};

export default Highlights;
