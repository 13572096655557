/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { PlaceWalletOrderStartMutation as PlaceWalletOrderStartMutationType, PlaceWalletOrderStartMutationVariables } from "gql/types/mutations/PlaceWalletOrderStartMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation PlaceWalletOrderStartMutation($placeWalletOrderStartInput: PlaceWalletOrderStartInput!) {
	placeWalletOrderStart(placeWalletOrderStartInput: $placeWalletOrderStartInput) {
		order {
			_id
		}
		paymentIntentKey
	}
}
`;

const PlaceWalletOrderStartMutation: IGqlOperation<PlaceWalletOrderStartMutationType, PlaceWalletOrderStartMutationVariables> = { text } as any;

export default PlaceWalletOrderStartMutation;
