/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { PaymentMethodDeleteMutation as PaymentMethodDeleteMutationType, PaymentMethodDeleteMutationVariables } from "gql/types/mutations/PaymentMethodDeleteMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation PaymentMethodDeleteMutation($customerPaymentMethodDeleteInput: CustomerPaymentMethodDeleteInput!) {
	customerPaymentMethodDelete(customerPaymentMethodDeleteInput: $customerPaymentMethodDeleteInput)
}
`;

const PaymentMethodDeleteMutation: IGqlOperation<PaymentMethodDeleteMutationType, PaymentMethodDeleteMutationVariables> = { text } as any;

export default PaymentMethodDeleteMutation;
