import GetCustomerAppInfoQuery from "api/queries/GetCustomerAppInfoQuery";

import { executeQuery } from "./gqlClient";

const getCustomerAppInfo = async (branch: string, currentVersion: string | undefined): Promise<void> => {
	const appInfo = await executeQuery(GetCustomerAppInfoQuery, {
		variables: {
			getCustomerAppInfoInput: {
				branch,
				currentVersion
			}
		}
	});

	// console.warn(
	// 	"appInfo.data.getCustomerAppInfo?.version === currentVersion",
	// 	appInfo.data.getCustomerAppInfo?.version === currentVersion
	// );
	// console.warn("refresh app!", `/${appInfo.data.getCustomerAppInfo?.version}/ vs /${currentVersion}/`);

	if (appInfo.data.getCustomerAppInfo?.version !== currentVersion) {
		window.location.reload();
	}
};

export default getCustomerAppInfo;
