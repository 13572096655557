import React, { useState } from "react";

import { Box, Tab, Tabs } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import analytics from "analytics/analytics";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router";
import useStore from "store";

import Modal from "components/Modal";

import TabPanel from "components/TabPanel";
import { OrderFulfillmentMethods } from "gql/types/globals";

import DeliveryTab from "./DeliveryTab";
import DineInTab from "./DineInTab";
import PickupTab from "./PickupTab";

const useStyles = makeStyles(theme => {
	return {
		root: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			flexGrow: 1,
			overflowX: "hidden",
			overflowY: "hidden",
			margin: 0,
			marginTop: 50,
			padding: 0,
			width: "100%",
			justifyContent: "space-between",
			[theme.breakpoints.up("sm")]: {
				width: 500,
				height: "calc(var(--vh, 1vh) * 100 - 80px)",
				minHeight: "calc(var(--vh, 1vh) * 100 - 80px)",
				elevation: 10
			},
			// [`${theme.breakpoints.up("sm")} and (orientation: landscape)`]: {
			[theme.breakpoints.up("sm")]: {
				// [theme.breakpoints.up("sm")]: {
			}
		},
		tabList: {
			width: "100%"
		},
		tabRoot: {
			width: "100vw",
			display: "flex",
			flexDirection: "column",
			overflowX: "hidden",
			overflowY: "hidden",
			margin: 0,
			padding: 0
		},
		tab: {
			overflowY: "hidden",
			width: "33%"
		},
		tabPanel: {
			overflowX: "hidden",
			overflowY: "auto",
			width: "100%",
			margin: 0,
			height: "calc(var(--vh, 1vh) * 100 - 100px)",
			padding: 0,
			[theme.breakpoints.up("sm")]: {
				width: 500,
				height: "calc(var(--vh, 1vh) * 100 - 110px)",
				elevation: 10
			}
		}
	};
});

enum TabTypes {
	dineIn = "dineIn",
	pickup = "pickup",
	delivery = "delivery"
}

interface Props {}

const OrderFulfillmentModal: React.FC<Props> = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	const business = useStore(store => store.business);
	const orderFulfillmentType = useStore(store => store.orderFulfillmentType);

	const setLoading = useStore(action => action.setLoading);
	const [isClosing, setIsClosing] = useState(false);

	const navigate = useNavigate();

	// fix for selected tab indicator width problem
	React.useEffect(() => {
		setTimeout(() => window.dispatchEvent(new CustomEvent("resize")), 0);
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!business) {
		return null;
	}

	const dineInFulfillmentType = business.orderFulfillmentTypes.find(
		t => t?.orderFulfillmentMethod === OrderFulfillmentMethods.dineIn
	);
	const pickupFulfillmentType = business.orderFulfillmentTypes.find(
		t => t?.orderFulfillmentMethod === OrderFulfillmentMethods.pickup
	);
	const deliveryFulfillmentType = business.orderFulfillmentTypes.find(
		t => t?.orderFulfillmentMethod === OrderFulfillmentMethods.delivery
	);

	const [currentTab, setCurrentTab] = useState(
		orderFulfillmentType?.orderFulfillmentMethod === OrderFulfillmentMethods.pickup
			? TabTypes.pickup
			: orderFulfillmentType?.orderFulfillmentMethod === OrderFulfillmentMethods.dineIn
			? TabTypes.dineIn
			: TabTypes.pickup // TODO: fix to make extensible
	);

	const tabChanged = (event, newValue) => {
		setCurrentTab(newValue);
	};

	const closeFromTab = () => {
		setIsClosing(true);
		onClose();
	};

	const onClose = () => {
		analytics.track("OrderFulfillmentModal closed");
		if (isClosing) {
			// push(window.location.pathname.replace("/type", ""));
			setIsClosing(false);
			navigate("..");
		}
	};

	return (
		<Modal onClose={closeFromTab} startClose={isClosing} businessName={business!.name} title={t("orderType")}>
			<Box className={classes.root}>
				<Tabs className={classes.tabList} value={currentTab} onChange={tabChanged} aria-label={t("selectOrderType")}>
					{dineInFulfillmentType && <Tab label={t("dineIn")} className={classes.tab} value={TabTypes.dineIn} />}
					{pickupFulfillmentType && <Tab label={t("pickup")} className={classes.tab} value={TabTypes.pickup} />}
					{deliveryFulfillmentType && <Tab label={t("delivery")} className={classes.tab} value={TabTypes.delivery} />}
				</Tabs>

				{dineInFulfillmentType && (
					<TabPanel className={classes.tabPanel} value={TabTypes.dineIn} selectedValue={currentTab}>
						<DineInTab business={business!} onClose={closeFromTab} orderFulfillmentType={dineInFulfillmentType!} />
					</TabPanel>
				)}
				{pickupFulfillmentType && (
					<TabPanel className={classes.tabPanel} value={TabTypes.pickup} selectedValue={currentTab}>
						<PickupTab business={business!} onClose={closeFromTab} orderFulfillmentType={pickupFulfillmentType!} />
					</TabPanel>
				)}
				{deliveryFulfillmentType && (
					<TabPanel className={classes.tabPanel} value={TabTypes.delivery} selectedValue={currentTab}>
						<DeliveryTab business={business!} onClose={closeFromTab} orderFulfillmentType={deliveryFulfillmentType!} />
					</TabPanel>
				)}
			</Box>
		</Modal>
	);
};

export default OrderFulfillmentModal;
