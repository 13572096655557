import React from "react";

import { Box, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";
import { CustomerInfo } from "store";

const useStyles = makeStyles(theme => {
	return {
		container: {
			display: "flex",
			flexDirection: "column",
			height: "calc(var(--vh, 1vh) * 100 - 50px)",
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			paddingBottom: theme.spacing(2),
			justifyContent: "space-between"
		},
		scrollContainer: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			justifyContent: "flex-start",
			overflowY: "scroll"
		},
		sectionTitle: {
			padding: theme.spacing(1),
			backgroundColor: "rgba(232, 233, 237, 0.87)"
		},
		sectionContainer: {
			width: "100%",
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			paddingBottom: theme.spacing(3)
		},
		bottom: {
			padding: 0,
			margin: 0,
			width: "100%"
		},
		row: {
			display: "flex",
			flexDirection: "row"
		},
		centered: {
			justifyContent: "center"
		},
		title: {
			fontSize: 13,
			fontWeight: 500,
			margin: 0
		},
		textInput: {
			fontSize: 16,
			marginTop: theme.spacing(1)
		},
		addButton: {
			marginTop: theme.spacing(2),
			borderRadius: 8,
			width: "100%"
		},
		itemsRow: {
			paddingBottom: theme.spacing(2)
		},
		totals: {
			marginTop: theme.spacing(2)
		},
		money: {
			textAlign: "right",
			justifySelf: "flex-end",
			alignSelf: "center"
		},
		total: {
			fontWeight: 700,
			fontSize: 18
		},
		spreadRow: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between"
		}
	};
});

interface Props {
	name: string | undefined;
	// firstName?: string | undefined;
	// lastName?: string | undefined;
	errors: Partial<CustomerInfo> | undefined;
	onChange: (val) => void;
	onSubmit: (isValid: boolean) => void;
}

const CustomerInfoPanel: React.FC<Props> = ({
	name,
	// firstName,
	// lastName,
	errors,
	onChange
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<>
			<Box className={classes.sectionContainer}>
				<TextField
					autoFocus
					className={classes.textInput}
					fullWidth
					required
					aria-required="true"
					placeholder={t("name")}
					value={name}
					id={"name"}
					name={"name"}
					autoComplete={"on"}
					inputProps={{ maxLength: 80 }}
					onChange={onChange}
					error={Boolean(errors?.name)}
					helperText={errors?.name}
				/>
				{/* <TextField
					className={classes.textInput}
					fullWidth
					required
					aria-required="true"
					placeholder={t("lastName")}
					value={lastName}
					id={"lastName"}
					name={"lastName"}
					inputProps={{ maxLength: 80 }}
					onChange={onChange}
					error={Boolean(errors?.lastName)}
					helperText={errors?.lastName}
				/> */}
			</Box>
		</>
	);
};

export default CustomerInfoPanel;
