import React from "react";

import { Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
		width: "100%",
		backgroundColor: theme.palette.background.paper
	},
	tab: {
		paddingTop: theme.spacing(1),
		margin: 0
	},
	container: {
		marginTop: theme.spacing(2)
	}
}));

interface Props {
	className?: string;
	children?: React.ReactNode;
	index?: any;
	value: any;
	selectedValue: any;
}

const TabPanel: React.FC<Props> = props => {
	const classes = useStyles();
	const { className, children, value, selectedValue, index, ...other } = props;

	return (
		<Box
			className={clsx(classes.tab, className)}
			role="tabpanel"
			hidden={value !== selectedValue}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === selectedValue && <Box p={0}>{children}</Box>}
		</Box>
	);
};
export default TabPanel;
