import React, { useEffect } from "react";

import { Route, Routes, useLocation } from "react-router-dom";
import useStore from "store";

import ErrorBoundary from "components/ErrorBoundary";

import Redirect from "components/Redirect";

import Business from "./Business";

const AppRoutes = () => {
	const logEvent = useStore(store => store.logEvent);
	const setHash = useStore(store => store.setHash);
	const checkAppVersion = useStore(store => store.checkAppVersion);
	const setCurrentMenuItemIndex = useStore(store => store.setCurrentMenuItemIndex);
	// const checkBusinessIsOrderingEnabled = useStore(store => store.checkBusinessIsOrderingEnabled);
	const location = useLocation();

	useEffect(() => {
		setHash(location.hash);
		checkAppVersion();
		// checkBusinessIsOrderingEnabled();
		if (location.hash === "") {
			setCurrentMenuItemIndex(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ErrorBoundary logEvent={logEvent}>
			<Routes>
				<Route path=".well-known/*" />
				<Route path=":locale/*" element={<Business />} />
				<Route path="//*" element={<Redirect to="/" />} />
			</Routes>
		</ErrorBoundary>
	);
};

export default AppRoutes;
