/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { BusinessCheckIsOrderingEnabledQuery as BusinessCheckIsOrderingEnabledQueryType, BusinessCheckIsOrderingEnabledQueryVariables } from "gql/types/queries/BusinessCheckIsOrderingEnabledQuery";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`query BusinessCheckIsOrderingEnabledQuery($businessInput: BusinessQueryInput!) {
	business(businessInput: $businessInput) {
		_id
		isOrderingEnabled
	}
}
`;

const BusinessCheckIsOrderingEnabledQuery: IGqlOperation<BusinessCheckIsOrderingEnabledQueryType, BusinessCheckIsOrderingEnabledQueryVariables> = { text } as any;

export default BusinessCheckIsOrderingEnabledQuery;
