import { DateTime } from "luxon";

import { BusinessQuery_business_orderingHours } from "gql/types/queries/BusinessQuery";

import getNextTimeSlot from "./getNextTimeSlot";
import getSchedulePeriods from "./getSchedulePeriods";
import getTimeFromTimeString from "./getTimeFromTimeString";

const getTimeSlots = (
	orderTime: DateTime,
	interval: number,
	prepTimeMinutes: number,
	orderingHours: BusinessQuery_business_orderingHours,
	timeZone: string
): DateTime[] => {
	// get the first timeslot
	let hrs = getSchedulePeriods(orderTime, orderingHours, timeZone);
	// let hrs = !scheduleExceptions
	// 	? orderingHours.schedulePeriods?.filter(p => p?.days.includes(dayOfWeek))
	// 	: scheduleExceptions[0]?.periods;

	const slots: DateTime[] = [];
	let slotTime = orderTime.toLocal().day === DateTime.local().day ? orderTime : orderTime.startOf("day");

	let checkDate = orderTime;
	while (hrs?.length === 0) {
		checkDate = orderTime.plus({ day: 1 });
		hrs = getSchedulePeriods(checkDate, orderingHours, timeZone);
		slotTime = slotTime.plus({ day: 1 }).startOf("day");
	}

	if (hrs && hrs?.length > 0) {
		hrs?.forEach(p => {
			if (p) {
				const openTime = getTimeFromTimeString(p.isAllDay ? "00:00" : p.startTime, slotTime, timeZone);
				const closeTime = getTimeFromTimeString(p.isAllDay ? "23:59:59" : p.endTime, slotTime, timeZone);
				if (slotTime < openTime!) {
					slotTime = openTime!.plus({ minutes: prepTimeMinutes });
				}
				while (slotTime <= closeTime!) {
					slots.push(slotTime);
					if (slotTime.minute % interval > 0) {
						slotTime = getNextTimeSlot(slotTime, interval);
						if (slotTime <= closeTime!) {
							slots.push(slotTime);
						}
					}
					slotTime = getNextTimeSlot(slotTime.plus({ minute: interval }), interval);
				}
			}
		});
	}
	return slots;
};

export default getTimeSlots;
