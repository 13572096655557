import React from "react";

import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import Business from "api/types/Business";

const useStyles = makeStyles(theme => {
	return {
		root: {
			display: "flex",
			flexDirection: "column",
			margin: 0,
			marginBottom: theme.spacing(2),
			padding: 0,
			paddingTop: theme.spacing(2),
			boxShadow: "none",
			alignContent: "center",
			justifyContent: "center",
			alignItems: "center",
			justifyItems: "center"
		},
		businessName: {
			textAlign: "center",
			width: "100%",
			fontSize: "1.5rem",
			fontWeight: 600,
			color: theme.palette.primary.main
		},
		center: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "center"
		},
		logo: {
			justifySelf: "center",
			alignSelf: "center",
			maxHeight: 160,
			maxWidth: 160
		}
	};
});

interface Props {
	business: Business;
}

const BusinessHeader: React.FC<Props> = ({ business }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const { logoUrl, logoUrl2 } = business!;

	const src = business.mediaLinks?.find(l => l?.key === "accountLogo")?.mediaUrl || logoUrl2 || logoUrl;

	return (
		<Box className={classes.root}>
			{src ? <img src={src} className={classes.logo} alt={t("businessLogo")} /> : null}

			{!src && (
				<Box className={classes.center}>
					<Typography className={classes.businessName}>{business.name}</Typography>
				</Box>
			)}
		</Box>
	);
};

export default BusinessHeader;
