import mixpanel, { Callback, Dict } from "mixpanel-browser";

import config from "config";
import { BusinessQuery_business } from "gql/types/queries/BusinessQuery";

mixpanel.init(config.mixPanelToken);

const env_check = process.env.NODE_ENV === "production";
// const env_check = true;

let _business: BusinessQuery_business;

const analytics = {
	initialize: (): void => {
		mixpanel.init(config.mixPanelToken);
	},

	setBusiness: (business: BusinessQuery_business): void => {
		_business = business;
	},

	identify: (id: string | undefined): void => {
		if (env_check) {
			mixpanel.identify(id);
		}
	},

	alias: (alias: string, original?: string | undefined): void => {
		if (env_check) {
			mixpanel.alias(alias, original);
		}
	},

	track: (name: string, props?: Dict | undefined, excludeBuiness = false): void => {
		if (env_check) {
			try {
				if (excludeBuiness || !_business) {
					mixpanel.track(name, props);
				} else {
					mixpanel.track(name, { ...props, businessName: _business.name, businessSlug: _business.slug });
				}
			} catch (e) {
				console.error("track error", e);
				mixpanel.init(config.mixPanelToken);
			}
		}
	},

	people: {
		set: (props: Dict, callback?: Callback | undefined): void => {
			if (env_check) {
				mixpanel.people.set(props, callback);
			}
		}
	}
};

export default analytics;
