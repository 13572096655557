import React from "react";

import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import { DateTime } from "luxon";

import Day from "./Day";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		overflowX: "auto",
		overflowY: "hidden",
		scrollBehavior: "smooth",
		paddingBottom: theme.spacing(1)
	}
}));

interface CalendarStripProps {
	daysAhead: number;
	className?: string;
	selectedDate: DateTime;
	startDate: DateTime;
	onChange: (selectedDate) => void;
	isActive: boolean;
}

const CalendarStrip: React.FC<CalendarStripProps> = ({
	className,
	daysAhead,
	selectedDate,
	startDate,
	onChange,
	isActive
}) => {
	const classes = useStyles();

	const dates = [startDate];
	for (let i = 1; i < daysAhead; i++) {
		dates.push(startDate.plus({ days: i }));
	}

	const onSelected = selectedDate => {
		onChange(selectedDate);
	};

	return (
		<Box className={clsx(classes.root, className)}>
			{dates.map((d, indx) => (
				<Day
					day={d}
					index={indx}
					key={`day_${indx}`}
					selected={selectedDate.day === d.day}
					onSelected={onSelected}
					isEnd={indx === daysAhead - 1}
					isActive={isActive}
				/>
			))}
		</Box>
	);
};

export default CalendarStrip;
