/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { OrderFindOpenTabQuery as OrderFindOpenTabQueryType, OrderFindOpenTabQueryVariables } from "gql/types/queries/OrderFindOpenTabQuery";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`query OrderFindOpenTabQuery($orderFindOpenTabInput: OrderFindOpenTabInput!) {
	orderFindOpenTab(orderFindOpenTabInput: $orderFindOpenTabInput) {
		_id
		tabHost {
			name
		}
	}
}
`;

const OrderFindOpenTabQuery: IGqlOperation<OrderFindOpenTabQueryType, OrderFindOpenTabQueryVariables> = { text } as any;

export default OrderFindOpenTabQuery;
