import React from "react";

import { Box, Container, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import { useTranslation } from "react-i18next";

import useStore from "store";

import Business from "api/types/Business";
import ExternalLinks from "components/ExternalLinks";
import useLocalize from "hooks/useLocalize";

const useStyles = makeStyles(theme => {
	return {
		outerContainer: {
			width: "100vw",
			display: "flex",
			flex: 1,
			flexGrow: 1,
			opacity: 1,
			overflow: "hidden",
			margin: 0,
			padding: 0,
			backgroundColor: "rgba(255,255,255,0.8)",
			backdropFilter: "blur(8px)",
			webkitBackdropFilter: "blur(8px)",
			height: "100%"
		},
		container: {
			display: "flex",
			height: "100%",
			minHeight: "100%",
			overflow: "hidden",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			margin: 0,
			padding: 0,
			paddingBottom: theme.spacing(4),
			flex: 1
		},
		center: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "center"
		},
		row: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between"
		},
		logo: {
			justifySelf: "center",
			alignSelf: "center",
			maxHeight: 160,
			maxWidth: "60vw",
			marginBottom: theme.spacing(3)
		},
		businessName: {
			textAlign: "center",
			width: "100%",
			fontSize: "1.5rem",
			fontWeight: 600,
			color: theme.palette.primary.main
		},
		label: {
			textAlign: "left",
			fontFamily: "Barlow Condensed",
			display: "block",
			fontSize: "1rem",
			fontWeight: 600,
			color: "#444"
		},
		address: {
			fontFamily: "Barlow Condensed",
			display: "block",
			fontSize: "1rem",
			color: "#444"
		},
		phone: {
			fontFamily: "Barlow Condensed",
			display: "block"
		},
		contents: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			margin: 0,
			padding: 0,
			[theme.breakpoints.up("sm")]: {
				width: "60vw",
				maxWidth: 800
			}
		},
		landingSection: {
			marginBottom: theme.spacing(2),
			justifyContent: "flex-start",
			justifyItems: "flex-start"
		},
		welcomeContainer: {
			justifyContent: "flex-start",
			justifyItems: "flex-start",
			marginTop: theme.spacing(2)
		},
		col: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center"
		},
		openClosed: {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(4)
		},
		open: {
			fontFamily: "Barlow Condensed",
			fontSize: "1.6rem",
			color: "green"
		},
		closed: {
			fontFamily: "Barlow Condensed",
			fontSize: "1.6rem",
			color: theme.palette.error.main
		},
		hrs: {
			fontFamily: "Barlow Condensed",
			fontSize: "1rem"
		},
		topLine: {
			borderTopWidth: 1,
			borderTopStyle: "solid",
			borderTopColor: "#fff",
			margin: 0,
			padding: 0
		},
		extLink: {
			padding: theme.spacing(2)
		}
	};
});

interface Props {
	business: Business;
}

const BusinessInfoPanel: React.FC<Props> = ({ business }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { l } = useLocalize();
	const isOpenNow = useStore(state => state.isOpenNow);

	const backgroundStyles = makeStyles(business?.styles);
	const backgroundStyle = backgroundStyles();

	if (!business) {
		return null;
	}
	const address = business.address?.address1
		? `${business.address?.address1}${business.address?.address2 !== null ? ` ${business.address?.address2}` : ""}, ${
				business.address?.city
		  }, ${business.address?.state} ${business.address?.postalCode}`
		: null;

	const logoUrl = business.landingLogoUrl || business.logoUrl2 || business.logoUrl;

	return (
		<Box className={clsx(classes.outerContainer, backgroundStyle["infoBackground"])}>
			<Container maxWidth="lg">
				<Box className={classes.container}>
					{logoUrl && (
						<Box className={classes.center}>
							<img className={clsx(classes.logo, backgroundStyle["logo"])} src={logoUrl} alt={"logo"} />
						</Box>
					)}

					<Box className={clsx(classes.contents)}>
						{business.localeLocationNames && (
							<Box className={clsx(classes.landingSection, classes.welcomeContainer)}>
								<Typography className={classes.businessName}>{l(business.localeLocationNames)}</Typography>
							</Box>
						)}
						<Box className={clsx(classes.col, classes.openClosed)}>
							{isOpenNow() ? (
								<Typography className={clsx(classes.open, backgroundStyle["text"])}>{t("openNow")}</Typography>
							) : (
								<Typography className={clsx(classes.closed, backgroundStyle["text"])}>{t("closedNow")}</Typography>
							)}
						</Box>

						<Box className={clsx(classes.col, classes.topLine)}>
							<Box className={clsx(classes.landingSection, classes.welcomeContainer)}>
								<Typography className={clsx(classes.label, backgroundStyle["text"])}>{t("hours")}</Typography>
								{business.displayBusinessHours!.map((h, index) => (
									<Typography key={`h_${index}`} className={clsx(classes.hrs, backgroundStyle["text"])}>
										{h}
									</Typography>
								))}
							</Box>

							{address && (
								<Box className={clsx(classes.landingSection, classes.welcomeContainer)}>
									<Typography className={clsx(classes.label, backgroundStyle["text"])}>{t("address")}</Typography>
									<Link href={business.mapUrl!} target={"_blank"} rel="noopener noreferrer">
										<Typography className={clsx(classes.address, backgroundStyle["text"])}>{address}</Typography>
									</Link>
								</Box>
							)}
							<Box className={clsx(classes.landingSection, classes.welcomeContainer)}>
								<Typography className={clsx(classes.label, backgroundStyle["text"])}>{t("phone")}</Typography>
								<Link className={classes.phone} href={`tel:${business.phoneNumber}`!}>
									<Typography className={clsx(backgroundStyle["text"])}>{business.displayPhoneNumber}</Typography>
								</Link>
							</Box>
						</Box>
					</Box>
					<Box className={clsx(classes.landingSection, classes.welcomeContainer, classes.row)}>
						<ExternalLinks className={backgroundStyle["text"]} externalLinks={business.externalLinks} />
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export default BusinessInfoPanel;
