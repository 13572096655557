import React from "react";

import { Badge, Box } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import useStore from "store";

import StoreInfoIcon from "icons/StoreIcon";

const useStyles = makeStyles(() => {
	return {
		root: {
			display: "flex",
			position: "relative"
		},
		fabIcon: {
			elevation: 10
		},
		badge: {
			top: 2,
			right: -4
		}
	};
});

interface Props {
	className?: string;
}

const StoreIcon: React.FC<Props> = ({ className }) => {
	const cls = useStyles();

	const business = useStore(store => store.business);
	const businessPromotions = useStore(store => store.businessPromotions);

	return (
		<Box className={cls.root}>
			<StoreInfoIcon className={clsx(cls.fabIcon, className)} height={24} width={24} />
			{business?.settings?.isPromotionsEnabled && (
				<Badge badgeContent={businessPromotions?.length} color={"secondary"} className={cls.badge} />
			)}
		</Box>
	);
};

export default StoreIcon;
