import React from "react";

import { Box, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import useStore from "store";

import Head from "components/Head";
import AppLoading from "components/Loading";
import ProgressBar from "components/ProgressBar";

const useStyles = makeStyles(theme => {
	return {
		root: {
			height: "100vh",
			minHeight: "100%",
			alignContent: "center",
			alignItems: "center",
			padding: theme.spacing(2),
			backgroundColor: theme.palette.primary.main
		},
		content: {
			display: "flex",
			flexDirection: "column",
			alignContent: "center",
			margin: 0,
			paddingTop: theme.spacing(4),
			color: "#fff",
			[theme.breakpoints.up("sm")]: {
				width: 400
			}
		},
		progress: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			zIndex: 5000
		},
		center: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			textAlign: "center"
		},
		logo: {
			justifySelf: "center",
			alignSelf: "center",
			maxHeight: 150,
			maxWidth: "50vw",
			marginBottom: theme.spacing(3)
		},
		hdr: {
			fontWeight: 500,
			textAlign: "center",
			padding: theme.spacing(2)
		},
		txt: {
			fontSize: "1.1rem"
		},
		link: {
			fontSize: "1.2rem",
			color: "#fff",
			fontFamily: theme.typography.fontFamily,
			paddingLeft: theme.spacing(2)
		},
		section: {
			paddingTop: theme.spacing(2)
		}
	};
});

interface Props {}

const Unsubscribe: React.FC<Props> = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	const business = useStore(store => store.business);
	const loading = useStore(store => store.loading);
	const params = useParams();

	console.log("params ", params);
	const resubscribe = async () => {};

	if (!business) {
		return <AppLoading />;
	}

	const backgroundStyles = makeStyles(business?.styles);
	const backgroundStyle = backgroundStyles();

	const logoUrl = business.logoUrl;
	return (
		<Box className={clsx(classes.root)}>
			{loading && <ProgressBar className={classes.progress} />}
			<Box className={clsx(classes.content)}>
				{logoUrl && (
					<Box className={classes.center}>
						<img className={clsx(backgroundStyle["logo"], classes.logo)} src={logoUrl} alt={"logo"} />
					</Box>
				)}
				<Head title={business.name} subTitle={"unsubscribe"} />
				<Typography className={classes.hdr} variant={"h1"}>
					{t("youveUnsubscribed")}
				</Typography>
				<Typography className={clsx(classes.txt, classes.center)}>
					{t("youWillNoLongerReceiveRewardsEmailMessages")}
				</Typography>

				<Typography className={clsx(classes.txt, classes.section, classes.center)}>
					{t("unsubscribedOnAccident")}{" "}
					<Link className={classes.link} onClick={resubscribe}>
						{t("subscribeAgain")}
					</Link>
				</Typography>
			</Box>
		</Box>
	);
};

export default Unsubscribe;
