import React, { useCallback } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Button, ButtonGroup } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";

import { useTranslation } from "react-i18next";

import MenuItem from "api/types/MenuItem";

const useStyles = makeStyles(theme => {
	return {
		small: {
			width: 20,
			minWidth: 20,
			fontSize: 16,
			height: 30
		},
		large: {
			width: 45,
			minWidth: 45,
			fontSize: 22,
			height: 45
		},
		largeIcon: {
			margin: 0,
			padding: 0,
			width: 41,
			minWidth: 41,
			height: 41
		},
		icon: {},
		qtyButton: {
			borderColor: "#888",
			backgroundColor: "#fff",
			opacity: 1
		},
		qty: {
			borderTopColor: "#555 !important",
			borderBottomColor: "#555 !important",
			borderLeftColor: "#555 !important",
			backgroundColor: "#fff",
			color: `${theme.palette.text.primary}!important`,
			display: "flex",
			verticalAlign: "middle",
			textAlign: "center",
			justifyContent: "center",
			justifySelf: "center",
			alignItems: "center"
		}
	};
});

export enum sizes {
	small,
	large
}

interface Props {
	quantity: number;
	minimumQuantity: number;
	menuItem: MenuItem;
	disableIncrement?: boolean;
	onDecrement: () => void;
	onIncrement: () => void;
	size: "small" | "large";
	isEditable?: boolean;
}

const ItemQuantity: React.FC<Props> = ({
	menuItem,
	minimumQuantity,
	quantity,
	onDecrement,
	onIncrement,
	size,
	disableIncrement,
	isEditable = true
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const decrement = useCallback(() => {
		onDecrement();
	}, [onDecrement]);

	const increment = useCallback(() => {
		onIncrement();
	}, [onIncrement]);

	return (
		<ButtonGroup disableElevation color="primary">
			<Button
				onClick={decrement}
				className={clsx(classes.qtyButton, size === "small" ? classes.small : classes.large)}
				disabled={quantity === minimumQuantity && isEditable}
				color="primary"
				aria-label={t("decreaseQuantity")}
			>
				<RemoveIcon className={clsx(size === "small" ? classes.small : classes.largeIcon)} />
			</Button>
			<Button disabled className={clsx(size === "small" ? classes.small : classes.large, classes.qty)}>
				{quantity}
			</Button>
			<Button
				onClick={increment}
				className={clsx(classes.qtyButton, size === "small" ? classes.small : classes.large)}
				color="primary"
				disabled={!isEditable || menuItem.isUnAvailable == true || disableIncrement}
				aria-label={t("increaseQuantity")}
			>
				<AddIcon className={clsx(classes.icon, size === "small" ? classes.small : classes.largeIcon)} />
			</Button>
		</ButtonGroup>
	);
};

export default ItemQuantity;
