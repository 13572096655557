import MenuItem from "api/types/MenuItem";

export const tagSortByItemDisplayOrder = (a: MenuItem | null, b: MenuItem | null): number => {
	const aTag = (a !== null && a.tags!.find(t => t && t.slug === "highlights")!.itemDisplayOrder) || 99;
	const bTag = (b !== null && b.tags!.find(t => t && t.slug === "highlights")!.itemDisplayOrder) || 99;

	// const aTag = a
	// 	? a.tags!.find(t => t && t.slug === "highlights")
	// 		? a.tags!.find(t => t && t.slug === "highlights")!.itemDisplayOrder!
	// 		: 99
	// 	: 99;
	// const bTag = b
	// 	? b.tags!.find(t => t && t.slug === "highlights")
	// 		? b.tags!.find(t => t && t.slug === "highlights")!.itemDisplayOrder!
	// 		: 99
	// 	: 99;

	return aTag - bTag;
};

export const tagSortByTagItemDisplayOrder = (tagSlug: string | null) => {
	return (a: MenuItem | null, b: MenuItem | null): number => {
		const aTag = (a !== null && a.tags!.find(t => t && t.slug === tagSlug)!.itemDisplayOrder) || 99;
		const bTag = (b !== null && b.tags!.find(t => t && t.slug === tagSlug)!.itemDisplayOrder) || 99;

		// const aTag = a
		// 	? a.tags!.find(t => t && t.slug === "highlights")
		// 		? a.tags!.find(t => t && t.slug === "highlights")!.itemDisplayOrder!
		// 		: 99
		// 	: 99;
		// const bTag = b
		// 	? b.tags!.find(t => t && t.slug === "highlights")
		// 		? b.tags!.find(t => t && t.slug === "highlights")!.itemDisplayOrder!
		// 		: 99
		// 	: 99;

		return aTag - bTag;
	};
};
