import React from "react";

import { Box } from "@mui/material";
import { styled } from "@mui/system";

import { useTranslation } from "react-i18next";

import ProgressBar from "components/ProgressBar";

const StyledBox = styled(Box)`
	overflow-y: hidden;
	overflow-x: hidden;
	height: 100vh;
	margin: 0;
	padding: 0;
`;

const StyledContainer = styled(Box)`
	background-color: #fff;
	overflow-y: hidden;
	overflow-x: hidden;
	display: flex;
	flex: 1;
	flex-direction: column;
	height: calc(var(--vh, 1vh) * 90);
	flex-grow: 1;
	margin: 0;
	padding: 0;
	align-content: center;
	align-items: center;
	justify-content: center;
`;

const StyledPoweredBy = styled(Box)`
	color: #888;
	font-size: 10;
	margin-bottom: 10;
`;

const StyledLogo = styled("img")`
	align-self: center;
	justify-self: center;
	height: auto;
	width: 100px;
`;

interface Props {}

const AppLoading: React.FC<Props> = () => {
	const { t } = useTranslation();

	return (
		<StyledBox>
			<ProgressBar />

			<StyledContainer>
				<StyledContainer>
					<StyledPoweredBy>{t("poweredBy")}</StyledPoweredBy>
					<StyledLogo src={window.location.origin + "/vertical_black.png"} />
				</StyledContainer>
			</StyledContainer>
		</StyledBox>
	);
};

export default AppLoading;
