/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient, ApolloQueryResult, DefaultContext, FetchResult, Observable } from "@apollo/client";

import { GraphQLError } from "graphql";

import IGqlOperation from "api/IGqlOperation";

let _client: ApolloClient<unknown>;

export const initClient = async (apolloClient: ApolloClient<unknown>): Promise<void> => {
	_client = apolloClient;
};

export const executeQuery = async <D, V>(
	query: IGqlOperation<D, V>,
	options?: Record<"variables", V>
): Promise<ApolloQueryResult<D>> => {
	return await _client!.query<D, V>({
		query: query.text,
		...options,
		fetchPolicy: "network-only"
	} as any);
};

export const executeMutation = async <D, V, C = DefaultContext>(
	mutation: IGqlOperation<D, V>,
	options?: Record<"variables", V>
): Promise<FetchResult<D>> => {
	try {
		return await _client!.mutate<D, V, C>({ mutation: mutation.text, ...options } as any);
	} catch (error: any) {
		return { errors: [error] };
	}
};

export const executeSubscription = <D, V>(
	subscription: IGqlOperation<D, V>,
	options?: Record<"variables", V>
): Observable<FetchResult<D>> => {
	return _client!.subscribe<D, V>({ query: subscription.text, ...options } as any);
};
