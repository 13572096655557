/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { PlaceOrderStartMutation as PlaceOrderStartMutationType, PlaceOrderStartMutationVariables } from "gql/types/mutations/PlaceOrderStartMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation PlaceOrderStartMutation($placeOrderStartInput: PlaceOrderStartInput!) {
	placeOrderStart(placeOrderStartInput: $placeOrderStartInput) {
		order {
			_id
		}
		paymentIntentKey
	}
}
`;

const PlaceOrderStartMutation: IGqlOperation<PlaceOrderStartMutationType, PlaceOrderStartMutationVariables> = { text } as any;

export default PlaceOrderStartMutation;
