/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { GetCustomerAppInfoQuery as GetCustomerAppInfoQueryType, GetCustomerAppInfoQueryVariables } from "gql/types/queries/GetCustomerAppInfoQuery";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`query GetCustomerAppInfoQuery($getCustomerAppInfoInput: GetCustomerAppInfoInput!) {
	getCustomerAppInfo(getCustomerAppInfoInput: $getCustomerAppInfoInput) {
		version
	}
}
`;

const GetCustomerAppInfoQuery: IGqlOperation<GetCustomerAppInfoQueryType, GetCustomerAppInfoQueryVariables> = { text } as any;

export default GetCustomerAppInfoQuery;
