import React, { useEffect, useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { Background, Parallax } from "react-parallax";
import { useNavigate, useParams } from "react-router-dom";
import useStore from "store";

import Layout from "components/Layout";
import AppLoading from "components/Loading";

import { OrderTabFindQuery_orderTabFind } from "gql/types/queries/OrderTabFindQuery";
import useForm from "hooks/useForm";
import useLocalize from "hooks/useLocalize";

import CustomerInputPanel from "./CustomerInputPanel";
import validateCustomerInfo from "./validateCustomerInfo";

const useStyles = makeStyles(theme => {
	return {
		container: {
			position: "relative",
			display: "flex",
			height: "100%",
			flexDirection: "column",
			flexGrow: 1,
			alignItems: "center",
			margin: 0,
			padding: theme.spacing(2),
			paddingBottom: 100,
			flex: 1
		},
		root: {
			display: "flex",
			flexDirection: "column",
			padding: theme.spacing(2)
		},
		progress: {
			position: "absolute",
			top: 0,
			left: 0,
			right: 0
		},
		icon: {
			width: 48
		},
		topImgContainer: {
			backgroundRepeat: "no-repeat",
			width: "100%",
			height: 235
		},
		topImg: {
			height: 235,
			[theme.breakpoints.up("sm")]: {
				width: "auto"
			}
		},
		logoContainer: {
			position: "absolute",
			top: -65,
			justifySelf: "center",
			alignSelf: "center",
			margin: 0,
			padding: 0,
			maxHeight: 96,
			maxWidth: 96,
			marginBottom: theme.spacing(2)
		},
		logo: {
			justifySelf: "center",
			alignSelf: "center",
			margin: 0,
			padding: 0,
			height: "100%",
			width: "100%",
			marginBottom: theme.spacing(2)
		},
		title: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
			fontSize: "1.5rem"
		},
		section: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			marginBottom: theme.spacing(1),
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			padding: theme.spacing(1),
			justifyContent: "center",
			justifyItems: "center",
			alignContent: "center",
			alignItems: "center"
		},
		content: {
			display: "flex",
			flexDirection: "column",
			margin: 0,
			padding: 0,
			boxShadow: "none",
			alignContent: "center",
			justifyContent: "center",
			alignItems: "center",
			justifyItems: "center"
		},
		btn: {
			borderRadius: 20,
			marginTop: theme.spacing(1),
			textAlign: "center",
			textTransform: "uppercase"
		},
		formAction: {
			textAlign: "center",
			alignContent: "center",
			marginTop: theme.spacing(2)
		},
		instructions: {
			fontSize: 18,
			textAlign: "center"
		},
		instructions2: {
			paddingTop: theme.spacing(1),
			fontSize: 18,
			textAlign: "center"
		},
		err: {
			backgroundColor: "rgba(255,0,0,0.1)",
			border: "1px solid #f00"
		},
		link: {
			fontSize: 15,
			color: theme.palette.text.primary
		},
		hLink: {
			fontSize: 15,
			color: theme.palette.primary.main,
			fontWeight: 600
		},
		chk: {
			display: "table-cell"
		},
		reset: {
			marginTop: theme.spacing(3),
			textAlign: "center"
		},
		businessName: {
			textAlign: "center",
			width: "100%",
			margin: 0,
			padding: 0,
			paddingTop: theme.spacing(2),
			fontSize: "1.5rem",
			fontWeight: 600,
			color: theme.palette.primary.main
		},
		subTitle: {
			textAlign: "center",
			margin: theme.spacing(2),
			fontSize: "1.2rem"
		},
		subTitle2: {
			textAlign: "center",
			fontSize: "1.2rem"
		},
		formControl: {
			width: "100%",
			display: "table",
			margin: 0,
			padding: 0
		},
		text: {
			color: theme.palette.text.primary
		},
		col: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			alignItems: "center"
		},
		bottomBtnTop: {
			width: "100%",
			marginBottom: theme.spacing(2)
		},
		bottomBtn: {
			width: "100%"
		},
		bottomLink: {
			fontSize: "1rem",
			textTransform: "uppercase",
			height: 40,
			verticalAlign: "center",
			padding: theme.spacing(2)
		}
	};
});

export interface CardDetails {
	nameOnCard?: string;
	savePaymentInfo: boolean;
}

export interface TabInfo {
	name?: string;
}

interface Props {}

const TableJoin: React.FC<Props> = () => {
	const classes = useStyles();

	const { t } = useTranslation();
	const { l } = useLocalize();

	const business = useStore(store => store.business);

	const customerInfo = useStore(store => store.customerInfo);
	const table = useStore(store => store.table);
	const locale = useStore(store => store.locale);
	const orderTabFind = useStore(store => store.orderTabFind);
	const orderTabJoin = useStore(store => store.orderTabJoin);
	const orderTabOpen = useStore(store => store.orderTabOpen);
	const setCustomerInfo = useStore(store => store.setCustomerInfo);

	const setLoading = useStore(store => store.setLoading);
	const loading = useStore(store => store.loading);

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isIndependentOrder, setIsIndependentOrder] = useState(false);
	const [orderTabFindResult, setOrderTabFindResult] = useState<OrderTabFindQuery_orderTabFind>();
	const navigate = useNavigate();

	const params = useParams();

	const {
		values,
		setValue,
		errors,
		onChange,
		onSubmit: submitForm
	} = useForm<TabInfo, Partial<TabInfo>>(
		() => {},
		{
			name: customerInfo.name
		},
		values => validateCustomerInfo(values)
	);

	if (!business) {
		return <AppLoading />;
	}

	const { tableSlug, joinId } = params;

	const businessTable = business.tables?.find(t => t && t.slug === tableSlug);

	if (!businessTable) {
		throw new Error("Table not found"); // shouldn't happen
	}

	useEffect(() => {
		const lookupAsync = async () => {
			if (joinId && tableSlug) {
				const order = await orderTabFind(joinId, tableSlug);
				setOrderTabFindResult(order);
				setLoading(false);
			}
		};

		if (tableSlug) {
			setLoading(true);
			lookupAsync();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const updateAsync = async () => {
			if (values) {
				// if (isEditing) {

				// }
				setCustomerInfo({
					name: values.name
				});

				if (isIndependentOrder) {
					await orderTabOpen({ table: businessTable, name: values?.name });
				} else {
					if (joinId) {
						if (!orderTabFindResult) {
							throw new Error("Order not found");
						}
						await orderTabJoin({
							table: businessTable,
							orderId: orderTabFindResult!._id!,
							joinId,
							name: values.name
						});
					}
				}
				setLoading(false);
				navigate(`/${locale}/main`);
			}
		};

		if (isSubmitting) {
			updateAsync();
		}
	}, [
		isSubmitting,
		navigate,
		setLoading,
		businessTable,
		values,
		setCustomerInfo,
		orderTabJoin,
		joinId,
		orderTabFindResult,
		locale,
		isIndependentOrder,
		orderTabOpen
	]);

	const infoImage = business.mediaLinks?.find(m => m && m.key === "infoImage");

	const background = business.menus[0]!.defaultMedia?.mediaUrl
		? {
				backgroundSize: "contain",
				backgroundAttachment: "fixed",
				backgroundRepeat: "no-repeat",
				backgroundImageOpacity: 0.2
				// backgroundImage: `url('${infoImage?.mediaUrl || business.menus[0]!.defaultMedia?.mediaUrl}')`
		  }
		: {};

	const infoLogo = business.mediaLinks?.find(m => m && m.key === "infoLogo");

	const onCustomerInfoSubmit = isValid => {
		if (!isValid) {
			setIsSubmitting(false);
			setLoading(false);
		}
	};

	const onFormSubmit = async e => {
		e.preventDefault();
		console.log("onFormSubmit e.nativeEvent.submitter.name", e.nativeEvent.submitter.name);

		if (!isSubmitting) {
			if (e.nativeEvent.submitter.name === "join") {
				setIsIndependentOrder(false);
			} else {
				setIsIndependentOrder(true);
			}
			setLoading(true);
			setIsSubmitting(true);
			setValue({
				...values
			});
			submitForm(e);
		}
	};

	const dontJoin = async () => {
		setLoading(true);
	};

	return (
		<Layout
			businessName={business.name}
			pageTitle={t("dineInSetup")}
			onSubmit={onFormSubmit}
			bottom={
				<Box className={classes.col}>
					<Button
						variant={"contained"}
						color="secondary"
						className={classes.bottomBtn}
						type="submit"
						name="join"
						disabled={loading}
					>
						{orderTabFindResult?.tabHost ? `${t("join")} ${orderTabFindResult?.tabHost?.name}` : t("join")}
					</Button>
					{!loading && (
						<Button className={classes.bottomLink} type="submit" name={"startNewOrder"}>
							{t("startNewOrder")}
						</Button>
					)}
					{/* <Button variant={"contained"} color="secondary" className={classes.bottomBtn} type="submit">
						{t("startOrdering")}
					</Button> */}
				</Box>
			}
		>
			<Box>
				<Parallax
					className={classes.topImgContainer}
					bgImage={infoImage?.mediaUrl || business.menus[0]!.defaultMedia?.mediaUrl}
					strength={300}
					bgImageStyle={background}
				>
					<Background className={classes.topImg}>
						{infoImage && (
							<img
								className={classes.topImg}
								src={infoImage.mediaUrl || business.menus[0]!.defaultMedia?.mediaUrl}
								alt={t("info")}
							/>
						)}
					</Background>
				</Parallax>

				<Box className={classes.container}>
					{infoLogo && (
						<motion.div
							className={classes.logoContainer}
							initial={{ opacity: 0, height: 0, width: 0 }}
							animate={{ opacity: 1, height: 96, width: 96 }}
							transition={{ duration: 0.25, ease: "easeInOut" }}
						>
							<img src={infoLogo.mediaUrl} alt={t("logo")} className={classes.logo} />
						</motion.div>
					)}
					<Box className={classes.section}>
						<Typography className={clsx(classes.businessName)}>{l(business.localeNames)}</Typography>
						<Typography className={clsx(classes.subTitle)}>
							{t("tableWithName", { name: businessTable.name })}
						</Typography>

						{loading && !isSubmitting ? (
							<Typography className={clsx(classes.title)}>{t("searching")}</Typography>
						) : (
							<>
								{orderTabFindResult?.tabHost?.name && (
									<Box className={classes.col}>
										<Typography className={clsx(classes.subTitle2)}>{t("toJoin")}</Typography>
										<Typography className={clsx(classes.title)}>{orderTabFindResult?.tabHost?.name}</Typography>
									</Box>
								)}
								<Typography className={clsx(classes.subTitle2)}>{t("getTabStartedStep2")}</Typography>
								<CustomerInputPanel
									name={values!.name}
									// firstName={values!.firstName}
									// lastName={values!.lastName}
									errors={errors}
									onChange={onChange}
									onSubmit={onCustomerInfoSubmit}
								/>
							</>
						)}
					</Box>
				</Box>
			</Box>
		</Layout>
	);
};

export default TableJoin;
