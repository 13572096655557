import React from "react";

import LinearProgress from "@mui/material/LinearProgress";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";

const useStyles = makeStyles(() => {
	return {
		progress: {
			width: "100vw"
		}
	};
});

interface Props {
	className?: string;
}

const ProgressBar: React.FC<Props> = ({ className }) => {
	const classes = useStyles();

	return (
		<LinearProgress
			className={clsx(classes.progress, className)}
			color={"secondary"}
			role={"progressbar"}
			aria-label={"loading"}
			aria-busy={"true"}
		/>
	);
};

export default ProgressBar;
