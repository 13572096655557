import { DateTime } from "luxon";

import { DaysOfWeek } from "gql/types/globals";

import {
	BusinessQuery_business_orderingHours,
	BusinessQuery_business_orderingHours_scheduleExceptions_periods
} from "gql/types/queries/BusinessQuery";

import getScheduleExceptions from "./getScheduleExceptions";

const getSchedulePeriods = (
	orderTime: DateTime,
	orderingHours: BusinessQuery_business_orderingHours,
	timeZone: string
): (BusinessQuery_business_orderingHours_scheduleExceptions_periods | null)[] | null | undefined => {
	const scheduleExceptions = getScheduleExceptions(orderTime, orderingHours, timeZone);
	if (scheduleExceptions && scheduleExceptions.length === 1 && scheduleExceptions[0]?.periods) {
		return scheduleExceptions[0]?.periods;
	}
	const dayOfWeek = DaysOfWeek[orderTime.weekdayShort.toLowerCase()];
	const schedPeriods = orderingHours.schedulePeriods?.filter(p => p?.days.includes(dayOfWeek));
	if (schedPeriods && schedPeriods.length === 1) {
		return schedPeriods[0]?.periods;
	}
	return null;
};

export default getSchedulePeriods;
