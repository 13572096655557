/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { OrderTabLeaveMutation as OrderTabLeaveMutationType, OrderTabLeaveMutationVariables } from "gql/types/mutations/OrderTabLeaveMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation OrderTabLeaveMutation($orderTabLeaveInput: OrderTabLeaveInput!) {
	orderTabLeave(orderTabLeaveInput: $orderTabLeaveInput) {
		_id
		orderStatus
		localId
	}
}
`;

const OrderTabLeaveMutation: IGqlOperation<OrderTabLeaveMutationType, OrderTabLeaveMutationVariables> = { text } as any;

export default OrderTabLeaveMutation;
