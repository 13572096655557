import useStore from "store";

// translations are optional

const useLocalize = (): {
	l: (translations: Record<string, string> | null, defaultValue?: string) => string;
	localize: (translations: Record<string, string> | null, defaultValue?: string) => string;
} => {
	const locale = useStore(state => state.locale);

	const localize = (translations: null | Record<string, string>, defaultValue?: string): string => {
		if (!translations) {
			return defaultValue ? defaultValue : "";
		}

		if (locale) {
			if (translations && translations[locale]) {
				return translations[locale];
			}
		}

		if (defaultValue !== undefined && defaultValue !== null) {
			return defaultValue;
		}

		if (translations && "en" in translations) {
			return translations.en;
		}

		return translations[Object.keys(translations)[0]];
	};

	return {
		localize,
		l: localize
	};
};

export default useLocalize;
