/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { RecentOrdersQuery as RecentOrdersQueryType, RecentOrdersQueryVariables } from "gql/types/queries/RecentOrdersQuery";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`query RecentOrdersQuery($recentOrdersInput: RecentOrdersInput!) {
	recentOrders(recentOrdersInput: $recentOrdersInput) {
		_id
		orderStatus
		orderFulfillmentType {
			orderFulfillmentMethod
			priceCents
		}
		orderNumber
		orderSubTotalCents
		taxCents
		tipCents
		tipPercentage
		deliveryFeeCents
		orderTotalCents
		taxRate

		orderNote
		deliveryInstructions

		orderPlacedAt

		taxes {
			type
			taxRate
			taxCents
		}

		orderItems {
			_id
			localId
			quantity
			unitPriceCents
			extendedPriceCents
			specialInstructions
			isUnavailable
			cancellationReasons

			menuItem {
				_id
				itemCode
				name
				localeNames
				localeDescriptions
				priceCents
				displayPrice
				isTaxable
				isAlcoholicBeverage
				mediaLinks {
					mediaUrl
					mediaType
				}
			}

			selectedMenuItemModifiers {
				_id
				name
				localeNames
				priceCents
				displayPrice
			}
		}
	}
}
`;

const RecentOrdersQuery: IGqlOperation<RecentOrdersQueryType, RecentOrdersQueryVariables> = { text } as any;

export default RecentOrdersQuery;
