import React, { ChangeEvent, useEffect, useMemo, useState } from "react";

import { Box, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import useStore from "store";

import Address from "api/types/Address";
import Business from "api/types/Business";
import OrderFulfillmentType from "api/types/OrderFulfillmentType";
import PlacesAutocomplete from "components/PlacesAutocomplete";
import useLocalize from "hooks/useLocalize";

const useStyles = makeStyles(theme => {
	return {
		content: {
			minHeight: "180px",
			display: "block"
		},
		row: {
			display: "flex",
			flexDirection: "row"
		},
		textField: {
			marginTop: theme.spacing(1),
			borderColor: "#fff",
			color: "#000",
			borderRadius: 8
		},
		textInput: {
			marginTop: theme.spacing(1)
		},
		textAreaInput: {
			marginTop: theme.spacing(2.5),
			marginBottom: theme.spacing(3)
		}
	};
});

interface Props {
	business: Business;
	orderFulfillmentType: OrderFulfillmentType;
	onAddressChanged: (hasValue: boolean) => void;
	isSubmitting: boolean;
}

const DeliveryInfo: React.FC<Props> = ({ business, orderFulfillmentType, isSubmitting, onAddressChanged }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { l } = useLocalize();

	const orderDeliveryAddress = useStore(state => state.orderDeliveryAddress);
	const orderDeliveryInstructions = useStore(state => state.orderDeliveryInstructions);
	const setOrderDeliveryAddress = useStore(action => action.setOrderDeliveryAddress);
	const setOrderDeliveryInstructions = useStore(action => action.setOrderDeliveryInstructions);

	const [deliveryInstructions, setDeliveryInstructions] = useState(
		orderDeliveryInstructions ? orderDeliveryInstructions : ""
	);
	const [deliveryAddress, setDeliveryAddress] = useState<Partial<Address> | null>(orderDeliveryAddress);
	const [deliveryAddress2, setDeliveryAddress2] = useState(deliveryAddress ? deliveryAddress.address2 : "");

	const onAddress2Changed = useMemo(
		() => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
			setDeliveryAddress2(event.target.value);
		},
		[setDeliveryAddress2]
	);

	const onInstructionChanged = useMemo(
		() => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
			setDeliveryInstructions(event.target.value);
		},
		[setDeliveryInstructions]
	);

	useEffect(() => {
		if (isSubmitting) {
			const addr = deliveryAddress;
			if (deliveryAddress2 !== "") {
				addr!.address2 = deliveryAddress2;
			}
			setOrderDeliveryAddress(addr as Address);
			setOrderDeliveryInstructions(deliveryInstructions);
		}
	}, [
		isSubmitting,
		setOrderDeliveryAddress,
		setOrderDeliveryInstructions,
		deliveryAddress,
		deliveryAddress2,
		deliveryInstructions
	]);

	const helperText = orderFulfillmentType?.localeDeliveryAddressHelperText
		? l(orderFulfillmentType?.localeDeliveryAddressHelperText)
		: "";

	const placesChanged = (event, value) => {
		setDeliveryAddress(
			value && {
				address1: value.structured_formatting.main_text,
				// address2: null,
				city: value.structured_formatting.secondary_text,
				state: null,
				postalCode: null,
				country: null,
				placeId: value.place_id,
				place: value
			}
		);
		onAddressChanged(value !== null);
	};

	return (
		<Box className={classes.content}>
			<PlacesAutocomplete
				className={classes.textField}
				placeholder={t("deliveryAddress")}
				helperText={helperText}
				cities={orderFulfillmentType?.deliveryCities as Address[]}
				defaultValue={deliveryAddress?.place || null}
				origin={business?.address?.gpsCoordinates}
				radius={orderFulfillmentType?.deliveryRadiusMeters}
				filterRadius={orderFulfillmentType?.deliveryFilterRadiusMeters}
				onChange={placesChanged}
			/>
			<TextField
				className={classes.textInput}
				fullWidth
				placeholder={t("apartmentUnit")}
				value={deliveryAddress2}
				onChange={onAddress2Changed}
				inputProps={{
					maxLength: 200
				}}
			/>
			<TextField
				className={classes.textAreaInput}
				fullWidth
				placeholder={t("deliveryInstructions")}
				value={deliveryInstructions}
				onChange={onInstructionChanged}
				multiline
				inputProps={{
					maxLength: 200
				}}
				maxRows={4}
			/>
		</Box>
	);
};

export default DeliveryInfo;
