/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { BusinessQuery as BusinessQueryType, BusinessQueryVariables } from "gql/types/queries/BusinessQuery";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`query BusinessQuery($businessInput: BusinessQueryInput!) {
	business(businessInput: $businessInput) {
		_id
		name
		locales
		localeNames
		localeShortNames
		localeLocationNames
		slug

		landingLogoUrl
		logoUrl
		logoUrl2
		faviconUrl
		theme
		styles

		isOrderingEnabled
		orderAheadMaxDays

		paymentMethods

		menuTags {
			tag
			localeTags
			localeDescriptions
			slug
			count
		}

		tags {
			tag
			slug
			count
			displayOrder
			itemDisplayOrder
		}
		homeTags {
			tag
			slug
			count
		}
		defaultTagSlug

		currency

		taxRule

		taxes {
			type
			taxRate
		}

		tipPercentages
		additionalTipPercentages
		defaultTipPercentage
		defaultDeliveryTipPercentage

		stripeAccountId

		settings {
			isPromotionsEnabled
			isEmojiTipEnabled

			isAutomaticGratuityEnabled
			automaticGratuityPartySize
			automaticGratuityPercentage
			automaticGratuityCheckTotalCents
			
			tipMessage

			tipEmoji {
				start
				end
				emoji
			}
		}

		mediaLinks {
			mediaUrl
			mediaType
			key
		}

		externalLinks {
			url
			name
		}

		mapUrl

		address {
			address1
			address2
			city
			state
			postalCode
			country
			gpsCoordinates {
				latitude
				longitude
			}
			placeId
			place
		}

		phoneNumber
		displayPhoneNumber

		timeZone
		displayBusinessHours

		orderingIntervalMinutes

		tables {
			_id
			slug
			name
			localeNames
		}

		orderingHours {
			schedulePeriods {
				days
				periods {
					isAllDay
					startTime
					endTime
				}
			}
			scheduleExceptions {
				name
				localeNames
				date
				periods {
					startTime
					endTime
					isAllDay
				}
				startsAt
				endsAt
			}
		}

		orderFulfillmentTypes {
			_id
			name
			orderFulfillmentMethod
			deliveryRadiusMeters
			defaultTipPercentage
			minPrepTimeMinutes
			maxPrepTimeMinutes
			defaultDeliveryTimeMinutes
			deliveryRadiusMeters
			deliveryFilterRadiusMeters
			deliveryCities {
				city
				state
				country
			}
			localeDeliveryAddressHelperText
			priceCents
			isTaxable
			useIncludeUtensils
			isEmojiTipEnabled
		}

		globalMenuItemModifierGroups {
			_id
			name
			localeNames
			requiredMinimum
			requiredMaximum
			menuItemModifiers {
				_id
				name
				localeNames
				itemCode
				tags {
					tag
					slug
					count
				}
				localeDescriptions
				priceCents
				displayPrice
				prepTimeMinutes
				isDefault
			}
		}

		menus {
			_id
			slug
			name
			localeNames
			defaultMedia {
				mediaUrl
				mediaType
			}

			menuItems {
				_id
				itemCode
				name
				localeNames
				slug
				localeDescriptions
				disableGlobalMenuItemModifierGroups
				tags {
					tag
					slug
					displayOrder
					itemDisplayOrder
					count
				}
				priceCents
				displayPrice
				displayOrder
				isTaxable
				isAlcoholicBeverage
				isUnAvailable

				prepTimeMinutes
				mediaLinks {
					mediaUrl
					mediaType
				}

				availability {
					schedulePeriods {
						days
						periods {
							startTime
							endTime
						}
					}
					scheduleExceptions {
						name
						localeNames
						date
						periods {
							startTime
							endTime
							isAllDay
						}
						startsAt
						endsAt
					}
				}

				menuItemModifierGroups {
					_id
					name
					localeNames
					requiredMinimum
					requiredMaximum
					menuItemModifiers {
						_id
						name
						localeNames
						itemCode
						tags {
							tag
							slug
							count
						}
						localeDescriptions
						priceCents
						displayPrice
						prepTimeMinutes
						isDefault
					}
				}
			}
		}
	}
}
`;

const BusinessQuery: IGqlOperation<BusinessQueryType, BusinessQueryVariables> = { text } as any;

export default BusinessQuery;
