/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { MenuItemFavoritesQuery as MenuItemFavoritesQueryType, MenuItemFavoritesQueryVariables } from "gql/types/queries/MenuItemFavoritesQuery";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`query MenuItemFavoritesQuery($getMenuItemFavoritesInput: GetMenuItemFavoritesInput!) {
	getMenuItemFavorites(getMenuItemFavoritesInput: $getMenuItemFavoritesInput) {
		menuItemId
	}
}
`;

const MenuItemFavoritesQuery: IGqlOperation<MenuItemFavoritesQueryType, MenuItemFavoritesQueryVariables> = { text } as any;

export default MenuItemFavoritesQuery;
