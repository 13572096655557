import React, { useEffect, useState } from "react";

import { Box, Button, Link, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useStore from "store";

import BusinessInfoHeader from "components/BusinessInfoHeader";
import BusinessLogoBadge from "components/BusinessLogoBadge";
import Layout from "components/Layout";
import AppLoading from "components/Loading";
import QrCodeScanner from "components/QrCodeScanner";
import { ParsedScanResult } from "components/QrCodeScanner/QrCodeScanner";
import Redirect from "components/Redirect";
import TableQrCode from "components/TableQrCode/TableQrCode";

import useLocalize from "hooks/useLocalize";

const useStyles = makeStyles(theme => {
	return {
		container: {
			minHeight: "100vh",
			position: "relative",
			display: "flex",
			height: "100%",
			flexDirection: "column",
			flexGrow: 1,
			alignItems: "center",
			margin: 0,
			padding: theme.spacing(2),
			paddingTop: 10,
			paddingBottom: 100,
			objectFit: "cover",
			flex: 1
		},
		progress: {
			position: "absolute",
			top: 0,
			left: 0,
			right: 0
		},
		icon: {
			width: 48
		},
		title: {
			marginTop: theme.spacing(1),
			fontSize: "1.5rem"
		},
		section: {
			display: "flex",
			flexDirection: "column",
			marginBottom: theme.spacing(1),
			padding: theme.spacing(1),
			alignContent: "center",
			alignItems: "center"
		},
		btn: {
			borderRadius: 20,
			marginTop: theme.spacing(1),
			textAlign: "center",
			textTransform: "uppercase"
		},
		instructions: {
			fontSize: 18,
			textAlign: "center"
		},
		instructions2: {
			paddingTop: theme.spacing(1),
			fontSize: 18,
			textAlign: "center"
		},
		err: {
			backgroundColor: "rgba(255,0,0,0.1)",
			border: "1px solid #f00"
		},
		link: {
			fontSize: 15,
			color: theme.palette.text.primary
		},
		hLink: {
			fontSize: 15,
			color: theme.palette.primary.main,
			fontWeight: 600
		},
		chk: {
			display: "table-cell"
		},
		reset: {
			marginTop: theme.spacing(3),
			textAlign: "center"
		},
		businessName: {
			textAlign: "center",
			width: "100%",
			margin: 0,
			padding: 0,
			paddingTop: theme.spacing(2),
			fontSize: "1.5rem",
			fontWeight: 600,
			color: theme.palette.primary.main
		},
		subTitle: {
			textAlign: "center",
			margin: theme.spacing(1),
			fontSize: "1.2rem"
		},
		formControl: {
			width: "100%",
			display: "table",
			margin: 0,
			padding: 0
		},
		text: {
			color: theme.palette.text.primary
		},
		col: {
			display: "flex",
			flexDirection: "column",
			width: "100%"
		},
		scannerContainer: {
			paddingTop: theme.spacing(2),
			minHeight: 300,
			width: "100%",
			margin: 0
		},
		bottomBtn: {
			height: 40,
			fontSize: "1rem",
			width: "100%"
		},
		bottomLink: {
			alignSelf: "center",
			fontSize: "1rem",
			textTransform: "uppercase",
			height: 40,
			verticalAlign: "center",
			padding: theme.spacing(2),
			maxWidth: 600,
			width: "100%"
		},
		bottomBtn2: {
			alignSelf: "center",
			height: 40,
			fontSize: "1rem",
			marginTop: theme.spacing(2),
			maxWidth: 600,
			width: "100%"
		}
	};
});

export interface CardDetails {
	nameOnCard?: string;
	savePaymentInfo: boolean;
}

interface Props {}

const Table: React.FC<Props> = () => {
	const classes = useStyles();

	const { t } = useTranslation();
	const { l } = useLocalize();

	const business = useStore(store => store.business);

	const customerInfo = useStore(store => store.customerInfo);
	const table = useStore(store => store.table);
	const locale = useStore(store => store.locale);
	const setLoading = useStore(store => store.setLoading);
	const newOrder = useStore(store => store.newOrder);
	const orderTabFind = useStore(store => store.orderTabFind);
	const orderTabJoin = useStore(store => store.orderTabJoin);
	const orderTabOpen = useStore(store => store.orderTabOpen);

	const [isScanningEnabled, setIsScanningEnabled] = useState(false);
	const [isProcessingScannedResult, setIsProcessingScannedResult] = useState(false);

	const navigate = useNavigate();

	const params = useParams();

	if (!business) {
		return <AppLoading />;
	}

	const tableSlug = params.tableSlug;
	const businessTable = business.tables?.find(t => t && t.slug === tableSlug);

	if (!businessTable) {
		throw new Error(`Table not found: '${tableSlug}'`); // shouldn't happen
	}

	useEffect(() => {
		const openTableAsync = async () => {
			await orderTabOpen({
				table: businessTable,
				name: customerInfo.name
			});
			setLoading(false);
		};
		if (customerInfo.name) {
			// open a tab
			if (!table || table._id !== businessTable._id) {
				setLoading(true);
				openTableAsync();
			}
		} else {
			console.warn("empty customerInfo.name");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [businessTable, table]);

	if (!table || table.slug !== tableSlug) {
		if (!customerInfo.name || customerInfo.name.trim().length === 0) {
			return <Redirect to={`table/${tableSlug}/customerInfo`} />;
		}
	}

	const joinAnotherOrderClicked = () => {
		setIsScanningEnabled(isScanningEnabled ? false : true);
	};

	const startOrderingClicked = () => {
		navigate(`/${locale}/main`);
	};

	const onScanned = async (result: ParsedScanResult) => {
		setIsScanningEnabled(false);
		setLoading(true);
		setIsProcessingScannedResult(true);
		if (result) {
			const joinTable = business.tables?.find(t => t && t.slug === result.tableSlug);
			if (joinTable) {
				if (result.isJoinExisting) {
					const order = await orderTabFind(result.joinId!, result.tableSlug);
					if (joinTable && order) {
						await orderTabJoin({
							table: joinTable,
							orderId: order!._id!,
							joinId: result.joinId!,
							name: customerInfo.name
						});
						navigate(`/${locale}/main`);
					}
				} else {
					setIsProcessingScannedResult(false);
					navigate(`/${locale}/table/${joinTable.slug}`);
					// await orderTabOpen({
					// 	table: joinTable,
					// 	name: customerInfo.name
					// });
				}
			}
		}
	};

	const orderAloneClicked = () => {
		newOrder(true);
		navigate(`/${locale}/main`);
	};

	return (
		<Layout
			businessName={business.name}
			pageTitle={t("dineInSetup")}
			bottom={
				<Stack direction={"column"} sx={{ width: "100%" }}>
					{!isScanningEnabled && (
						<>
							<Button
								variant={"contained"}
								color="secondary"
								className={classes.bottomBtn2}
								disabled={isProcessingScannedResult}
								onClick={startOrderingClicked}
							>
								{t("startOrdering")}
							</Button>
						</>
					)}

					<Link className={classes.bottomLink} onClick={joinAnotherOrderClicked}>
						{isScanningEnabled ? t("cancel") : t("rescan")}
					</Link>
				</Stack>
			}
		>
			<BusinessInfoHeader />

			<Box className={classes.container}>
				<BusinessLogoBadge />
				<Box className={classes.section}>
					<Typography className={clsx(classes.businessName)}>{l(business.localeNames)}</Typography>
					<Typography className={clsx(classes.subTitle)}>{t("tableWithName", { name: businessTable.name })}</Typography>

					{isScanningEnabled ? (
						!isProcessingScannedResult ? (
							<>
								<Typography className={clsx(classes.instructions)}>{t("scanPhoneQrCode")}</Typography>
								<Box className={classes.scannerContainer}>
									<QrCodeScanner onScanned={onScanned} />
								</Box>
							</>
						) : (
							<Typography className={clsx(classes.instructions2)}>{t("joining")}</Typography>
						)
					) : (
						<>
							<Typography className={clsx(classes.title)}>
								{customerInfo.name ? t("welcomeCustomer", { name: customerInfo.name }) : t("welcome")}
							</Typography>
							{tableSlug && <TableQrCode businessName={business.name} tableSlug={tableSlug} />}
						</>
					)}
				</Box>
			</Box>
		</Layout>
	);
};

export default Table;
