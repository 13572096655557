/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { OrderTabOpenMutation as OrderTabOpenMutationType, OrderTabOpenMutationVariables } from "gql/types/mutations/OrderTabOpenMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation OrderTabOpenMutation($orderTabOpenInput: OrderTabOpenInput!) {
	orderTabOpen(orderTabOpenInput: $orderTabOpenInput) {
		_id
		orderTabStatus
	}
}
`;

const OrderTabOpenMutation: IGqlOperation<OrderTabOpenMutationType, OrderTabOpenMutationVariables> = { text } as any;

export default OrderTabOpenMutation;
