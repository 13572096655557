import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";

import { Box, Checkbox, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import MenuItemModifierGroupType from "api/types/MenuItemModifierGroup";
import Price from "components/Price/Price";

import useLocalize from "hooks/useLocalize";

import SelectedModifier from "./SelectedModifier";

const useStyles = makeStyles(theme => {
	return {
		optionGroupName: {
			textTransform: "uppercase",
			paddingBottom: theme.spacing(1),
			[theme.breakpoints.up(480)]: {
				fontWeight: "500"
			}
		},
		radioGroup: {
			display: "flex",
			flex: 1,
			padding: 0,
			marginBottom: theme.spacing(1),
			flexFlow: "column",
			width: "calc(var(--vw, 1vw) * 100 - 32px)", // not great..
			[theme.breakpoints.up(480)]: {
				width: "100%",
				color: theme.palette.text.primary
			}
		},
		radio: {
			color: theme.palette.text.primary,
			borderColor: "#fff"
		},
		itemContent: {
			display: "flex",
			flexDirection: "row"
		},
		itemCode: {
			textAlign: "left",
			marginRight: theme.spacing(1)
		},
		itemName: {
			textAlign: "left"
		},
		option: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(1),
			borderTop: "1px solid #bbb",
			fontSize: 13,
			color: theme.palette.text.primary,
			alignContent: "center",
			alignItems: "center",
			display: "flex",
			justifyContent: "space-between",
			[theme.breakpoints.up(480)]: {
				flexDirection: "row-reverse",
				width: "100%",
				color: theme.palette.text.primary,
				paddingLeft: 0
			}
		},
		optionContainer: {
			textTransform: "uppercase",
			width: "100%",
			display: "flex",
			alignContent: "center",
			alignItems: "center",
			flex: 1,
			justifyContent: "space-between"
		}
	};
});

interface Props {
	menuItemModifierGroup: MenuItemModifierGroupType;

	defaultValue?: (SelectedModifier | null)[];

	onChange?(event: { menuItemModifierGroup: MenuItemModifierGroupType; selection: SelectedModifier[] | null }): void;
}

const MenuItemModifierGroup: React.FC<Props> = ({ menuItemModifierGroup, defaultValue, onChange }) => {
	const classes = useStyles();
	const { l } = useLocalize();
	const refValue = useRef<string[] | null>(null);

	const [value, setValue] = useState<null | string[]>(null);

	// useEffect(() => {
	// 	console.log(value, defaultValue);
	// 	if (!value && defaultValue?.length === 0) {
	// 		console.log("check if the menuItemModifiers have a default item");
	// 		// check if the menuItemModifiers have a default item
	// 		const defaultModifier = menuItemModifierGroup.menuItemModifiers?.find(i => i?.isDefault);
	// 		console.log("defaultModifier", defaultModifier);
	// 		if (defaultModifier) {
	// 			setValue([defaultModifier._id]);
	// 		}
	// 	}
	// }, []);

	useEffect(() => {
		const item =
			menuItemModifierGroup
				.menuItemModifiers!.filter(x =>
					defaultValue?.find(
						y => y && y.menuItemModifierGroupId === menuItemModifierGroup._id && y.menuItemModifierId === x!._id
					)
				)
				?.map(a => a!._id) || null;
		if (item) {
			setValue(item);
			refValue.current = item;
		}
	}, [defaultValue, menuItemModifierGroup._id, menuItemModifierGroup.menuItemModifiers, setValue]);

	const handleChange = useMemo(
		() => (event: ChangeEvent<HTMLInputElement>) => {
			const selectedValue = event.currentTarget.value;
			const option = menuItemModifierGroup.menuItemModifiers!.find(x => x?._id === selectedValue);

			setValue(option ? [option._id] : null);
			refValue.current = option ? [option._id] : null;

			onChange &&
				onChange({
					menuItemModifierGroup,
					selection: option
						? [
								{
									menuItemModifierGroupId: menuItemModifierGroup._id!,
									menuItemModifierId: option._id!,
									priceCents: option.priceCents || undefined,
									displayPrice: option.displayPrice || undefined,
									prepTimeMinutes: option.prepTimeMinutes || undefined
								}
						  ]
						: null
				});
		},
		[menuItemModifierGroup, onChange]
	);

	const handleCheckboxChange = (selectedValue: string) => () => {
		let values: string[] = value || [];
		if (values?.find(a => a === selectedValue)) {
			values = values?.filter(a => a != selectedValue);
		} else {
			values = [...values, selectedValue];
		}
		setValue(values);
		const options = menuItemModifierGroup
			.menuItemModifiers!.filter(x => values.find(a => a === x!._id))
			.map(option => {
				return {
					menuItemModifierGroupId: menuItemModifierGroup._id!,
					menuItemModifierId: option!._id,
					priceCents: option!.priceCents || undefined,
					displayPrice: option!.displayPrice || undefined,
					prepTimeMinutes: option!.prepTimeMinutes || undefined
				};
			});
		onChange &&
			onChange({
				menuItemModifierGroup,
				selection: options
			});
	};

	if (menuItemModifierGroup?.requiredMinimum === 1 && menuItemModifierGroup?.requiredMaximum === 1) {
		// radio
		return (
			<RadioGroup className={classes.radioGroup} aria-label="option" value={value} onChange={handleChange}>
				<Typography className={classes.optionGroupName}>
					{l(menuItemModifierGroup.localeNames, menuItemModifierGroup.name!)}
				</Typography>
				{menuItemModifierGroup.menuItemModifiers!.map(
					o =>
						o && (
							<FormLabel className={classes.option} key={o!._id}>
								<Box className={classes.optionContainer}>
									<Box className={classes.itemContent}>
										{o.itemCode && <Box className={classes.itemCode}>{o.itemCode}</Box>}

										<Box className={classes.itemName}>{l(o.localeNames, o.name!)}</Box>
									</Box>
									{o.priceCents && <Price priceCents={o.priceCents} displayPrice={o.displayPrice} />}
								</Box>

								<Radio
									inputProps={{ "aria-label": o.name }}
									classes={{ root: classes.radio }}
									value={o._id}
									checked={!!value?.find(a => a === o._id)}
									color="secondary"
								/>
							</FormLabel>
						)
				)}
			</RadioGroup>
		);
	} else {
		// checkboxes
		return (
			<Box className={classes.radioGroup} aria-label="option">
				<Typography className={classes.optionGroupName}>{menuItemModifierGroup.name}</Typography>
				{menuItemModifierGroup.menuItemModifiers &&
					menuItemModifierGroup.menuItemModifiers.map(
						o =>
							o && (
								<FormLabel className={classes.option} key={o._id}>
									<Box className={classes.optionContainer}>
										<Box className={classes.itemContent}>
											{o.itemCode && <Box className={classes.itemCode}>{o.itemCode}</Box>}

											<Box className={classes.itemName}>{l(o.localeNames, o.name)}</Box>
										</Box>
										{o.priceCents && <Price priceCents={o.priceCents} displayPrice={o.displayPrice} />}
									</Box>
									<Checkbox
										inputProps={{ "aria-label": l(o.localeNames, o.name) }}
										classes={{ root: classes.radio }}
										checked={!!value?.find(a => a === o._id)}
										value={o._id}
										color="secondary"
										onChange={handleCheckboxChange(o._id)}
									/>
								</FormLabel>
							)
					)}
			</Box>
		);
	}
};

export default MenuItemModifierGroup;
