import React from "react";

import Button from "@mui/material/IconButton";
import withStyles from "@mui/styles/withStyles";

const styles = theme => ({
	button: {
		backgroundColor: theme.palette.primary.main,
		"&:hover": {
			backgroundColor: theme.palette.primary.main
		}
	},
	child: {
		backgroundColor: theme.palette.primary.dark
	},
	rippleVisible: {
		opacity: 0.5,
		animation: `$enter 550ms ${theme.transitions.easing.easeInOut}`
	},
	"@keyframes enter": {
		"0%": {
			transform: "scale(0)",
			opacity: 0.1
		},
		"100%": {
			transform: "scale(1)",
			opacity: 0.5
		}
	}
});

interface Props {
	classes?: any;
	className?: string;
	size?: "medium" | "small";
	color?: "primary" | "secondary";
	onClick?: () => void;
}

const IconButton2: React.FC<Props> = ({ classes, className, size = "medium", onClick, ...other }) => {
	const { button: buttonClass, ...rippleClasses } = classes;
	return (
		<Button
			TouchRippleProps={{ classes: rippleClasses }}
			className={className}
			size={size}
			onClick={onClick}
			{...other}
		/>
	);
};

export default withStyles(styles)(IconButton2);
