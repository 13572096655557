import React from "react";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";

import { BusinessQuery_business_externalLinks } from "gql/types/queries/BusinessQuery";
import YelpIcon from "icons/YelpIcon";

const useStyles = makeStyles(theme => {
	return {
		extLink: {
			padding: theme.spacing(2)
		}
	};
});

interface Props {
	className: string;
	externalLinks?: (BusinessQuery_business_externalLinks | null)[] | null;
}

const ExternalLinks: React.FC<Props> = ({ className, externalLinks }) => {
	const classes = useStyles();

	return (
		<>
			{externalLinks?.map(
				l =>
					l && (
						<Link key={`link-${l.name}`} href={l?.url} target="_blank" className={clsx(classes.extLink, className)}>
							{l.name === "facebook" ? (
								<FacebookIcon />
							) : l.name === "instagram" ? (
								<InstagramIcon />
							) : l.name === "twitter" ? (
								<TwitterIcon />
							) : l.name === "yelp" ? (
								<YelpIcon />
							) : null}
						</Link>
					)
			)}
		</>
	);
};

export default ExternalLinks;
