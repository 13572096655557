/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { PlaceOrderCompleteMutation as PlaceOrderCompleteMutationType, PlaceOrderCompleteMutationVariables } from "gql/types/mutations/PlaceOrderCompleteMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation PlaceOrderCompleteMutation($placeOrderCompleteInput: PlaceOrderCompleteInput!) {
	placeOrderComplete(placeOrderCompleteInput: $placeOrderCompleteInput) {
		_id
	}
}
`;

const PlaceOrderCompleteMutation: IGqlOperation<PlaceOrderCompleteMutationType, PlaceOrderCompleteMutationVariables> = { text } as any;

export default PlaceOrderCompleteMutation;
