import React, { useEffect, useMemo, useRef } from "react";

import _ from "lodash";

import MenuItemModifierGroupType from "api/types/MenuItemModifierGroup";

import { BusinessQuery_business_menus_menuItems_menuItemModifierGroups } from "gql/types/queries/BusinessQuery";

import MenuItemModifierGroup from "./MenuItemModifierGroup";
import SelectedModifier from "./SelectedModifier";

interface MenuItemModifierGroupsProps {
	menuItemModifierGroups: (BusinessQuery_business_menus_menuItems_menuItemModifierGroups | null)[];

	defaultValue?: (SelectedModifier | null)[];

	onChange?(event: { value: SelectedModifier[] }): void;
}

const MenuItemModifierGroups = ({ menuItemModifierGroups, defaultValue, onChange }: MenuItemModifierGroupsProps) => {
	const refValue = useRef<(SelectedModifier | null)[] | [] | undefined>(defaultValue || []);

	useEffect(() => {
		refValue.current = defaultValue;
	}, [defaultValue]);

	const handleChange = useMemo(
		() =>
			({
				menuItemModifierGroup,
				selection
			}: {
				menuItemModifierGroup: MenuItemModifierGroupType;
				selection: SelectedModifier[] | null;
			}) => {
				let values: SelectedModifier[] =
					refValue.current && refValue.current.length > 0
						? refValue.current.map(r => (r.menuItemModifierGroupId === menuItemModifierGroup._id ? selection : r))
						: selection
						? selection
						: [];
				values = _.uniqBy(_.flatten(values), "menuItemModifierId");

				if (selection && !values.find(v => v && v.menuItemModifierId === selection[0].menuItemModifierId)) {
					values.push(...selection);
				}

				onChange && onChange({ value: values });
			},
		[onChange]
	);

	return (
		<>
			{menuItemModifierGroups.map(menuItemModifierGroup => (
				<MenuItemModifierGroup
					key={menuItemModifierGroup!._id}
					menuItemModifierGroup={menuItemModifierGroup!}
					defaultValue={defaultValue}
					onChange={handleChange}
				/>
			))}
		</>
	);
};

export default MenuItemModifierGroups;
