/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ChangeEvent, Reducer, useCallback, useEffect, useMemo, useReducer, useState } from "react";

import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import analytics from "analytics/analytics";
import clsx from "clsx";
import { motion } from "framer-motion";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useStore, { CartItem } from "store";

import calculateMenuItemUnitPriceCents from "utils/calculateMenuItemUnitPriceCents";

import getLocalId from "utils/getLocalId";
import isUnavailable from "utils/timeUtils/isUnavailable";

import MenuItem from "api/types/MenuItem";
import ItemQuantity from "components/ItemQuantity";
import { formatMoney } from "components/Price";
import { OrderItemStatuses } from "gql/types/globals";
import useLocalize from "hooks/useLocalize";

import MenuItemModifierGroups from "./MenuItemModifierGroups";
import SelectedModifier from "./SelectedModifier";

const useStyles = makeStyles(theme => {
	return {
		main: {
			display: "flex",
			flex: 1,
			flexGrow: 1,
			flexDirection: "column",
			backgroundColor: "#fff",
			margin: 0,
			padding: 0,
			paddingBottom: 140,
			overflowX: "hidden",
			overflowY: "auto"
		},
		inner: {
			display: "flex",
			width: "100%",
			height: "auto",
			flexGrow: 1,
			flexDirection: "column",
			padding: theme.spacing(2)
		},
		noImage: {
			marginTop: 50
		},
		unavailable: {
			alignSelf: "flex-end",
			height: 25,
			padding: theme.spacing(2),
			width: "100%"
		},
		unavailableMessage: {
			textAlign: "center"
		},
		bottom: {
			position: "absolute",
			backgroundColor: "transparent",
			bottom: 0,
			left: 0,
			right: 0,
			alignSelf: "flex-end",
			height: 75,
			padding: theme.spacing(2),
			width: "100%"
		},
		row: {
			display: "flex",
			flexDirection: "row",
			flex: 1
		},
		btnRow: {
			display: "flex",
			flexDirection: "row",
			flex: 1,
			justifyContent: "flex-end",
			alignItems: "flex-end"
		},
		centered: {
			justifyContent: "center"
		},
		titleArea: {
			display: "grid",
			gridTemplateColumns: "1fr 50px"
		},

		title: {
			gridColumn: "1/2",
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
			fontWeight: 600,
			fontSize: 20
		},

		favoriteButton: {
			margin: 0,
			gridColumn: "2/2",
			display: "flex",
			alignSelf: "center",
			alignItems: "center",
			alignContent: "flex-end",
			justifySelf: "flex-end",
			textAlign: "right",
			height: 45,
			width: 45
		},

		favorite: {
			color: "red"
		},

		desc: {
			fontSize: 16,
			marginBottom: theme.spacing(2)
		},
		textFieldInput: {
			paddingTop: 6,
			paddingBottom: 7
		},
		addButton: {
			height: 45,
			marginLeft: theme.spacing(2),
			borderRadius: 8,
			width: "100%",
			display: "flex",
			justifyContent: "space-between"
		},
		btnText: {
			fontSize: "1rem"
		},
		play: {
			color: "#fff",
			alignSelf: "center",
			justifySelf: "center",
			zIndex: 2000,
			position: "absolute",
			top: "calc(50% - 40px)",
			left: "calc(50vw - 40px)",
			height: 80,
			width: 80
		},
		itemsRow: {
			paddingBottom: theme.spacing(2)
		},
		specialInstructions: {
			marginBottom: theme.spacing(6)
		},
		disabled: {}
	};
});

type State = {
	mode: string;
	localId: string;
	menuItem?: MenuItem;
	specialInstructions: string | null | undefined;
	quantity: number;
	selectedModifiers?: (SelectedModifier | null)[] | undefined;
	unitPriceCents: number;
	extendedPriceCents: number;
	valid: boolean;
};

enum actions {
	loadCartItem,
	setInstructions,
	increaseQty,
	decreaseQty,
	setGroupModifiers
}
type Action =
	| { type: actions.loadCartItem; payload: CartItem }
	| { type: actions.setInstructions; payload: string }
	| { type: actions.increaseQty }
	| { type: actions.decreaseQty }
	| {
			type: actions.setGroupModifiers;
			payload: (SelectedModifier | null)[];
	  };

const isValid = (state: State): boolean => {
	if (state.quantity < 1) {
		return false;
	}

	if (state.menuItem?.menuItemModifierGroups && state.menuItem!.menuItemModifierGroups?.length > 0) {
		for (const group of state.menuItem.menuItemModifierGroups) {
			const selected =
				state.selectedModifiers && state.selectedModifiers.filter(x => x && x.menuItemModifierGroupId === group!._id);

			if (group?.requiredMinimum && selected && selected.length < group.requiredMinimum) {
				return false;
			}

			if (group && group.requiredMaximum && selected && selected.length > group.requiredMaximum) {
				return false;
			}
		}
	}

	return true;
};

const validate = (state: State): State => ({ ...state, valid: isValid(state) });

const calculate = (state: State): State => {
	const unitPriceCents = calculateMenuItemUnitPriceCents(state);
	return { ...state, unitPriceCents, extendedPriceCents: unitPriceCents * state.quantity };
};

const calculateAndValidate = (state: State) => validate(calculate(state));

const initializer = ({
	mode,
	localId,
	menuItem,
	defaultSpecialInstructions,
	defaultQuantity,
	defaultSelectedModifiers
}: {
	mode: State["mode"];
	localId: State["localId"];
	menuItem: State["menuItem"];
	defaultSpecialInstructions?: State["specialInstructions"];
	defaultQuantity?: State["quantity"];
	defaultSelectedModifiers?: State["selectedModifiers"];
}) =>
	calculateAndValidate({
		mode,
		localId,
		menuItem,
		specialInstructions: defaultSpecialInstructions || "",
		quantity: defaultQuantity || 1,
		selectedModifiers: defaultSelectedModifiers || [],
		unitPriceCents: 0,
		extendedPriceCents: 0,
		valid: false
	});

const reducer: Reducer<State, Action> = (state: State, action: Action) => {
	switch (action.type) {
		case actions.loadCartItem:
			return calculateAndValidate({
				...state,
				...action.payload,
				mode: "edit"
			});
		case actions.setInstructions:
			return validate({
				...state,
				specialInstructions: action.payload
			});

		case actions.increaseQty:
			return calculateAndValidate({
				...state,
				quantity: state.quantity + 1
			});

		case actions.decreaseQty:
			return calculateAndValidate({
				...state,
				quantity: Math.max(1, state.quantity - 1)
			});

		case actions.setGroupModifiers:
			return calculateAndValidate({
				...state,
				selectedModifiers: action.payload
			});

		default:
			return state;
	}
};

interface Props {
	onClose: () => void;
}

const MenuItemDetails: React.FC<Props> = ({ onClose }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { l } = useLocalize();

	const cart = useStore(store => store.cart);
	const business = useStore(store => store.business);
	const locale = useStore(store => store.locale);
	const tableMemberId = useStore(store => store.tableMemberId);
	const addOrderItems = useStore(store => store.addOrderItems);
	const updateOrderItem = useStore(store => store.updateOrderItem);
	const setIsCartButtonVisible = useStore(store => store.setIsCartButtonVisible);
	const orderTime = useStore(store => store.orderTime);

	const setLoading = useStore(state => state.setLoading);
	const isLoggedIn = useStore(state => state.isLoggedIn);
	const favorites = useStore(state => state.favorites);
	const toggleMenuItemFavorite = useStore(state => state.toggleMenuItemFavorite);

	const navigate = useNavigate();

	const [isAddEnabled, setIsAddEnabled] = useState(true);

	const { pathname } = window.location;
	const isEditMode = pathname.includes("/cart");

	useEffect(() => {
		setIsCartButtonVisible(false);
		if (isEditMode) {
			const hashLocalId = pathname.split("/")[pathname.split("/").length - 2];

			if (hashLocalId && hashLocalId.length > 0) {
				const cartItem = cart.find(i => i && i.localId == hashLocalId);
				if (cartItem) {
					cartItem.menuItem = business!.menus![0]!.menuItems!.find(f => f!._id === cartItem?.menuItem._id)!;
					dispatch({ type: actions.loadCartItem, payload: cartItem });
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // only onmount

	const itemSlug = useMemo(() => pathname.replace("/detail", "").split("/").splice(-1)[0], [pathname]);

	const currentMenuItem = !isEditMode
		? business!.menus![0]!.menuItems!.find(f => f!.slug === itemSlug)!
		: cart.find(i => i && i.localId == itemSlug)?.menuItem;

	const [state, dispatch] = useReducer(
		reducer,
		{
			mode: "new",
			localId: getLocalId(),
			menuItem: currentMenuItem,
			defaultSpecialInstructions: "",
			defaultQuantity: 1,
			defaultSelectedModifiers: []
		},
		initializer
	);

	const {
		menuItem,
		localId,
		specialInstructions,
		quantity,
		selectedModifiers,
		extendedPriceCents,
		unitPriceCents,
		valid
	} = state;

	if (!menuItem) {
		return null;
	}

	const isFavorite = !!favorites.find(f => f && f.menuItemId === menuItem._id);

	const favoriteClick = async e => {
		if (isLoggedIn) {
			e.stopPropagation();
			const newValue = !isFavorite;
			await toggleMenuItemFavorite(menuItem._id, newValue);

			analytics.track(newValue ? "Favorite selected from item detail" : "Favorite unselected from item detail", {
				business: business?.name,
				menuItem: menuItem!.name,
				menuItemId: menuItem!._id
			});
		} else {
			analytics.track("Favorite selected from item detail not logged in", {
				business: business?.name,
				menuItem: menuItem!.name,
				menuItemId: menuItem!._id
			});
			navigate(`/${locale}/main/account`);
		}
	};

	const startClose = useCallback(() => {
		// setIsAddEnabled(true);
		setLoading(false);
		onClose();
	}, [onClose, setLoading]);

	const setInstructions = useCallback(
		(value: string) => dispatch({ type: actions.setInstructions, payload: value }),
		[dispatch]
	);

	const increment = useCallback(() => {
		dispatch({ type: actions.increaseQty });
		analytics.track("Quantity incremented", {
			menuItem: menuItem!.name,
			menuItemId: menuItem!._id,
			quantity: quantity + 1,
			extendedPriceCents: extendedPriceCents
		});
	}, [dispatch, menuItem, quantity, extendedPriceCents]);

	const decrement = useCallback(() => {
		dispatch({ type: actions.decreaseQty });
		analytics.track("Quantity decremented", {
			menuItem: menuItem!.name,
			menuItemId: menuItem!._id,
			quantity: quantity - 1,
			extendedPriceCents: extendedPriceCents
		});
	}, [dispatch, menuItem, quantity, extendedPriceCents]);

	const setSelectedModifiers = useCallback(
		(payload: SelectedModifier[]) => {
			dispatch({ type: actions.setGroupModifiers, payload });
		},
		[dispatch]
	);

	const onInstructionChanged = useCallback(
		(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
			setInstructions(event.target.value);
		},
		[setInstructions]
	);

	const handleModifierChange = useCallback(
		({ value }: { value: SelectedModifier[] }) => {
			setSelectedModifiers(value);
		},
		[setSelectedModifiers]
	);

	const addToCart = useCallback(() => {
		const addAsync = async () => {
			setLoading(true);
			setIsAddEnabled(false);
			if (isEditMode) {
				await updateOrderItem({
					localId,
					menuItem: menuItem!,
					quantity,
					specialInstructions,
					extendedPriceCents,
					unitPriceCents,
					selectedModifiers: state.selectedModifiers
				});
			} else {
				await addOrderItems([
					{
						localId,
						menuItem: menuItem!,
						orderedById: tableMemberId,
						orderItemStatus: OrderItemStatuses.new,
						quantity,
						specialInstructions,
						extendedPriceCents,
						unitPriceCents,
						selectedModifiers: state.selectedModifiers
					}
				]);
			}
			startClose();
		};
		addAsync();
	}, [
		addOrderItems,
		extendedPriceCents,
		isEditMode,
		localId,
		menuItem,
		quantity,
		setLoading,
		specialInstructions,
		startClose,
		state.selectedModifiers,
		tableMemberId,
		unitPriceCents,
		updateOrderItem
	]);

	const hasImage = menuItem?.mediaLinks && menuItem?.mediaLinks.length > 0;

	business?.globalMenuItemModifierGroups &&
		business?.globalMenuItemModifierGroups.forEach(g => {
			g &&
				g.menuItemModifiers &&
				g.menuItemModifiers.forEach(i => {
					if (
						i &&
						i.isDefault == true &&
						selectedModifiers &&
						!selectedModifiers.find(s => s && s.menuItemModifierGroupId === g._id)
					) {
						selectedModifiers.push({
							menuItemModifierId: i._id,
							menuItemModifierGroupId: g!._id!
						});
					}
				});
		});

	const isItemUnAvailable =
		menuItem.isUnAvailable ||
		Boolean(
			currentMenuItem?.availability?.scheduleExceptions &&
				isUnavailable(orderTime ?? DateTime.local(), currentMenuItem.availability)
		);

	const businessMenuItem = business!.menus![0]!.menuItems!.find(f => f!._id === currentMenuItem?._id)!;

	return (
		<motion.div layoutId={menuItem._id} className={classes.main}>
			<Box>
				<Box className={clsx(classes.inner, !hasImage ? classes.noImage : "")}>
					<Box>
						<Box className={classes.titleArea}>
							<Typography className={classes.title}>
								{l(menuItem.localeNames, menuItem?.name)} •{" "}
								{menuItem.displayPrice ? menuItem.displayPrice : formatMoney(menuItem?.priceCents)}
							</Typography>

							<IconButton
								className={classes.favoriteButton}
								onClick={favoriteClick}
								aria-label={t("toggleFavorite")}
								size="large"
							>
								{isFavorite ? <FavoriteIcon className={classes.favorite} /> : <FavoriteBorderIcon />}
							</IconButton>
						</Box>
						<Box className={classes.row}>
							<div className={classes.desc} dangerouslySetInnerHTML={{ __html: l(menuItem?.localeDescriptions) }} />
						</Box>
					</Box>
					<Box>
						{!businessMenuItem.menuItemModifierGroups?.length ? null : (
							<Box className={classes.itemsRow}>
								<MenuItemModifierGroups
									menuItemModifierGroups={businessMenuItem.menuItemModifierGroups}
									defaultValue={selectedModifiers}
									onChange={handleModifierChange}
								/>
							</Box>
						)}
					</Box>
					{/* <Box>
						{businessMenuItem.menuItemModifierGroups && businessMenuItem.menuItemModifierGroups!.length > 0
							? businessMenuItem.menuItemModifierGroups!.map(g => <Box key={g!._id!}>{l(g?.localeNames)}</Box>)
							: null}
					</Box> */}
					<TextField
						className={clsx(classes.specialInstructions)}
						InputProps={{
							className: classes.textFieldInput
						}}
						fullWidth
						placeholder={t("specialInstructions")}
						value={specialInstructions}
						onChange={onInstructionChanged}
						multiline
						maxRows={4}
					/>
					{!businessMenuItem.disableGlobalMenuItemModifierGroups && business?.globalMenuItemModifierGroups && (
						<Box>
							{!business?.globalMenuItemModifierGroups?.length ? null : (
								<Box className={classes.itemsRow}>
									<MenuItemModifierGroups
										menuItemModifierGroups={business?.globalMenuItemModifierGroups}
										defaultValue={selectedModifiers}
										onChange={handleModifierChange}
									/>
								</Box>
							)}
						</Box>
					)}
				</Box>
			</Box>
			<>
				{/* {isItemUnAvailable && (
					<Box className={classes.unavailable}>
						<Typography className={classes.unavailableMessage}>{t("itemSoldOut")}</Typography>
					</Box>
				)} */}
				<Box className={classes.bottom}>
					<Box className={clsx(classes.btnRow)}>
						<ItemQuantity
							size={"large"}
							minimumQuantity={1}
							onDecrement={decrement}
							onIncrement={increment}
							menuItem={menuItem!}
							quantity={quantity}
						/>

						<Button
							variant={"contained"}
							color="secondary"
							className={classes.addButton}
							onClick={addToCart}
							disabled={!valid || !isAddEnabled || isItemUnAvailable}
						>
							{isItemUnAvailable ? (
								<Typography className={classes.btnText}>{t("soldOut")}</Typography>
							) : (
								<Typography className={classes.btnText}>{isEditMode ? t("updateCart") : t("addToCart")}</Typography>
							)}
							<Typography className={classes.btnText}>{formatMoney(extendedPriceCents)}</Typography>
						</Button>
					</Box>
				</Box>
			</>
		</motion.div>
	);
};

export default MenuItemDetails;
