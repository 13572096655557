import i18n from "i18next";

import { TabInfo } from "api/types/TabInfo";

const validateCustomerInfo = (values: TabInfo): Partial<TabInfo> => {
	const errors: Partial<TabInfo> = {};
	if (!values.name) {
		errors.name = i18n.t("required");
	} else {
		if (values.name.length < 2) {
			errors.name = i18n.t("invalid");
		}
	}
	// if (!values.lastName) {
	// 	errors.lastName = i18n.t("required");
	// } else {
	// 	if (values.lastName.length < 2) {
	// 		errors.lastName = i18n.t("invalid");
	// 	}
	// }
	return errors;
};

export default validateCustomerInfo;
