/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { PaymentMethodAddStartMutation as PaymentMethodAddStartMutationType, PaymentMethodAddStartMutationVariables } from "gql/types/mutations/PaymentMethodAddStartMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation PaymentMethodAddStartMutation($customerPaymentMethodStartInput: CustomerPaymentMethodStartInput!) {
	customerPaymentMethodAddStart(customerPaymentMethodStartInput: $customerPaymentMethodStartInput) {
		setupIntent
	}
}
`;

const PaymentMethodAddStartMutation: IGqlOperation<PaymentMethodAddStartMutationType, PaymentMethodAddStartMutationVariables> = { text } as any;

export default PaymentMethodAddStartMutation;
