/* tslint:disable */
/* eslint-disable */
// @generated
//============================================
// Auto-generated file.  DO NOT EDIT.
//============================================

import { PaymentMethodAddMutation as PaymentMethodAddMutationType, PaymentMethodAddMutationVariables } from "gql/types/mutations/PaymentMethodAddMutation";

import { gql } from "@apollo/client";
import IGqlOperation from "api/IGqlOperation";

const text = gql`mutation PaymentMethodAddMutation($customerPaymentMethodAddInput: CustomerPaymentMethodAddInput!) {
	customerPaymentMethodAdd(customerPaymentMethodAddInput: $customerPaymentMethodAddInput) {
		_id
		name
		brand
		last4
		isDefault
		expMonth
		expYear
	}
}
`;

const PaymentMethodAddMutation: IGqlOperation<PaymentMethodAddMutationType, PaymentMethodAddMutationVariables> = { text } as any;

export default PaymentMethodAddMutation;
