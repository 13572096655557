import { DateTime } from "luxon";

/**
 * Round the specified startTime to the nearest interval
 * @param startTime
 * @param interval
 * @returns
 */

const getNextTimeSlot = (startTime: DateTime, interval: number): DateTime => {
	const intMs = interval * 60 * 1000; // to milliseconds
	return DateTime.fromMillis(Math.ceil(startTime.toMillis() / intMs) * intMs);
};

export default getNextTimeSlot;
