/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";

import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import analytics from "analytics/analytics";
import { motion } from "framer";

import { useNavigate } from "react-router";

import { MediaTypes } from "gql/types/globals";
import { BusinessQuery_business_menus_menuItems } from "gql/types/queries/BusinessQuery";

const useStyles = makeStyles(theme => {
	return {
		root: {
			display: "flex",
			backgroundColor: "transparent",
			border: 0,
			padding: 0,
			marginRight: theme.spacing(1)
		},
		img: {
			display: "flex",
			objectFit: "cover",
			backgroundColor: "#eee",
			flex: 1,
			marginRight: 2,
			borderRadius: 8,
			height: "277px",
			elevation: 10,
			width: 128
		},
		video: {
			backgroundColor: "#eee",
			objectFit: "cover",
			marginRight: 2,
			height: "277px",
			borderRadius: 8,
			width: 128
		}
	};
});

interface Props {
	menuItem: BusinessQuery_business_menus_menuItems;
	key: string;
	defaultImageUrl: string;
}

const HighlightItem: React.FC<Props> = ({ menuItem, defaultImageUrl }) => {
	const classes = useStyles();
	const navigate = useNavigate();

	const background = {
		backgroundSize: "cover",
		backgroundPosition: "center",
		backgroundImage: `url('${
			menuItem.mediaLinks && menuItem.mediaLinks![0]!.mediaUrl ? menuItem.mediaLinks![0]!.mediaUrl : defaultImageUrl
		}')`
	};

	const selectItem = () => {
		analytics.track("Menu highlight selected", {
			menuItem: menuItem!.name,
			menuItemId: menuItem!._id
		});

		navigate(`../noms/highlights/${menuItem.slug}`);
	};

	const video =
		menuItem.mediaLinks &&
		menuItem.mediaLinks?.length > 0 &&
		menuItem.mediaLinks![0]!.mediaType === MediaTypes.video ? (
			<video className={classes.video} autoPlay loop src={menuItem!.mediaLinks![0]!.mediaUrl} playsInline muted />
		) : null;

	return (
		<motion.div className={classes.root} onMouseUp={selectItem} whileTap={{ scale: 0.98 }}>
			{video ? video : <Box className={classes.img} style={background}></Box>}
		</motion.div>
	);
};

export default HighlightItem;
