/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AuthTypes {
	business = "business",
	consumer = "consumer"
}

export enum DaysOfWeek {
	fri = "fri",
	mon = "mon",
	sat = "sat",
	sun = "sun",
	thu = "thu",
	tue = "tue",
	wed = "wed"
}

export enum EventLevels {
	critical = "critical",
	error = "error",
	information = "information",
	warning = "warning"
}

export enum MediaTypes {
	desktopImage = "desktopImage",
	image = "image",
	video = "video"
}

export enum NotificationLevels {
	error = "error",
	information = "information",
	warning = "warning"
}

export enum NotificationTypes {
	orderUpdated = "orderUpdated",
	sharedOrderItemAdded = "sharedOrderItemAdded",
	sharedOrderItemRemoved = "sharedOrderItemRemoved",
	sharedOrderItemUpdated = "sharedOrderItemUpdated"
}

export enum OrderFulfillmentMethods {
	batchDelivery = "batchDelivery",
	curbsidePickup = "curbsidePickup",
	delivery = "delivery",
	dineIn = "dineIn",
	pickup = "pickup"
}

export enum OrderItemStatuses {
	cancelledByBusiness = "cancelledByBusiness",
	cancelledByCustomer = "cancelledByCustomer",
	confirmed = "confirmed",
	new = "new",
	placed = "placed",
	ready = "ready",
	served = "served"
}

export enum OrderStatuses {
	awaitingPayment = "awaitingPayment",
	cancelledByBusiness = "cancelledByBusiness",
	cancelledByCustomer = "cancelledByCustomer",
	completed = "completed",
	confirmed = "confirmed",
	confirmedWithDelay = "confirmedWithDelay",
	new = "new",
	outForDelivery = "outForDelivery",
	paymentFailed = "paymentFailed",
	placed = "placed",
	ready = "ready",
	scheduled = "scheduled"
}

export enum OrderTabStatuses {
	closed = "closed",
	closing = "closing",
	open = "open",
	opening = "opening"
}

export enum PaymentMethods {
	card = "card",
	inStore = "inStore"
}

export enum PromotionTypes {
	coupon = "coupon",
	discountAmount = "discountAmount",
	discountPercent = "discountPercent",
	freeItem = "freeItem"
}

export enum TableMemberStatuses {
	joined = "joined",
	left = "left",
	removed = "removed"
}

export enum TaxRules {
	caAb = "caAb",
	caBc = "caBc",
	caMb = "caMb",
	caNb = "caNb",
	caNl = "caNl",
	caNs = "caNs",
	caNt = "caNt",
	caNu = "caNu",
	caOn = "caOn",
	caPe = "caPe",
	caQc = "caQc",
	caSk = "caSk",
	caYt = "caYt",
	usa = "usa"
}

export enum TaxTypes {
	gst = "gst",
	hst = "hst",
	pst = "pst",
	qst = "qst",
	rst = "rst",
	salesTax = "salesTax"
}

export interface AddOrderItemDetailInput {
	menuItemId: string;
	localId?: string | null;
	specialInstructions?: string | null;
	quantity?: number | null;
	orderedById?: string | null;
	orderedBy?: string | null;
	selectedMenuItemModifiers?: (AddOrderItemSelectedModifierInput | null)[] | null;
	unitPriceCents?: number | null;
	extendedPriceCents?: number | null;
	taxCents?: number | null;
}

export interface AddOrderItemSelectedModifierInput {
	menuItemModifierId?: string | null;
	menuItemModifierGroupId?: string | null;
}

export interface AddOrderItemsInput {
	businessId: string;
	orderId?: string | null;
	localId: string;
	menuId: string;
	items?: (AddOrderItemDetailInput | null)[] | null;
}

export interface AddressInput {
	address1?: string | null;
	address2?: string | null;
	city?: string | null;
	state?: string | null;
	postalCode?: string | null;
	country?: string | null;
	gpsCoordinates?: GpsCoordinatesInput | null;
	placeId?: string | null;
	place?: any | null;
}

export interface AuthenticateOtpInput {
	authType: AuthTypes;
	mobileNumber: string;
	otp: string;
	businessId?: string | null;
}

export interface BeginOtpAuthenticationInput {
	authType: AuthTypes;
	domain?: string | null;
	businessId?: string | null;
	mobileNumber: string;
}

export interface BusinessPromotionsInput {
	businessId: string;
}

export interface BusinessQueryInput {
	slug?: string | null;
	domainHostName?: string | null;
	businessId?: string | null;
}

export interface ClearOrderItemsInput {
	businessId: string;
	orderId: string;
	tableMemberId?: string | null;
}

export interface CustomerPaymentMethodAddInput {
	paymentMethod: string;
	nameOnCard: string;
	isDefault?: boolean | null;
}

export interface CustomerPaymentMethodDeleteInput {
	_id: string;
}

export interface CustomerPaymentMethodStartInput {
	mobileNumber?: string | null;
}

export interface CustomerPaymentMethodUpdateInput {
	_id: string;
	isDefault?: boolean | null;
}

export interface CustomerPaymentMethodsInput {
	customerId?: string | null;
}

export interface CustomerUpdateProfileInput {
	name?: string | null;
	firstName?: string | null;
	lastName?: string | null;
	email?: string | null;
	phoneNumber?: string | null;
}

export interface EnsureCustomerDeviceInput {
	deviceToken?: string | null;
}

export interface GetCustomerAppInfoInput {
	branch: string;
	currentVersion: string;
}

export interface GetMenuItemFavoritesInput {
	businessId: string;
}

export interface GpsCoordinatesInput {
	latitude: number;
	longitude: number;
}

export interface LogEventInput {
	title: string;
	eventType: string;
	source?: string | null;
	data?: any | null;
	level?: EventLevels | null;
	publish?: boolean | null;
}

export interface NotificationUpdatedInput {
	deviceToken?: string | null;
	customerId?: string | null;
}

export interface OrderApplyPromotionInput {
	businessId: string;
	localId: string;
	orderId?: string | null;
	promotionId?: string | null;
	promotionCode?: string | null;
}

export interface OrderChangedInput {
	orderId: string;
}

export interface OrderFindOpenTabInput {
	businessId: string;
	tableSlug: string;
}

export interface OrderQueryInput {
	orderId: string;
	accessToken?: string | null;
}

export interface OrderTabFindInput {
	businessId: string;
	joinId: string;
	tableSlug: string;
}

export interface OrderTabJoinInput {
	orderId: string;
	tableSlug: string;
	tableMemberId: string;
	name?: string | null;
}

export interface OrderTabLeaveInput {
	orderId: string;
	tableMemberId: string;
	orderFulfillmentMethod?: OrderFulfillmentMethods | null;
}

export interface OrderTabMakeHostInput {
	orderId: string;
	tableMemberId: string;
}

export interface OrderTabOpenInput {
	businessId: string;
	tableSlug: string;
	orderId?: string | null;
	localId: string;
	orderTabStatus?: OrderTabStatuses | null;
	tableMemberId: string;
	name?: string | null;
	firstName?: string | null;
	lastName?: string | null;
}

export interface OrderTabRemoveMemberInput {
	orderId: string;
	tableMemberId: string;
}

export interface OrderTabUpdateMemberInfoInput {
	orderId: string;
	tableMemberId: string;
	name?: string | null;
}

export interface OrderTaxInput {
	type: TaxTypes;
	taxRate: number;
	taxCents: number;
}

export interface PlaceOrderCompleteCustomerInput {
	name?: string | null;
	firstName?: string | null;
	lastName?: string | null;
	mobileNumber?: string | null;
	email?: string | null;
}

export interface PlaceOrderCompleteInput {
	businessId: string;
	orderId?: string | null;
	customer?: PlaceOrderCompleteCustomerInput | null;
}

export interface PlaceOrderStartCustomerInput {
	firstName?: string | null;
	lastName?: string | null;
	mobileNumber?: string | null;
}

export interface PlaceOrderStartInput {
	businessId: string;
	menuId: string;
	orderId?: string | null;
	deviceToken: string;
	customer?: PlaceOrderStartCustomerInput | null;
	paymentMethod?: PaymentMethods | null;
	nameOnCard?: string | null;
	savePaymentCard?: boolean | null;
	useDefaultPaymentMethod?: boolean | null;
	deliveryAddress?: AddressInput | null;
	deliveryOrPickupAt?: any | null;
	deliveryInstructions?: string | null;
	orderFulfillmentType: PlaceOrderStartOrderFulfillmentTypeInput;
	prepTimeMinutes?: number | null;
	isAsapOrder?: boolean | null;
	includeUtensils?: boolean | null;
	orderNote?: string | null;
	promotions?: (PlaceOrderStartPromotionInput | null)[] | null;
	discountCents?: number | null;
	orderSubTotalCents: number;
	taxes?: (OrderTaxInput | null)[] | null;
	taxCents: number;
	tipCents: number;
	deliveryFeeCents?: number | null;
	orderTotalCents: number;
	items?: (PlaceOrderStartItemDetailInput | null)[] | null;
}

export interface PlaceOrderStartItemDetailInput {
	menuItemId: string;
	localId?: string | null;
	specialInstructions?: string | null;
	quantity?: number | null;
	selectedMenuItemModifiers?: (PlaceOrderStartSelectedModifierInput | null)[] | null;
	unitPriceCents?: number | null;
	extendedPriceCents?: number | null;
	taxCents?: number | null;
}

export interface PlaceOrderStartOrderFulfillmentTypeInput {
	orderFulfillmentTypeId: string;
	orderFulfillmentMethod?: OrderFulfillmentMethods | null;
}

export interface PlaceOrderStartPromotionInput {
	promotionId?: string | null;
}

export interface PlaceOrderStartSelectedModifierInput {
	menuItemModifierId?: string | null;
	menuItemModifierGroupId?: string | null;
}

export interface PlaceWalletOrderCustomerInput {
	name: string;
	email?: string | null;
	phone?: string | null;
}

export interface PlaceWalletOrderStartInput {
	businessId: string;
	menuId: string;
	orderId?: string | null;
	deviceToken: string;
	walletName?: string | null;
	customer?: PlaceWalletOrderCustomerInput | null;
	paymentMethod?: PaymentMethods | null;
	deliveryAddress?: AddressInput | null;
	deliveryOrPickupAt?: any | null;
	deliveryInstructions?: string | null;
	orderFulfillmentType: PlaceOrderStartOrderFulfillmentTypeInput;
	prepTimeMinutes?: number | null;
	isAsapOrder?: boolean | null;
	includeUtensils?: boolean | null;
	orderNote?: string | null;
	promotions?: (PlaceOrderStartPromotionInput | null)[] | null;
	discountCents?: number | null;
	orderSubTotalCents: number;
	taxes?: (OrderTaxInput | null)[] | null;
	taxCents: number;
	tipCents: number;
	deliveryFeeCents?: number | null;
	orderTotalCents: number;
	items?: (PlaceOrderStartItemDetailInput | null)[] | null;
}

export interface RecentOrdersInput {
	businessId?: string | null;
	phoneNumber?: string | null;
	asOfDate?: any | null;
	limit?: number | null;
	skip?: number | null;
}

export interface RegisterInput {
	businessId?: string | null;
	domain?: string | null;
	mobileNumber?: string | null;
}

export interface RemoveOrderItemInput {
	businessId: string;
	orderId: string;
	orderItemLocalId: string;
}

export interface ToggleMenuItemFavoriteInput {
	businessId: string;
	menuItemId: string;
	isFavorite: boolean;
}

export interface UpdateOrderItemDetailInput {
	menuItemId: string;
	localId?: string | null;
	specialInstructions?: string | null;
	quantity?: number | null;
	selectedMenuItemModifiers?: (UpdateOrderItemSelectedModifierInput | null)[] | null;
	unitPriceCents?: number | null;
	extendedPriceCents?: number | null;
	taxCents?: number | null;
}

export interface UpdateOrderItemInput {
	businessId: string;
	menuId: string;
	orderId?: string | null;
	item?: UpdateOrderItemDetailInput | null;
}

export interface UpdateOrderItemSelectedModifierInput {
	menuItemModifierId?: string | null;
	menuItemModifierGroupId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
