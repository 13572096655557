import React, { useCallback, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, IconButton, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import { AnimatePresence, Frame } from "framer";
import { useTranslation } from "react-i18next";
import useStore from "store";

import Head from "components/Head";
import ProgressBar from "components/ProgressBar";

const useStyles = makeStyles(theme => {
	return {
		dialogRoot: {
			backgroundColor: "transparent",
			// height: "calc(var(--vh, 1vh) * 100)",
			margin: 0,
			padding: 0,
			width: "100vw",
			[theme.breakpoints.down("sm")]: {
				minHeight: "100%"
			}
		},
		paper: {
			backgroundColor: "transparent",
			overflow: "hidden",
			[theme.breakpoints.down("sm")]: {
				minHeight: "100%"
			}
		},

		content: {},
		fullModalScroll: {},
		root: {
			width: "100vw",
			display: "flex",
			justifyContent: "center",
			alignContent: "center",
			overflow: "hidden",
			backgroundColor: "transparent",
			zIndex: 100,
			[theme.breakpoints.down("sm")]: {
				minHeight: "100%"
			}
		},
		root2: {
			minHeight: "100%",
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignContent: "center",
			overflow: "hidden",
			backgroundColor: "#fff",
			zIndex: 100
		},
		topRow: {
			backgroundColor: "transparent",
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			zIndex: 1401,
			display: "flex",
			flexDirection: "row",
			alignContent: "center",
			alignItems: "center"
		},
		topRowFullScroll: {
			backgroundColor: "transparent",
			zIndex: 1401,
			display: "flex",
			flexDirection: "row",
			alignContent: "center",
			alignItems: "center"
		},
		gradientTop: {
			background: "linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 90%)"
		},
		progress: {
			zIndex: 2000,
			position: "absolute",
			top: 0,
			left: 0,
			right: 0
		},
		closeIcon: {
			zIndex: 1500,
			color: theme.palette.text.primary
		},
		title: {
			justifySelf: "center",
			alignSelf: "center",
			textAlign: "center",
			verticalAlign: "center",
			fontSize: 20,
			fontWeight: 500,
			color: theme.palette.primary.main
		},
		headerForImage: {
			color: "#fff"
		}
	};
});

const spring = {
	duration: 0.1,
	opacity: { duration: 0.1, delay: 0.1 }
};

interface Props {
	hasImage?: boolean | null | undefined;
	checkVisible?: () => boolean;
	startClose: boolean;
	onOpen?: () => void;
	onClose?: () => void;
	children: React.ReactElement;
	businessName: string;
	title?: string;
	browserTabTitle?: string;
	hideHeader?: boolean;
	fullScreen?: boolean;
	fullModalScroll?: boolean;
}

const Modal: React.FC<Props> = ({
	checkVisible,
	startClose,
	children,
	onOpen,
	onClose,
	businessName,
	title,
	browserTabTitle,
	hasImage = false,
	fullScreen = false,
	hideHeader = false,
	fullModalScroll = false
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const loading = useStore(store => store.loading);
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	// const { t } = useTranslation();
	const [visible, setVisible] = useState(false);
	const [isClosing, setIsClosing] = useState(false);

	useEffect(() => {
		if (checkVisible) {
			setVisible(checkVisible());
		} else {
			setVisible(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const close = useCallback(() => {
		if (isClosing) {
			if (isMobile) {
				setVisible(false);
			}

			setIsClosing(false);

			// the timeout allows the UI to animate smoothly
			setTimeout(() => {
				onClose && onClose();
			}, 500);
		}
	}, [isClosing, isMobile, onClose]);

	// handle modal-initiated closing
	useEffect(() => {
		if (isClosing) {
			close();
		}
	}, [isClosing, close]);

	// handle child-initiated closing
	useEffect(() => {
		if (startClose) {
			setIsClosing(true);
		}
	}, [startClose, close]);

	const closeClicked = () => {
		setIsClosing(true);
	};

	if (onOpen) {
		onOpen();
	}

	return isMobile || fullScreen ? (
		<AnimatePresence>
			{visible && (
				<Dialog
					fullScreen={isMobile || fullScreen}
					open={visible}
					onClose={closeClicked}
					aria-labelledby={"modal-title"}
					className={clsx(classes.dialogRoot)}
					classes={{ paper: classes.paper }}
				>
					<Frame
						key={"dialogParent"}
						className={clsx(classes.root)}
						drag={false}
						transition={spring}
						backgroundColor="transparent"
						width="100vw"
						height="calc(var(--vh, 1vh) * 100)"
						minHeight="100%"
						exit={{ opacity: 0 }}
						initial={{ scale: 0.85, opacity: 0.5 }}
						animate={{ scale: 1, opacity: 1 }}
					>
						<Frame
							className={clsx(classes.root)}
							backgroundColor="#fff"
							width="100vw"
							drag={false}
							height={"calc(var(--vh, 1vh) * 100)"}
							minHeight="100%"
							transition={{ delay: 0.1 }}
							exit={{ scale: 1.5 }}
							initial={{ y: -100, opacity: 0 }}
							animate={{ y: 0, opacity: 1 }}
						>
							<Head title={businessName} subTitle={browserTabTitle ?? title} />
							<Box className={clsx(fullModalScroll ? classes.fullModalScroll : classes.content)}>
								{loading && <ProgressBar className={classes.progress} />}
								{!hideHeader && (
									<Box
										className={clsx(
											fullModalScroll ? classes.topRowFullScroll : classes.topRow,
											hasImage && classes.gradientTop
										)}
									>
										<IconButton
											onClick={closeClicked}
											className={clsx(classes.closeIcon, hasImage && classes.headerForImage)}
											aria-label={t("close")}
											size="large"
										>
											<CloseIcon />
										</IconButton>
										{title && (
											<Typography className={clsx(classes.title, hasImage && classes.headerForImage)} id="modal-title">
												{title}
											</Typography>
										)}
									</Box>
								)}
								{children}
							</Box>
						</Frame>
					</Frame>
				</Dialog>
			)}
		</AnimatePresence>
	) : (
		<AnimatePresence>
			{visible && (
				<Dialog
					fullScreen={isMobile}
					open={visible}
					onClose={closeClicked}
					className={classes.dialogRoot}
					classes={{ paper: classes.paper }}
				>
					<Box className={clsx(classes.root2)}>
						<Head title={businessName} subTitle={title} />
						{loading && <ProgressBar className={classes.progress} />}
						{!hideHeader && (
							<Box className={clsx(classes.topRow, hasImage && classes.gradientTop)}>
								<IconButton
									onClick={closeClicked}
									className={clsx(classes.closeIcon, hasImage && classes.headerForImage)}
									aria-label={t("close")}
									size="large"
								>
									<CloseIcon />
								</IconButton>
								{title && (
									<Typography className={clsx(classes.title, hasImage && classes.headerForImage)}>{title}</Typography>
								)}
							</Box>
						)}
						{children}
					</Box>
				</Dialog>
			)}
		</AnimatePresence>
	);
};

export default Modal;
