/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";

import { Box, ListItem, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import { motion } from "framer-motion";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router";

import useStore from "store";
import isUnavailable from "utils/timeUtils/isUnavailable";

import IMenuItem from "api/types/MenuItem";
import { formatMoney } from "components/Price";
import { MediaTypes } from "gql/types/globals";
import useLocalize from "hooks/useLocalize";

const useStyles = makeStyles(theme => {
	return {
		container: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			flexGrow: 1,
			alignItems: "flex-start",
			justifyContent: "center",
			borderBottom: "0.5px solid rgba(51, 49, 50, 0.2)",
			height: 71,
			minHeight: 71,
			margin: 0,
			padding: 0
		},
		item: {
			display: "flex",
			alignItems: "center",
			flexDirection: "row",
			width: "100%"
		},
		entry: {
			display: "flex",
			alignItems: "center",
			width: "100%",
			flexDirection: "row"
		},
		iconButton: {
			color: "#000",
			backgroundColor: "#fff",
			marginRight: theme.spacing(2)
		},
		info: {
			position: "relative",
			display: "flex",
			flex: 1,
			flexGrow: 1,
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "center"
		},
		title: {
			paddingLeft: theme.spacing(2),
			flexDirection: "column",
			display: "flex",
			width: "100%",
			flex: 1,
			flexGrow: 1
		},
		unavailableContainer: {
			flex: 1,
			flexGrow: 1,
			backgroundColor: "#f8f8f8"
		},
		unavailable: {
			color: "red",
			fontSize: 18,
			fontWeight: 700
		},
		unavailableOverlay: {
			position: "absolute",
			display: "flex",
			alignItems: "center",
			paddingLeft: 5,
			// justifyContent: "center",
			flex: 1,
			top: 0,
			bottom: 0,
			left: 0,
			right: 0
		},
		fullOverlay: {
			justifyContent: "center"
		},
		name: {
			fontSize: 20
		},
		unavailableName: {
			color: "#ccc"
		},
		desc: {
			fontSize: 18,
			color: "#555", //theme.palette.text.disabled,
			width: "100%",
			display: "-webkit-box",
			lineClamp: 2,
			"-webkit-box-orient": "vertical",
			overflow: "hidden",
			textOverflow: "ellipsis"
		},
		price: {
			textAlign: "right",
			marginRight: theme.spacing(2)
		},
		media: {
			objectFit: "cover",
			backgroundPosition: "center",
			width: 70,
			height: 70
		},
		mediaUnavailable: {
			opacity: 0.3
		}
	};
});

interface Props {
	menuItem: IMenuItem;
	key: string;
}

const MenuItem: React.FC<Props> = ({ menuItem }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const orderTime = useStore(store => store.orderTime);

	const { localize } = useLocalize();
	const navigate = useNavigate();

	const background = {
		backgroundSize: "cover",
		backgroundImage: `url('${
			menuItem.mediaLinks && menuItem.mediaLinks.length > 0 && menuItem.mediaLinks![0]!.mediaUrl
				? menuItem.mediaLinks![0]!.mediaUrl
				: null
		}')`
	};

	const selectItem = () => {
		// push(`/${locale}/main/menu/${menuItem.slug}/detail`);
		navigate(`${menuItem.slug}/detail`);
	};

	const isUnAvailable =
		menuItem.isUnAvailable ||
		(menuItem?.availability?.scheduleExceptions && isUnavailable(orderTime ?? DateTime.local(), menuItem.availability));

	const video =
		menuItem.mediaLinks &&
		menuItem.mediaLinks?.length > 0 &&
		menuItem.mediaLinks![0]!.mediaType === MediaTypes.video ? (
			<video
				autoPlay
				loop
				src={menuItem!.mediaLinks![0]!.mediaUrl}
				playsInline
				muted
				className={clsx(classes.media, isUnAvailable && classes.mediaUnavailable)}
			/>
		) : null;
	if (!menuItem) {
		return null;
	}

	const hasMedia = menuItem.mediaLinks && menuItem.mediaLinks.length > 0;

	return (
		<ListItem button className={classes.container} onClick={selectItem}>
			<Box className={clsx(classes.entry, isUnAvailable && classes.unavailableContainer)}>
				<Box className={classes.info}>
					<motion.div
						layout
						// layoutId={menuItem._id}
						initial={{ opacity: 0, scale: 0.5 }}
						exit={{ opacity: 0, scale: 0.5 }}
						animate={{ opacity: 1, scale: 1 }}
					>
						{hasMedia ? (
							video ? (
								video
							) : (
								<Box
									className={clsx(classes.media, isUnAvailable && classes.mediaUnavailable)}
									style={background}
								></Box>
							)
						) : null}
					</motion.div>

					<Box className={clsx(classes.title)}>
						<Typography className={clsx(classes.name, isUnAvailable && classes.unavailableName)}>
							{localize(menuItem.localeNames, menuItem.name)}
						</Typography>
					</Box>

					{isUnAvailable && (
						<Box className={clsx(classes.unavailableOverlay, !hasMedia && classes.fullOverlay)}>
							<Typography sx={{ fontSize: "1.4rem", color: "rgba(255,0,0,0.8)", fontWeight: 700 }}>
								{t("soldOut")}
							</Typography>
						</Box>
					)}

					<Box className={classes.price}>
						<Typography className={classes.desc}>
							{menuItem.displayPrice ? menuItem.displayPrice : formatMoney(menuItem.priceCents)}
						</Typography>
					</Box>
				</Box>
			</Box>
		</ListItem>
	);
};

export default React.memo(MenuItem);
