import React, { useState } from "react";

import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import { motion } from "framer";

const useStyles = makeStyles(theme => {
	return {
		clippingContainer: {
			height: "100%",
			width: "100%",
			overflow: "hidden",
			position: "relative"
		},
		videoContainer: {
			height: "100%",
			width: "100%",
			position: "relative"
		},
		img: {
			margin: 0,
			padding: 0,
			// height: "30vh",
			minHeight: "calc(var(--vh, 1vh) * 30)",
			// height: "calc(var(--vh, 1vh) * 30)",
			height: 250,
			width: "100vw",
			objectFit: "cover",
			transform: "none",
			position: "relative",
			boxShadow: "0 0 15px",
			[theme.breakpoints.up(600)]: {
				minWidth: 600,
				width: "100%"
			}
		},
		fullScreen: {
			position: "relative",
			maxHeight: "100%",
			objectFit: "cover",
			width: "100vw",
			height: "100%",
			[theme.breakpoints.up(600)]: {
				height: "calc(var(--vh, 1vh) * 90 - 60px)"
			}
		},
		fsToggle: {
			position: "absolute",
			color: "#fff",
			bottom: 10,
			right: 10,
			zIndex: 3000
		}
	};
});

interface Props {
	mediaUrl: string;
	onToggleFullScreen: (boolean) => void;
}

const AnimatedImage: React.FC<Props> = ({ mediaUrl, onToggleFullScreen }) => {
	const classes = useStyles();

	const [isFullScreen, setIsFullScreen] = useState(false);

	const toggleFullScreen = () => {
		setIsFullScreen(!isFullScreen);
		onToggleFullScreen(!isFullScreen);
	};

	return (
		<div
			className={clsx(
				classes.clippingContainer,
				classes.videoContainer,
				!isFullScreen && classes.img,
				isFullScreen && classes.fullScreen
			)}
		>
			<motion.div
				layout
				transition={{ duration: 25, ease: "easeInOut", repeat: Infinity, repeatType: "mirror" }}
				animate={{ scale: 1.3 }}
				className={clsx(classes.videoContainer, !isFullScreen && classes.img, isFullScreen && classes.fullScreen)}
			>
				<motion.div
					layout
					transition={{ duration: 0.2 }}
					animate={{ scale: 1 }}
					className={clsx(classes.videoContainer, !isFullScreen && classes.img, isFullScreen && classes.fullScreen)}
				>
					<img
						src={mediaUrl}
						className={clsx(!isFullScreen && classes.img, isFullScreen && classes.fullScreen)}
						onClick={toggleFullScreen}
					/>
				</motion.div>
			</motion.div>

			{!isFullScreen && <FullscreenIcon className={classes.fsToggle} fontSize="large" onClick={toggleFullScreen} />}
			{isFullScreen && <FullscreenExitIcon className={classes.fsToggle} fontSize="large" onClick={toggleFullScreen} />}
		</div>
	);
};

export default React.memo(AnimatedImage);
