import React, { useEffect } from "react";

import CssBaseline from "@mui/material/CssBaseline";

import analytics from "analytics";
import { useGA4React } from "ga-4-react";
import { OptionsObject, useSnackbar } from "notistack";
import { Helmet } from "react-helmet";
import { Route, Routes, useNavigate, useParams } from "react-router";

import useStore from "store";

import AppLoading from "components/AppLoading";
import Favicon from "components/Favicon";
import Redirect from "components/Redirect";
import config from "config";
import Landing from "containers/Landing";
import MobileMain from "containers/MobileMain";
import Dialogs from "containers/MobileMain/Dialogs";

import Table from "containers/MobileMain/Table";
import TableCustomerInfo from "containers/MobileMain/Table/TableCustomerInfo";
import TableJoin from "containers/MobileMain/Table/TableJoin";
import TableLookup from "containers/MobileMain/Table/TableLookup";
import TableSetup from "containers/MobileMain/Table/TableSetup";
import Unsubscribe from "containers/Unsubscribe";
import { NotificationLevels } from "gql/types/globals";

interface Props {}

const BusinessRoutes: React.FC<Props> = () => {
	const navigate = useNavigate();
	const params = useParams();
	const ga4React = useGA4React(config.googleAnalyticsId);
	const { enqueueSnackbar } = useSnackbar();

	const setLocale = useStore(store => store.setLocale);
	const locale = useStore(store => store.locale);
	const business = useStore(store => store.business);

	const notificationText = useStore(store => store.notificationText);
	const notificationLevel = useStore(store => store.notificationLevel);
	const clearNotification = useStore(store => store.clearNotification);

	useEffect(() => {
		if (notificationText) {
			if (notificationLevel) {
				const variant: OptionsObject =
					notificationLevel === NotificationLevels.error
						? { variant: "error", persist: true }
						: notificationLevel === NotificationLevels.warning
						? { variant: "error", persist: true }
						: { variant: "default" };
				enqueueSnackbar(notificationText, variant);
			} else {
				enqueueSnackbar(notificationText);
			}
			clearNotification();
		}
	}, [enqueueSnackbar, clearNotification, notificationText, notificationLevel]);

	useEffect(() => {
		if (params.locale && locale !== params.locale) {
			setLocale(params.locale);
			navigate(["", locale, params["*"]].filter(Boolean).join("/"));
		}
	}, [params, locale, setLocale, navigate]);

	const onChangeClientState = ({ title }) => {
		if (config.isGoogleAnalyticsEnabled && ga4React) {
			ga4React.pageview(window.location.href, undefined, title);
		}
		analytics.track("Page Viewed", { title, url: window.location.href });
	};

	if (!business) {
		return (
			<>
				<CssBaseline />
				<AppLoading />
			</>
		);
	}

	return (
		<>
			<Helmet onChangeClientState={onChangeClientState} />
			<CssBaseline />
			<Favicon faviconUrl={business?.faviconUrl} />
			<Routes>
				<Route path="landing" element={<Landing />} />
				{/* <Route path="info" element={<Landing />} /> */}
				{/* <Route path="menu*" element={<FullMenu />} /> */}
				{/* <Route path="menu/:itemSlug*" element={<FullMenu />} />
				<Route path="menu/:itemSlug/*" element={<FullMenu />} /> */}
				{/* <Route path="viewer/:tag" element={<Main />} /> */}
				<Route path="//*" element={<Redirect to={"landing"} />} />
				<Route path="//unsubscribe/:id" element={<Unsubscribe />} />

				<Route path="main/*" element={<MobileMain />} />
				<Route path="table" element={<Table />} />
				<Route path="table/:tableSlug" element={<Table />} />
				<Route path="table/:tableSlug/lookup" element={<TableLookup />} />
				<Route path="table/:tableSlug/setup" element={<TableSetup />} />
				<Route path="table/:tableSlug/customerinfo" element={<TableCustomerInfo />} />
				<Route path="table/:tableSlug/join/:joinId" element={<TableJoin />} />

				{/* <Route path="table/:tableSlug" element={<Table />} />
				<Route path="table/:tableSlug/setup" element={<TableSetup />} /> */}
				{/* <Route path="//*" element={<Redirect to={"menu"} />} /> */}
				{/* <Route path="//*" element={<Redirect to={`viewer/${defaultTagSlug}`} />} /> */}
			</Routes>
			<Dialogs />
		</>
	);
};

export default BusinessRoutes;
