import React from "react";

import { Helmet } from "react-helmet";

interface Props {
	faviconUrl?: string | null | undefined;
}

const Favicon: React.FC<Props> = ({ faviconUrl }) => {
	const url = faviconUrl ? faviconUrl : "/favicon.ico";
	return (
		<Helmet>
			<link rel="icon" href={url}></link>
		</Helmet>
	);
};

export default React.memo(Favicon);
