import { DateTime } from "luxon";

import { BusinessQuery_business_orderingHours } from "gql/types/queries/BusinessQuery";

import getSchedulePeriods from "./getSchedulePeriods";
import getTimeFromTimeString from "./getTimeFromTimeString";
import isOpen from "./isOpen";

export const processDay = (
	orderTime: DateTime,
	prepTimeMinutes: number,
	orderingHours: BusinessQuery_business_orderingHours,
	timeZone: string
): DateTime | null => {
	const hrs = getSchedulePeriods(orderTime, orderingHours, timeZone);

	if (hrs && hrs?.length > 0 && hrs[0]?.isAllDay) {
		const nextTime = orderTime.plus({ minutes: prepTimeMinutes });
		return nextTime;
	}
	// get the next open time after ordering
	const nextPeriod = hrs?.find(p => {
		if (p) {
			const openTime = getTimeFromTimeString(p.startTime, orderTime, timeZone);
			// const closeTime = getTimeFromTimeString(p.endTime, orderTime, timeZone);
			if (openTime! > orderTime) {
				return true;
			}
		}
	});
	if (nextPeriod) {
		return getTimeFromTimeString(nextPeriod.startTime, orderTime, timeZone)!.plus({ minutes: prepTimeMinutes });
	}

	return null;
};

const getNextOrderFulfillmentTime = (
	orderTime: DateTime,
	prepTimeMinutes: number,
	orderingHours: BusinessQuery_business_orderingHours,
	timeZone: string
): DateTime | null => {
	const testOrderCompletionTime = orderTime.plus({ minutes: prepTimeMinutes });

	// Check the current date:  isOpen for both orderTime & completion time?

	const isOpenAtOrdering = isOpen(orderTime, orderingHours, timeZone);
	const isOpenAtPreparationDue = isOpen(testOrderCompletionTime, orderingHours, timeZone);

	if (isOpenAtOrdering && isOpenAtPreparationDue) {
		return testOrderCompletionTime;
	}

	// find when the next opening occurs

	let hrs = getSchedulePeriods(orderTime, orderingHours, timeZone);

	if (hrs && hrs?.length > 0 && hrs[0]?.isAllDay) {
		const nextTime = orderTime.plus({ minutes: prepTimeMinutes });
		return nextTime;
	}

	// get the next open time after ordering
	const nextPeriod = hrs?.find(p => {
		if (p) {
			const openTime = getTimeFromTimeString(p.startTime, orderTime, timeZone);
			// const closeTime = getTimeFromTimeString(p.endTime, orderTime, timeZone);
			if (openTime! > orderTime) {
				return true;
			}
		}
	});
	if (nextPeriod) {
		return getTimeFromTimeString(nextPeriod.startTime, orderTime, timeZone)!.plus({ minutes: prepTimeMinutes });
	}
	// }

	// Need to check the next opening time
	let nextDay = orderTime.plus({ days: 1 }).startOf("day");
	while (hrs && hrs?.length === 0 && nextDay < orderTime.plus({ days: 14 })) {
		hrs = getSchedulePeriods(nextDay, orderingHours, timeZone);

		if (hrs && hrs.length > 0) {
			if (hrs && hrs?.length > 0 && hrs[0]?.isAllDay) {
				return nextDay.plus({ days: 1 });
			}

			return getTimeFromTimeString(hrs[0]?.startTime, orderTime.plus({ days: 1 }), timeZone)!.plus({
				minutes: prepTimeMinutes
			});
		}
		nextDay = nextDay.plus({ days: 1 }).startOf("day");
	}
	return null;
	/*
	// Get the next open time
	let nextOpenTimeToday: DateTime | null = null;
	let nextOpenTime: DateTime | null = null;

	// Get the day of the week
	let dayOfWeek = DaysOfWeek[orderTime.weekdayShort.toLowerCase()];

	// Check scheduleExceptions
	nextOpenTime = orderTime;

	const schedExceptions = getScheduleExceptions(testOrderCompletionTime, orderingHours, timeZone);

	let isClosedToday = false;
	if (schedExceptions.length === 1) {
		if (schedExceptions[0]!.periods?.length === 0) {
			// closed today
			isClosedToday = true;
		}
	}

	dayOfWeek = DaysOfWeek[nextOpenTime.weekdayShort.toLowerCase()];

	// Filter if any hours for that day

	if (hrs && hrs.length > 0) {
		hrs.forEach(p => {
			if (nextOpenTimeToday === null && p) {
				const openTime = getTimeFromTimeString(p.startTime, orderTime, timeZone);
				const closeTime = getTimeFromTimeString(p.endTime, orderTime, timeZone);
				if (orderTime <= openTime && (testOrderCompletionTime <= closeTime || testOrderCompletionTime <= openTime)) {
					nextOpenTimeToday = openTime.plus({ minutes: prepTimeMinutes });
					return;
				}
			}
		});
		if (nextOpenTimeToday) {
			return nextOpenTimeToday;
		}
	}

	// Get the next open day
	let nextDay = orderTime;
	hrs = [];
	while (hrs?.length === 0) {
		nextDay = nextDay.plus({ day: 1 }).startOf("day");
		dayOfWeek = DaysOfWeek[nextDay.weekdayShort.toLowerCase()];
		hrs = orderingHours.schedulePeriods?.filter(p => p?.days.includes(dayOfWeek));
	}

	hrs?.forEach(h => {
		h?.periods.forEach(p => {
			if (nextOpenTimeToday === null) {
				const openTime = getTimeFromTimeString(p.startTime, nextDay, timeZone);
				if (nextDay <= openTime) {
					nextOpenTimeToday = openTime.plus({ minutes: prepTimeMinutes });
					return;
				}
			}
		});
	});
	if (nextOpenTimeToday) {
		return nextOpenTimeToday;
	}
	return nextOpenTimeToday;
	*/
};

export default getNextOrderFulfillmentTime;
